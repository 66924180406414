import React, { useState, useCallback } from 'react';
import { useBannerAds, useDeleteBannerAd, useEditBannerAd } from 'api/site_administration';
import { notification, Card } from 'antd';
import { UserMessageTable } from './UserMessageTable';
import { IBannerAd } from 'types/SiteAdministration';
import moment from 'moment/moment';
import UserMessage from './UserMessage';

const BannerAdministration: React.FC = () => {
  const [showCreateBanner, setShowCreateBanner] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState<number | undefined>(undefined);

  const { data: bannerAds, refetch: refetchBannerAds } = useBannerAds(true);
  const { mutate: deleteBanner } = useDeleteBannerAd({
    onSuccess: () => {
      notification.success({
        message: 'Banner deleted successfully'
      });
    },
    onError: () => {
      notification.error({
        message: 'Error while deleting banner'
      });
    }
  });

  const { mutate: editBannerAd } = useEditBannerAd({
    onSuccess: async () => {
      notification.success({
        message: 'Banner unpublished!',
        duration: 4
      });
      await refetchBannerAds();
    },
    onError: () => {
      notification.error({
        message: 'Failed to unpublish banner',
        duration: 4
      });
    }
  });

  const handleAddNewBanner = () => {
    setShowCreateBanner(true);
  };

  const closeCreateBanner = () => {
    setShowCreateBanner(false);
  };

  const handleEditBanner = (bannerId: number) => {
    setSelectedBanner(bannerId);
  };

  const closeEditBanner = () => {
    setSelectedBanner(undefined);
  };

  const handleDeleteBanner = useCallback(
    (bannerId: number) => {
      deleteBanner(bannerId);
    },
    [deleteBanner]
  );

  const handleUnpublishBanner = useCallback(
    (bannerId: number) => {
      const payload = bannerAds?.find((b: IBannerAd) => b.id === bannerId);
      if (payload) {
        const { banner_type, url, publish_date, name, permissions } = payload;
        const expiration_date = moment().subtract(1, 'days').startOf('day').toISOString();
        editBannerAd({
          id: bannerId || 0,
          data: { name, banner_type, url, publish_date, permissions, expiration_date }
        });
      }
    },
    [bannerAds, editBannerAd]
  );

  return (
    <>
      {!showCreateBanner && !selectedBanner && <h1 className="text-3xl tracking-tight">Banner Administration</h1>}

      {selectedBanner && <UserMessage bannerId={selectedBanner} closeBanner={closeEditBanner} />}
      {showCreateBanner && !selectedBanner && <UserMessage closeBanner={closeCreateBanner} />}
      {!showCreateBanner && !selectedBanner && (
        <Card>
          <UserMessageTable
            bannerAds={bannerAds || []}
            onAddNewBanner={handleAddNewBanner}
            onEditBanner={handleEditBanner}
            onDeleteBanner={handleDeleteBanner}
            onUnpublishBanner={handleUnpublishBanner}
          />
        </Card>
      )}
    </>
  );
};

export default BannerAdministration;
