import axios from 'axios';

import { Storage } from './storage';
import { BASE_URL } from './constants';

const createAxiosClient = (baseURL?: string) => {
  const instance = axios.create({ baseURL });

  instance.interceptors.request.use((config) => {
    try {
      const token = Storage.getToken();
      if (config.headers) {
        config.headers.Accept = 'application/json,text/plain';
        config.headers['Content-Type'] = 'application/json';
        config.headers['Cache-Control'] = 'no-cache';

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    } catch (err) {
      // return original config if JSON.parse fails
      return config;
    }
  });

  instance.interceptors.response.use(undefined, (error) => {
    const status = error.response ? error.response.status : null;
    if (status === 401) {
      Storage.removeToken();
      location.href = '/';
    }
    const message = error.response?.data?.message;
    throw new Error(Array.isArray(message) ? message[0] : message || 'API error.');
  });

  return instance;
};

const axiosClient = createAxiosClient(BASE_URL);

export { axiosClient as axios };
