import React, { useEffect, useMemo, useState } from 'react';
import { Accuracy, EditAccuracyPayload, ProductAccuracy } from '../../models/Product';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Skeleton,
  Statistic,
  Typography
} from 'antd';
import { useDocLinks, useSalesforceFiles, useUnitsOfMeasure } from '../../sb/api/contract';
import { Document, Page, pdfjs } from 'react-pdf';
import sanitize from 'sanitize-filename';
import fileDownload from 'js-file-download';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './ProductAccuracy.scss';
import { currencyFormatter } from '../../shared/Helpers';
import { Card } from 'antd/es';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface EditAccuracyProps {
  accuracy: Accuracy;
  product: Omit<ProductAccuracy, 'accuracy'>;
  updateAccuracy: (data: EditAccuracyPayload & { transaction_id: number }) => void;
  onCancel: () => void;
}

const EditAccuracy: React.FC<EditAccuracyProps> = ({ accuracy, product, updateAccuracy, onCancel }) => {
  const [form] = Form.useForm();
  const { data: unitsOfMeasure } = useUnitsOfMeasure();
  const { data: docLinks, isLoading: isLoadingDocLinks } = useDocLinks(accuracy.salesforce_deal_id);

  const [pdfDetails, setPdfDetails] = useState<Record<string, number>>({});
  const [pdfScales, setPdfScales] = useState<Record<string, number>>({});

  const sfFiles = useSalesforceFiles(
    (docLinks?.data || [])?.map((d: any) => d.ContentDocumentId),
    { enabled: !!docLinks?.data?.length }
  );
  const docOptions = useMemo(
    () => ({
      cMapUrl: '/cmaps/',
      standardFontDataUrl: '/standard_fonts/'
    }),
    []
  );

  const qty = Form.useWatch('capacity', form);
  const unitQty = Form.useWatch('unit_quantity', form);

  useEffect(() => {
    form.setFieldsValue({
      unit_cost: accuracy.total_license_cost / (qty * unitQty)
    });
  }, [qty, unitQty, accuracy.total_license_cost, form]);

  const initialValues = {
    capacity: accuracy.capacity,
    unit_quantity: accuracy.unit_quantity || 1,
    unit_of_measure_id: accuracy.unit_of_measure_id || 4,
    unit_cost: accuracy.unit_cost,
    is_validated: accuracy.is_validated
  };

  const handleFinish = (payload: Accuracy) => {
    return updateAccuracy({
      capacity: payload.capacity,
      total_license_cost: accuracy.total_license_cost,
      unit_quantity: payload.unit_quantity,
      authorized_use: accuracy.authorized_use || '',
      unit_of_measure_id: payload.unit_of_measure_id,
      is_validated: payload.is_validated,
      transaction_id: accuracy.transaction_id
    });
  };

  const renderPdf = () => {
    return (
      <>
        <Typography.Title level={4}>Salesforce Deal Documents</Typography.Title>
        <Skeleton active loading={isLoadingDocLinks} />
        <Collapse>
          {(sfFiles || [])?.map((item, index) => {
            const docLink = docLinks?.data[index];
            return (
              <Collapse.Panel header={docLink?.ContentDocument?.Title} key={docLink?.ContentDocumentId}>
                {docLink?.ContentDocument?.FileExtension === 'pdf' ? (
                  <>
                    <Row className="bg-slate-100 p-2 sticky z-10 top-0 left-0 flex justify-center">
                      <Button
                        type={'link'}
                        onClick={() => {
                          setPdfScales((prev) => ({
                            ...prev,
                            [docLink?.ContentDocumentId]: pdfScales[docLink?.ContentDocumentId] + 0.5
                          }));
                        }}
                      >
                        <ZoomInOutlined />
                      </Button>
                      <Button
                        type={'link'}
                        onClick={() => {
                          setPdfScales((prev) => ({
                            ...prev,
                            [docLink?.ContentDocumentId]: pdfScales[docLink?.ContentDocumentId] - 0.5
                          }));
                        }}
                      >
                        <ZoomOutOutlined />
                      </Button>
                    </Row>
                    <Document
                      file={item.data}
                      options={docOptions}
                      onLoadSuccess={({ numPages }: { numPages: number }) => {
                        setPdfDetails((prev) => ({
                          ...prev,
                          [docLink?.ContentDocumentId]: numPages
                        }));
                        setPdfScales((prev) => ({
                          ...prev,
                          [docLink?.ContentDocumentId]: 1
                        }));
                      }}
                      loading={<Skeleton active />}
                    >
                      {Array.from(new Array(pdfDetails[docLink?.ContentDocumentId]), (_, index) => (
                        <Page
                          className="w-auto !bg-gray-50"
                          key={`page_${index + 1}_${pdfScales[docLink?.ContentDocumentId]}`}
                          pageNumber={index + 1}
                          scale={pdfScales[docLink?.ContentDocumentId]}
                        />
                      ))}
                    </Document>
                  </>
                ) : (
                  <div className="p-6 block">
                    This document cannot be previewed.
                    <Typography.Link
                      className="pl-2"
                      onClick={() => {
                        const docTitle = `${sanitize(docLink.ContentDocument.Title)}.${
                          docLink.ContentDocument.FileExtension
                        }`;
                        fileDownload(item.data, docTitle);
                      }}
                    >
                      Please click to download the file.
                    </Typography.Link>
                  </div>
                )}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      </>
    );
  };

  return (
    <Modal
      width={'80%'}
      title={`Edit Product Accuracy - ${accuracy?.salesforce_deal_name}`}
      className="product-accuracy"
      open={!!accuracy && !!product}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="ok"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          Save
        </Button>
      ]}
    >
      <Row gutter={24}>
        <Col span={16} className="overflow-hidden left-pane">
          <Row className="flex-col">{renderPdf()}</Row>
        </Col>
        <Col span={8}>
          <Card>
            <Typography.Title level={4} className="!mb-4">
              {product.name}
            </Typography.Title>
            <div className="opacity-50 mt-2">{product.code}</div>

            <Row className="p-4 bg-slate-100 rounded-xl mb-6">
              <Col span={8}>
                <div className="opacity-50">Extended Price</div>
                <Typography.Title level={4} className="!mb-0">
                  {currencyFormatter(accuracy.total_license_cost)}
                </Typography.Title>
              </Col>
              <Col span={8} className="text-left">
                <div className="opacity-50">Pred. by Quantity</div>
                <Typography.Title level={4} className="!mb-0">
                  {accuracy.predictedUnitCostQuantity ? `$${accuracy.predictedUnitCostQuantity.toFixed(2)}` : '-'}
                </Typography.Title>
              </Col>
              <Col span={8} className="text-left">
                <div className="opacity-50">Pred. by TCV</div>
                <Typography.Title level={4} className="!mb-0">
                  {accuracy.predictedUnitCostTcv ? `$${accuracy.predictedUnitCostTcv.toFixed(2)}` : '-'}
                </Typography.Title>
              </Col>
            </Row>

            <Form
              form={form}
              layout="horizontal"
              initialValues={initialValues}
              onFinish={handleFinish}
              labelCol={{ span: 13 }}
              wrapperCol={{ span: 'auto' }}
            >
              <Row>
                <Col span={8}>
                  <Typography.Text strong>Edit Line Item</Typography.Text>
                </Col>
                <Col span={16} className="text-right">
                  <Typography.Text strong>Initial Value</Typography.Text>
                </Col>
              </Row>
              <Divider className="my-2" />
              <Row className="spacer">
                <Col span={15}>
                  <Form.Item
                    label="Quantity"
                    labelAlign="left"
                    name="capacity"
                    rules={[{ required: true, message: 'Quantity is required' }]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Col>
                <Col span={9} className="text-right">
                  <span>{currencyFormatter(accuracy.capacity)}</span>
                </Col>
              </Row>

              <Row className="spacer">
                <Col span={15}>
                  <Form.Item
                    label="Unit Quantity"
                    labelAlign="left"
                    name="unit_quantity"
                    rules={[{ required: true, message: 'Quantity is required' }]}
                  >
                    <InputNumber min={0.01} />
                  </Form.Item>
                </Col>
                <Col span={9} className="text-right">
                  <span>{accuracy.unit_quantity ? currencyFormatter(accuracy.unit_quantity) : '-'}</span>
                </Col>
              </Row>

              <Row className="spacer">
                <Col span={15}>
                  <Form.Item
                    label="Unit of Measure"
                    labelAlign="left"
                    name="unit_of_measure_id"
                    rules={[{ required: true, message: 'Unit of measure is required' }]}
                  >
                    <Select className="!w-[120px]">
                      {unitsOfMeasure?.map((unit) => (
                        <Select.Option key={unit.id} value={unit.id} style={{ width: '95%' }}>
                          {unit.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={9} className="text-right">
                  <span>
                    {(() => {
                      const unit = unitsOfMeasure?.find((unit) => unit.id === accuracy.unit_of_measure_id);
                      return unit?.name;
                    })()}
                  </span>
                </Col>
              </Row>

              <Row className="spacer">
                <Col span={15}>
                  <Form.Item
                    label="Unit Price"
                    labelAlign="left"
                    name="unit_cost"
                    rules={[{ required: true, message: 'Unit cost is required' }]}
                  >
                    <InputNumber disabled />
                  </Form.Item>
                </Col>
                <Col span={9} className="text-right">
                  {currencyFormatter(accuracy.unit_cost)}
                </Col>
              </Row>

              <Row className="spacer">
                <Col span={15}>
                  <Form.Item label="Validated" labelAlign="left" name="is_validated" valuePropName="checked">
                    <Checkbox defaultChecked={false} />
                  </Form.Item>
                </Col>
                <Col span={9} className="text-right">
                  <span>{accuracy.is_validated ? 'Yes' : 'No'}</span>
                </Col>
              </Row>
              <div className="text-right pb-2">
                <Button
                  type="link"
                  onClick={() =>
                    form.resetFields(['capacity', 'unit_quantity', 'unit_of_measure_id', 'unit_cost', 'is_validated'])
                  }
                >
                  Reset All Values
                </Button>
              </div>

              <Form.Item className="bg-slate-100 p-4 mb-0 rounded-lg font-medium tracking-tight">
                Extended Price: <Statistic prefix={'$'} value={accuracy.total_license_cost} precision={2} />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditAccuracy;
