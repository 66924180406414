import { Button, Divider, Form, Modal, ModalProps, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import './DealTypeModal.scss';
import { DiffOutlined, FileAddOutlined } from '@ant-design/icons';
import { ADD_BULK_DEAL_TEMPLATE } from '../../shared/constants';
interface DealTypeModalProps extends ModalProps {
  onCancel: () => void;
}

export const DealTypeModal: React.FC<DealTypeModalProps> = ({ onCancel, ...rest }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      title="Add Renewal Reminder(s)"
      centered
      open
      okText="Submit Details"
      onCancel={onCancel}
      onOk={() => form.submit()}
      {...rest}
      footer={null}
    >
      <>
        <div>
          <div>
            <Button type="link" className="p-0 text-base ">
              <FileAddOutlined className="text-xl" />
              <Link className="pl-2 font-semibold text-xl" to={'/add_single_deal'}>
                Single Contract
              </Link>
            </Button>
          </div>
          <div className="text-base">
            Add a single contract into SmartCalendar to help track renewals. You may also include contract-related
            files.
          </div>
          <Divider />
          <Button type="link" className="p-0 text-base">
            <DiffOutlined className="text-2xl" />
            <Link className="pl-2 font-semibold text-xl" to={'/add_multiple_deals'}>
              Multiple Contracts
            </Link>
          </Button>
          <div className="text-base">Add multiple renewals contracts at a time to help track upcoming renewals.</div>
          <Divider />
          <Button type="link" className="p-0 text-base">
            <DiffOutlined className="text-2xl" />
            <Link className="pl-2 font-semibold text-xl" to={'/upload_deals'}>
              Bulk Contracts
            </Link>
          </Button>
          <div className="text-base mb-1">Use an Excel spreadsheet to add multiple contracts.</div>
          <Typography.Link className="p-0 text-base" href={ADD_BULK_DEAL_TEMPLATE} target="_blank">
            Click here to download Template
          </Typography.Link>
        </div>
        <Divider />
      </>
    </Modal>
  );
};
