import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { ReportElement, ReportPatch } from 'sb/models/Reports';

export async function fetchReport(id: number) {
  try {
    const res = await axios.get(`/secure/report/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch client reports
 *
 * @param salesforceAccountId
 */
export async function fetchClientReports(salesforceAccountId: string) {
  try {
    const res = await axios.get(`/secure/client/${salesforceAccountId}/reports`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch client reports
 *
 * @param id
 */
export async function fetchSupplierReports(id: number) {
  try {
    const res = await axios.get(`/secure/supplier/${id}/reports`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch client report
 *
 * @param id
 */
export async function fetchClientReport(id: number) {
  try {
    const res = await axios.get(`/secure/client/report/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch client report header
 *
 * @param id
 */
export async function fetchReportHeader(id: number) {
  try {
    const res = await axios.get(`/secure/client/report/${id}/header`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Create report element
 *
 * @param report_id
 * @param data
 */
export async function createReportElement(report_id: number, data: ReportElement) {
  try {
    const res = await axios.post(`/secure/report/${report_id}/element`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Patch report element
 *
 * @param report_id
 * @param id
 * @param data
 */
export async function patchReportElement(report_id: number, id: number, data: ReportElement) {
  try {
    const res = await axios.patch(`/secure/report/${report_id}/element/${id}`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Deal Summary
 *
 * @param salesforce_deal_id
 */
export async function fetchDealSummaryByDealId(salesforce_deal_id: string) {
  try {
    const res = await axios.get(`/secure/contract/salesforce_deal_id/${salesforce_deal_id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Patch ReportViewer
 *
 * @param id
 * @param data
 */
export async function patchReport(id: number, data: ReportPatch) {
  try {
    const res = await axios.patch(`/secure/report/${id}`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Insert ReportViewer
 *
 * @param data
 */
export async function insertReport(data: any) {
  try {
    const res = await axios.post(`/secure/report`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch report templates
 */
export async function fetchReportTemplates() {
  try {
    const res = await axios.get(`/secure/reportTemplates`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch client reports
 *
 * @param id
 * @param vendor_id
 * @param report_element_template_id
 */
export async function fetchRelatedReportElements(id: number, vendor_id: number, report_element_template_id: number) {
  try {
    const res = await axios.get(
      `/secure/report/${id}/vendor/${vendor_id}/report_element/${report_element_template_id}`
    );
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Patch report stats, e.g. effective_discount, target_savings, etc.
 *
 * @param id
 * @param data
 */
export async function patchReportStats(id: number, data: any) {
  try {
    const res = await axios.patch(`/secure/report/${id}/stats`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Delete report from DB
 *
 * @param id
 */
export async function deleteReport(id: number) {
  try {
    const res = await axios.delete(`/secure/report/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
