import { useState } from 'react';
import { UnmountClosed } from 'react-collapse';
import { animateScroll, Element, scroller } from 'react-scroll';
import { Button, Col, Divider, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';

import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { PriceListTable } from 'components/atoms/PriceListTable';
import { PriceListView } from 'components/molecules/admin/PriceListView';
import { PriceList } from 'sb/models/PriceList';
import { deleteVendorPriceList, getVendorPriceLists } from 'sb/api/vendor';
import { scrollOptions } from 'sb/shared/constants';

const PriceLists: React.FC = () => {
  const { aclCheck } = useScopes();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [vendorId, setVendorId] = useState<number>();
  const [priceLists, setPriceLists] = useState<PriceList[]>([]);
  const [priceList, setPriceList] = useState<PriceList>();

  const handleVendorChange = async (_vendorId: number) => {
    setVendorId(_vendorId);
    if (_vendorId) {
      getPriceLists(_vendorId).then();
    } else {
      setPriceLists([]);
      handleClose();
    }
  };

  const getPriceLists = async (_vendorId: number) => {
    setLoading(true);

    const res = await getVendorPriceLists(_vendorId);
    if (Array.isArray(res)) {
      setPriceLists(res);
      setOpen(false);
    }

    setLoading(false);
  };

  const handleClickAdd = () => {
    setOpen(true);
    setPriceList(undefined);

    scroller.scrollTo('price-list', scrollOptions);
  };

  const handlePriceListSaved = async () => {
    handleClose();

    if (vendorId) {
      await getPriceLists(vendorId);
    }
  };

  const handlePriceListMetaUpdate = (pl: Partial<PriceList>) => {
    setPriceLists((prev) => prev.map((prev) => (prev.price_list_id !== pl.price_list_id ? prev : { ...prev, ...pl })));
  };

  const handlePriceListClick = (pl: PriceList) => {
    setPriceList(pl);
    setOpen(true);

    scroller.scrollTo('price-list', scrollOptions);
  };

  const handleClose = () => {
    setOpen(false);
    animateScroll.scrollToTop(scrollOptions);
  };

  const handlePriceListDelete = async (pl: PriceList) => {
    setPriceList(undefined);
    handleClose();

    setDeleting(true);
    const res = await deleteVendorPriceList(pl.price_list_id);
    if (res) {
      setPriceLists((prev) => prev.filter((prev) => prev.price_list_id !== pl.price_list_id));
    }

    setDeleting(false);
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Price Lists</Typography.Title>
        </Col>

        <Col>
          {aclCheck(['create:all_supplier_price_lists']) && (
            <Button type="primary" icon={<PlusOutlined />} onClick={handleClickAdd}>
              Add
            </Button>
          )}
        </Col>
      </Row>

      <VendorsSearch placeholder="Search for a supplier" onChange={handleVendorChange} />

      <Divider />
      <PriceListTable
        loading={loading}
        priceListId={deleting ? priceList?.price_list_id : undefined}
        dataSource={priceLists}
        onItemClick={handlePriceListClick}
        onItemDelete={handlePriceListDelete}
      />

      <br />
      <Element name="price-list"></Element>
      <UnmountClosed isOpened={open}>
        <PriceListView
          vendorId={vendorId}
          priceList={priceList}
          onSave={handlePriceListSaved}
          onUpdate={handlePriceListMetaUpdate}
          onClose={handleClose}
        />
      </UnmountClosed>
    </>
  );
};

export default PriceLists;
