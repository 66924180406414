import { escapeRegExp, filter } from 'lodash-es';
import { ChildChild, RenewalChildRecord, RenewalRecord, Task, TaskChild } from 'models/Renewals';
import moment from 'moment-timezone';
import { RENEWAL_RECORD_TYPE } from 'shared/constants';
import { FilterRange, GanttChartMode } from 'sb/shared/constants';

const getClientContactFilteredChildren = (
  childData: RenewalChildRecord[],
  clientContactFilterValue: string | any[]
) => {
  return childData.filter(
    (childChild) =>
      childChild.recordType === RENEWAL_RECORD_TYPE.DEAL &&
      clientContactFilterValue.includes(childChild.clientContact?.Id)
  );
};

export const getClientContactFilteredTasks = (dataArray: RenewalRecord[], clientContactFilterValue: string | any[]) => {
  // create an array with the children that have selected client contacts
  const childrenFilteredMap = dataArray.map((taskChild) => {
    if (taskChild?.recordType === RENEWAL_RECORD_TYPE.SUPPLIER && taskChild?.children?.length > 0) {
      return {
        ...taskChild,
        children: getClientContactFilteredChildren(taskChild.children, clientContactFilterValue)
      };
    } else {
      return taskChild;
    }
  });
  // filter the created array
  return childrenFilteredMap?.filter((taskChild: RenewalRecord) => {
    return (
      (taskChild?.recordType === RENEWAL_RECORD_TYPE.SUPPLIER_DEAL &&
        clientContactFilterValue.includes((taskChild as TaskChild).clientContact?.Id)) ||
      (taskChild?.recordType === RENEWAL_RECORD_TYPE.SUPPLIER &&
        taskChild?.children?.length > 0 &&
        taskChild?.children?.some((childChild: RenewalRecord) =>
          clientContactFilterValue.includes((childChild as ChildChild).clientContact?.Id)
        ))
    );
  });
};

const filterRenewalRecords = (dataArray: RenewalChildRecord[], filterValue: string): Array<any> => {
  if (filterValue && filterValue.trim()) {
    const regExp = new RegExp(escapeRegExp(filterValue), 'i');
    return filter(dataArray, (item: Task) => {
      if (item && item.children.length === 0) {
        return item.orgName?.match(regExp);
      } else {
        return item.name.match(regExp);
      }
    });
  } else {
    return dataArray;
  }
};

interface FilterProps {
  portfolioFilterValue?: string | undefined;
  vendorFilterValue: string | undefined;
  rangeFilterValue: FilterRange;
  clientContactFilterValue: Array<string | number>;
  _tasks: RenewalRecord[];
  mode: GanttChartMode;
}
export const getFilteredTasks = (props: FilterProps) => {
  const { portfolioFilterValue, vendorFilterValue, rangeFilterValue, clientContactFilterValue, _tasks, mode } = props;
  let filteredTasks: RenewalRecord[] = [];
  if (mode == GanttChartMode.PORTFOLIO) {
    if (vendorFilterValue && vendorFilterValue.trim()) {
      const regExp = new RegExp(escapeRegExp(vendorFilterValue), 'i');
      _tasks[0].children.forEach((portfolioCos: any) => {
        portfolioCos.children.forEach((vendor: any) => {
          if (vendor.name?.match(regExp)) {
            filteredTasks.push(vendor);
          }
        });
      });
    } else if (portfolioFilterValue) {
      filteredTasks = filterRenewalRecords(_tasks[0].children, portfolioFilterValue);
    }
  } else if (mode == GanttChartMode.OWN_CALENDER) {
    if (vendorFilterValue) {
      filteredTasks = filterRenewalRecords(_tasks[0].children, vendorFilterValue);
    }
  }

  //apply closes in/range filter
  if (rangeFilterValue != 'reset') {
    const endDate =
      rangeFilterValue !== 'year' ? moment().add(rangeFilterValue, 'months') : moment(`${moment().get('year')}/12/31`);
    const tasksList = vendorFilterValue ? filteredTasks : _tasks[0].children;

    filteredTasks = [];
    tasksList?.forEach((taskChild) => {
      const recordType = taskChild?.recordType || '';
      switch (recordType) {
        case RENEWAL_RECORD_TYPE.SUPPLIER:
          if (taskChild?.children?.length > 0) {
            const filteredTask = { ...taskChild };
            const filteredTaskchildChildren = taskChild?.children?.filter((childChild: RenewalChildRecord) => {
              return moment((childChild as ChildChild).endDate).diff(endDate, 'seconds') < 0;
            });
            if (filteredTaskchildChildren.length > 0) {
              filteredTask.children = filteredTaskchildChildren;
              filteredTasks.push(filteredTask);
            }
          }
          break;
        case RENEWAL_RECORD_TYPE.SUPPLIER_DEAL:
          if (moment((taskChild as RenewalChildRecord).endDate).diff(endDate, 'seconds') < 0) {
            filteredTasks.push(taskChild);
          }
          break;
      }
    });
  }

  //apply client contact filter
  if (clientContactFilterValue?.length > 0) {
    const tasksList: RenewalRecord[] =
      vendorFilterValue || rangeFilterValue != 'reset' ? filteredTasks : _tasks[0].children;
    filteredTasks = getClientContactFilteredTasks(tasksList, clientContactFilterValue);
  }

  return filteredTasks;
};
