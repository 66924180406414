import { useRecoilState } from 'recoil';
import { dealToAddTasksState, dealToEditTasksState } from '../recoil/atoms';
import { useCategoryDefaultTasks, useDefaultCategories } from '../api/contract';
import { Task, EditTasksPayload } from '../types/Contract';
import { useCallback } from 'react';

export const useDealHelper = () => {
  const [dealIdToAddTasks, setDealIdToAddTasks] = useRecoilState(dealToAddTasksState);
  const [dealIdToEditTask, setDealIdToEditTask] = useRecoilState(dealToEditTasksState);
  const categoriesData = useDefaultCategories();
  const { data: defaultTasks } = useCategoryDefaultTasks();

  const clearDealIdToAddTasks = () => {
    setDealIdToAddTasks(undefined);
  };

  const handleAddTasksToDeal = (dealId: number, categoryId: number) => {
    setDealIdToAddTasks({
      dealId,
      categoryId
    });
  };

  const getDefaultTasksForCategory = useCallback(
    (categoryId: number): Task[] => {
      const taskObj = defaultTasks?.find((task) => task.category_root.id === categoryId);
      return taskObj ? taskObj.tasks : [];
    },
    [defaultTasks]
  );

  const handleEditDealTask = (dealId: number, taskId: number, task: EditTasksPayload) => {
    setDealIdToEditTask({
      dealId,
      taskId,
      task
    });
  };

  const clearDealIdToEditTask = () => {
    setDealIdToEditTask(undefined);
  };

  return {
    dealIdToAddTasks,
    handleAddTasksToDeal,
    clearDealIdToAddTasks,
    categoriesData,
    getDefaultTasksForCategory,
    dealIdToEditTask,
    handleEditDealTask,
    clearDealIdToEditTask
  };
};
