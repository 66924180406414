import { FC } from 'react';
import { RenewalGanttChart } from 'components/molecules/RenewalGanttChart/RenewalGanttChart';
import { useRecoilValue } from 'recoil';
import { myState } from 'recoil/atoms';
import { GanttChartMode } from 'sb/shared/constants';

const Renewals: FC = () => {
  const me = useRecoilValue(myState);

  return (
    <>
      <h1 className="text-3xl tracking-tight">Smart Calendar&trade;</h1>
      <RenewalGanttChart renewalsObject="client" id={me?.client_id} mode={GanttChartMode.OWN_CALENDER} />
    </>
  );
};

export default Renewals;
