import { axios } from '../shared/axiosClient';
import { AxiosErrorHandler } from '../shared/Helpers';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Accuracy, EditAccuracyPayload, ProductAccuracy } from '../models/Product';
import { AxiosError } from 'axios';

export const getSupplierProductAccuracy = async (supplierId: number): Promise<ProductAccuracy[]> => {
  try {
    const res = await axios.get(`/secure/supplier/${supplierId}/products/accuracy`);
    return res.data?.data;
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useSupplierProductAccuracy = (
  supplierId: number,
  config: any
): UseQueryResult<ProductAccuracy[], AxiosError> =>
  useQuery({
    queryKey: ['productAccuracy', supplierId],
    queryFn: async () => await getSupplierProductAccuracy(supplierId),
    ...config
  });

const editProductAccuracy = async (id: number, data: Partial<Accuracy>): Promise<Accuracy> => {
  try {
    const res = await axios.patch(`/secure/contract_transaction/${id}/accuracy`, data);
    return res.data?.data;
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useEditProductAccuracy = (config: any = {}): UseMutationResult<Accuracy, AxiosError> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      data: EditAccuracyPayload & {
        transaction_id: number;
        supplierId: number;
      }
    ) => {
      const { transaction_id, supplierId, ...rest } = data;
      const editedAccuracy = await editProductAccuracy(transaction_id, rest);
      await queryClient.invalidateQueries(['productAccuracy', supplierId]);

      return editedAccuracy;
    },
    ...config
  });
};
