import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  InputNumber,
  Modal,
  ModalProps,
  Row,
  Skeleton,
  Space,
  Statistic,
  TimePicker,
  Typography
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { fetchContractRenewalNotifications } from 'api/contract';
import { RenewalNotification, RequestZone } from 'models/Renewals';
import { FormatCurrency } from 'shared/Helpers';
import './RenewalAlertsModal.scss';
import moment, { Moment } from 'moment-timezone';
import { BellFilled } from '@ant-design/icons';
import { TIMEZONE } from 'shared/constants';
import { createRenewalNotifications } from 'api/notification';
import { useSetRecoilState } from 'recoil';
import { renewalNotificationRequestState } from 'recoil/atoms';

const dateFormat = 'ddd, ll [@] h:mm a';

interface NotificationsFormValues {
  orangeChecked: boolean;
  orangeDaysBefore: number;
  orangeTime: string;
  redChecked: boolean;
  redDaysBefore: number;
  redTime: string;
  renewalDateChecked: boolean;
  renewalDateDaysBefore: number;
  renewalDateTime: string;
}

export interface RenewalNotificationRequest {
  notification_queue_id?: number;
  job_id?: number;
  name: NotificationNameType;
  contract_id: number;
  dateTime: string;
  daysBefore: number;
  zone: string;
  action: 'insert' | 'update' | 'delete' | 'ignore';
}

export enum NotificationNameType {
  RenewalNotification = 'renewal_notification'
}

interface Props extends ModalProps {
  contract_id?: number;
  // onSave: (results: RenewalNotificationRequest[]) => void;
}

export const RenewalAlertsModal: React.FC<Props> = ({ contract_id, children, ...rest }: Props) => {
  const [form] = Form.useForm();

  // LOCAL STATE
  const [open, setOpen] = useState(false);
  const [renewal, setRenewal] = useState<RenewalNotification | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const [orangeDisabled, setOrangeDisabled] = useState(true);
  const [redDisabled, setRedDisabled] = useState(true);
  const [renewalDateDisabled, setRenewalDateDisabled] = useState(true);

  const [orangeDate, setOrangeDate] = useState<Moment | undefined>(undefined);
  const [redDate, setRedDate] = useState<Moment | undefined>(undefined);
  const [renewalDate, setRenewalDate] = useState<Moment | undefined>(undefined);

  // RECOIL STATE
  const setRenewalNotificationRequest = useSetRecoilState(renewalNotificationRequestState);

  const resetForm = useCallback(() => {
    form.resetFields();
    setOrangeDisabled(true);
    setRedDisabled(true);
    setRenewalDateDisabled(true);
  }, [form]);

  useEffect(() => {
    if (!contract_id || !open) {
      resetForm();
      return;
    }
    (async () => {
      setLoading(true);
      try {
        const renewal: RenewalNotification | undefined = await fetchContractRenewalNotifications(contract_id);
        setRenewal(renewal);
      } finally {
        setLoading(false);
      }
    })();
  }, [contract_id, open, resetForm]);

  /**
   * Handle changes to the form values
   *
   * @param _changedFields
   * @param allFields
   */
  const handleFormChangeEvent = useCallback(
    (_changedFields: NotificationsFormValues, allFields: NotificationsFormValues) => {
      setOrangeDisabled(!allFields.orangeChecked);
      setRedDisabled(!allFields.redChecked);
      setRenewalDateDisabled(!allFields.renewalDateChecked);

      const runway = renewal?.vendor.renewalRunway;
      const orangeOffset = runway && runway < 6 ? 3 : runway && runway < 9 ? 2 : 1;

      const orangeTime = moment(allFields.orangeTime);
      const redTime = moment(allFields.redTime);
      const renewalDateTime = moment(allFields.renewalDateTime);

      setOrangeDate(
        moment(renewal?.contract_renewal_date)
          .tz(TIMEZONE)
          .subtract(renewal?.vendor.renewalRunway, 'months')
          .subtract(orangeOffset, 'months')
          .subtract(allFields.orangeDaysBefore, 'days')
          .startOf('day')
          .add(orangeTime.format('H'), 'hours')
          .add(orangeTime.format('mm'), 'minutes')
      );

      setRedDate(
        moment(renewal?.contract_renewal_date)
          .tz(TIMEZONE)
          .subtract(renewal?.vendor.renewalRunway, 'months')
          .subtract(allFields.redDaysBefore, 'days')
          .startOf('day')
          .add(redTime.format('H'), 'hours')
          .add(redTime.format('mm'), 'minutes')
      );

      setRenewalDate(
        moment(renewal?.contract_renewal_date)
          .tz(TIMEZONE)
          .subtract(allFields.renewalDateDaysBefore, 'days')
          .startOf('day')
          .add(renewalDateTime.format('H'), 'hours')
          .add(renewalDateTime.format('mm'), 'minutes')
      );
    },
    [renewal]
  );

  useEffect(() => {
    if (!renewal || renewal.notification_queue.length === 0) return;

    const values: NotificationsFormValues = form.getFieldsValue();

    for (const job of renewal.notification_queue) {
      // HANDLE EXISTING ORANGE ZONE NOTIFICATIONS
      if (job.request.zone === RequestZone.OrangeZone) {
        const time = moment(job.request.dateTime, 'yyyy-mm-dd hh:mm:ss');
        form.setFieldsValue({
          orangeChecked: true,
          orangeDaysBefore: job.request.daysBefore,
          orangeTime: moment(time.format('h:mm'), 'h:mm')
        });

        values.orangeChecked = true;
        values.orangeDaysBefore = job.request.daysBefore;
        values.orangeTime = time as any;
        setOrangeDate(time);
      }

      // HANDLE EXISTING RED ZONE NOTIFICATIONS
      if (job.request.zone === RequestZone.RedZone) {
        const time = moment(job.request.dateTime, 'yyyy-mm-dd hh:mm:ss');
        form.setFieldsValue({
          redChecked: true,
          redDaysBefore: job.request.daysBefore,
          redTime: moment(time.format('h:mm'), 'h:mm')
        });

        values.redChecked = true;
        values.redDaysBefore = job.request.daysBefore;
        values.redTime = time as any;
        setRedDate(time);
      }

      // HANDLE EXISTING RENEWAL DATE NOTIFICATIONS
      if (job.request.zone === RequestZone.RenewalDate) {
        const time = moment(job.request.dateTime, 'yyyy-mm-dd hh:mm:ss');
        form.setFieldsValue({
          renewalDateChecked: true,
          renewalDateDaysBefore: job.request.daysBefore,
          renewalDateTime: moment(time.format('h:mm'), 'h:mm')
        });

        values.renewalDateChecked = true;
        values.renewalDateDaysBefore = job.request.daysBefore;
        values.renewalDateTime = time as any;
        setRenewalDate(time);
      }
    }

    handleFormChangeEvent(values, values);
  }, [form, handleFormChangeEvent, renewal]);

  /**
   * Handle "Ok" Click
   */
  const handleOkClick = async () => {
    if (!contract_id || !renewal) return;
    const values: NotificationsFormValues = await form.getFieldsValue();
    const format = 'YYYY-MM-DD HH:mm:ss';
    const data: RenewalNotificationRequest[] = [];

    const [orangeJob] = renewal.notification_queue.filter((item) => item.request.zone === RequestZone.OrangeZone);
    const [redJob] = renewal.notification_queue.filter((item) => item.request.zone === RequestZone.RedZone);
    const [renewalDateJob] = renewal.notification_queue.filter((item) => item.request.zone === RequestZone.RenewalDate);
    const now = moment();

    data.push({
      notification_queue_id: orangeJob?.id,
      job_id: orangeJob?.job_id,
      action:
        orangeDate && orangeDate < now && values.orangeChecked
          ? 'ignore'
          : !values.orangeChecked
          ? 'delete'
          : orangeJob
          ? 'update'
          : 'insert',
      name: NotificationNameType.RenewalNotification,
      contract_id: contract_id,
      dateTime: orangeDate ? orangeDate.format(format) : moment().format(format),
      daysBefore: values.orangeDaysBefore,
      zone: 'orange'
    });

    data.push({
      notification_queue_id: redJob?.id,
      job_id: redJob?.job_id,
      action:
        redDate && redDate < now && values.redChecked
          ? 'ignore'
          : !values.redChecked
          ? 'delete'
          : redJob
          ? 'update'
          : 'insert',
      name: NotificationNameType.RenewalNotification,
      contract_id: contract_id,
      dateTime: redDate ? redDate.format(format) : moment().format(format),
      daysBefore: values.redDaysBefore,
      zone: 'red'
    });

    data.push({
      notification_queue_id: renewalDateJob?.id,
      job_id: renewalDateJob?.job_id,
      action:
        renewalDate && renewalDate < now && values.renewalDateChecked
          ? 'ignore'
          : !values.renewalDateChecked
          ? 'delete'
          : renewalDateJob
          ? 'update'
          : 'insert',
      name: NotificationNameType.RenewalNotification,
      contract_id: contract_id,
      dateTime: renewalDate ? renewalDate.format(format) : moment().format(format),
      daysBefore: values.renewalDateDaysBefore,
      zone: 'renewalDate'
    });

    try {
      setSaving(true);
      const results: RenewalNotificationRequest[] = await createRenewalNotifications(data);
      setRenewalNotificationRequest(results);
      setOpen(false);
    } catch (e) {
      console.log(e);
    } finally {
      setSaving(false);
    }
  };

  /**
   * Notifications JSX component
   *
   * @param prefix
   * @param hint
   * @param disabled
   * @param date
   * @constructor
   */
  const NotificationRow = (prefix: string, hint: React.JSX.Element, disabled: boolean, date: Moment | undefined) => {
    return (
      <Row gutter={12}>
        <Col span={1} style={{ textAlign: 'center' }}>
          <Form.Item label={undefined} name={`${prefix}Checked`} valuePropName={'checked'}>
            <Checkbox />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Form.Item label={undefined} name={`${prefix}DaysBefore`} initialValue={0}>
            <InputNumber style={{ width: '100%' }} min={0} disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <div className="hint">{hint}</div>
        </Col>
        <Col span={2}>
          <Avatar shape="square" className="avatar">
            @
          </Avatar>
        </Col>
        <Col span={4}>
          <Form.Item label={undefined} name={`${prefix}Time`} initialValue={moment('9:00', 'h:mm')}>
            <TimePicker style={{ width: '100%' }} use12Hours format="h:mm a" minuteStep={15} disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={9}>
          {!disabled && (
            <div className="date">
              {(date && date <= moment() && (
                <Typography.Text type="danger">
                  <strong>{date.format(dateFormat)}</strong> is earlier than the current time!
                </Typography.Text>
              )) || (
                <div>
                  You will receive a reminder email on <strong>{date ? date.format(dateFormat) : ''}</strong>
                </div>
              )}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        title={
          <>
            <BellFilled /> Renewal Reminder
          </>
        }
        open={open}
        closable={true}
        width={850}
        cancelText={'Close'}
        onCancel={() => setOpen(false)}
        confirmLoading={saving}
        onOk={handleOkClick}
        {...rest}
      >
        <div className={'renewal-alerts-modal'}>
          <Skeleton active loading={loading}>
            <h2>
              {renewal?.salesforce_deal_name}: {renewal?.subject}
            </h2>
            <Space split={<Divider type="vertical" />}>
              <Statistic title={'Vendor'} value={renewal?.vendor.name} />
              <Statistic title={'Contract Value'} value={FormatCurrency(renewal?.tcv, 0)} />
              <Statistic title={'Renewal Date'} value={moment(renewal?.contract_renewal_date).format('ll')} />
              <Statistic title={'Renewal Runway'} value={`${renewal?.vendor.renewalRunway} Months`} />
            </Space>
            <Divider className="horizontal-divider" />
            <h3>Notifications Timing</h3>
          </Skeleton>
          <Form size="large" onValuesChange={handleFormChangeEvent} form={form}>
            {NotificationRow(
              'orange',
              <div>
                Days before deal enters <span className="orange-zone">Orange Zone</span>
              </div>,
              orangeDisabled,
              orangeDate
            )}
            {NotificationRow(
              'red',
              <div>
                Days before deal enters <span className="red-zone">Red Zone</span>
              </div>,
              redDisabled,
              redDate
            )}
            {NotificationRow(
              'renewalDate',
              <div>
                Days before <span className="renewal-date">Renewal Date</span>
              </div>,
              renewalDateDisabled,
              renewalDate
            )}
          </Form>
        </div>
      </Modal>
      <Button type={'text'} onClick={() => setOpen(true)} size={'large'}>
        {children}
      </Button>
    </>
  );
};
