import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { BuyerCaddyCompetitor, CommunityType, PeerGroupUsage } from 'models/SupplierDynamics';
import { orderBy } from 'lodash-es';
import { useQuery } from '@tanstack/react-query';

export async function getMyVendors() {
  try {
    const res = await axios.get(`/secure/client/vendors`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendors(str: string, reseller?: boolean, limitToVendorDynamics?: boolean) {
  try {
    const res = await axios.get(
      `/secure/${!reseller ? 'vendors' : 'resellers'}/search?str=${str}&vdi=${
        limitToVendorDynamics !== undefined ? `${+limitToVendorDynamics}` : ''
      }`
    );
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select supplier info
 *
 * @param id
 * @return
 */
export async function getVendor(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}`);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function getVendorCategories(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/categories`);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function getVendorDealDynamics(id: number, category_id?: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/dealDynamics${category_id ? `?category_id=${category_id}` : ''}`);

    return res.data;
  } catch (e) {
    return e;
  }
}

export const getVendorDealDynamicsAvg = async (id: number, categoryId?: number) => {
  try {
    const res = await axios.get(
      `/secure/vendor/${id}/dealDynamicsAvg${categoryId ? `?category_id=${categoryId}` : ''}`
    );
    return res.data;
  } catch (e) {
    return e;
  }
};

export const getVendorDealDynamicNotes = async (
  supplier_id: number,
  deal_dynamic_id: number,
  supplier_category_id?: number
) => {
  try {
    const res = await axios.get(`/secure/vendor/${supplier_id}/dealDynamics/notes`, {
      params: {
        deal_dynamic_id: deal_dynamic_id,
        supplier_category_id: supplier_category_id || undefined
      }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export async function getVendorReviewQuestions() {
  try {
    const res = await axios.get(`/secure/vendor/review/questions`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createVendorReview(id: number, values: any) {
  try {
    const res = await axios.post(`/secure/vendor/${id}/review`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorNotesByType(id: number, type: CommunityType) {
  try {
    const res = await axios.get(`/api/vendor/${id}/notes/${type}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorOverallRatings(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/ratings/overall`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorQuestionRatings(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/question_ratings`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorGoogleNews(name: string) {
  try {
    const res = await axios.get(`/secure/vendor/${name}/google-news`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorReviews(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/reviews`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendorReviewHelpful(id: number, reviewId: number) {
  try {
    const res = await axios.post(`/secure/vendor/${id}/review/${reviewId}/is_helpful`, {
      reaction: 1
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const getDocuments = async (id: number) => {
  try {
    const res = await axios.get(`/secure/vendor/${id}/documents`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch vendors with a supplier dynamics record
 *
 * @param profile_id
 */
export const fetchSDSuppliers = async (profile_id?: number) => {
  try {
    const res = await axios.get(`/secure/supplier_dynamics/suppliers`, {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch vendors list
 * @param profile_id
 */
export const fetchSDSuppliersList = async (profile_id?: number) => {
  try {
    const res = await axios.get(`/api/supplierDynamics/suppliers`, {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch vendors supplier dynamics scores
 *
 * @param supplier_id
 */
export const fetchSDSupplierScores = async (supplier_id?: number) => {
  try {
    const res = await axios.get(`/api/supplierDynamics/supplier/${supplier_id}/scores`, {
      params: { supplier_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch a vendor's competitors
 *
 * @param id
 * @param supplier_category_id
 */
export const getCompetitors = async (id: number, supplier_category_id?: number) => {
  try {
    const res = await axios.get(`/api/supplier/${id}/competitors`, {
      params: { supplier_category_id: supplier_category_id || null }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Retrieves the competitors of a supplier based on their ID.
 *
 * @async
 * @param {number} id - The ID of the supplier to retrieve competitors for.
 * @returns {Promise<any[] | undefined>} - A Promise that resolves with an array of competitors,
 *                                         or undefined if an error occurs.
 */
export const getSupplierCompetitors = async (id: number): Promise<any[] | undefined> => {
  try {
    const res = await axios.get(`/api/supplierDynamics/supplier/${id}/competitors`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch a vendor's competitors
 *
 * @param id
 */
export const getVendorPricingTrend = async (id: number) => {
  try {
    const res = await axios.get(`/secure/supplier/${id}/pricing/trend`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update Vendor Logo filename field
 *
 * @param vendor_id
 * @param logo_filename
 */
export const updateSupplierLogoFilename = async (vendor_id: number, logo_filename: string) => {
  try {
    const res = await axios.patch(`/secure/supplier/${vendor_id}/logoFilename`, { logo_filename });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Retrieve a vendor's ESG scores
 *
 * @param vendor_id
 */
export async function fetchVendorESGScores(vendor_id: number) {
  try {
    const res = await axios.get(`/api/supplier/${vendor_id}/esg`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select supplier selections
 */
export async function selectSupplierSelections(take?: number) {
  try {
    const res = await axios.get(`/api/supplier/selections`, {
      params: { take }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select supplier title for SupplierCardTitle
 *
 * @param id
 */
export const selectSupplierTitle = async (id: number) => {
  try {
    const res = await axios.get(`/sd/supplier/${id}/title`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Select My Supplier
 *
 * @param id
 * @param profile_id
 */
export const selectMySupplier = async (id: number, profile_id?: number) => {
  try {
    const res = await axios.get(`/api/supplier/${id}/my`, {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

const getPeerGroupUsage = (params: { supplier_id?: number; profile_id?: number }): Promise<Array<PeerGroupUsage>> => {
  const { supplier_id, profile_id } = params;
  const reqUrl = `/api/cohorts/supplier/${supplier_id}/usage`;

  return axios
    .get(reqUrl, { params: { profile_id } })
    .then((res) => orderBy(res?.data?.data, ['percentage'], ['desc']));
};

export const usePeerGroupUsage = (params: { supplier_id?: number; profile_id?: number }, config = {}) => {
  const queryKey = ['peerGroupUsage', params.supplier_id, params.profile_id].filter(Boolean);

  return useQuery({
    queryKey,
    queryFn: () => getPeerGroupUsage(params),
    ...config
  });
};

const getClientCompetitors = (params: { profile_id?: number }): Promise<Array<BuyerCaddyCompetitor>> => {
  const { profile_id } = params;
  const reqUrl = `/api/companies/competitors`;

  return axios.get(reqUrl, { params: { profile_id } }).then((res) => res?.data?.data);
};

export const useClientCompetitors = (params: { profile_id?: number }, config = {}) => {
  const queryKey = ['clientCompetitors', params.profile_id].filter(Boolean);

  return useQuery({
    queryKey,
    queryFn: () => getClientCompetitors(params),
    ...config
  });
};
