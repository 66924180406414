import { Inquiry, InquiryStatusForm, NoteStatus, OtherNote } from 'sb/models/Inquiry';
import {
  Avatar,
  Button,
  Card,
  CardProps,
  Col,
  Collapse,
  Comment,
  Descriptions,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Typography,
  Modal
} from 'antd';
import { URGENCY_LOV } from 'sb/shared/constants';
import moment from 'moment/moment';
import { useRecoilValue } from 'recoil';
import { allAccountUsersState, myState } from 'recoil/atoms';
import { statusLovState } from 'recoil/selectors';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect, useState } from 'react';
import nl2br from 'react-nl2br';
import { insertInquiryReply, insertInquiryStatus, selectInquiry } from 'sb/api/note';
import { getCommentDateTime } from 'sb/shared/Helpers';
import './InquiryWorkbenchCard.scss';
import { reverse } from 'lodash-es';

interface Props extends Omit<CardProps, 'id'> {
  id: number;
  onCancel?: () => void;
  onUpdate: () => void;
  open: boolean;
}

export const InquiryWorkbenchCard: React.FC<Props> = ({ id, onCancel, onUpdate, open, ...rest }: Props) => {
  const [replyForm] = useForm();
  const [updateStatusForm] = useForm();

  // LOCAL STATE
  const [inquiry, setInquiry] = useState<Inquiry | undefined>(undefined);
  const [fetchingInquiry, setFetchingInquiry] = useState(false);
  const [submittable, setSubmittable] = useState(false);

  // RECOIL STATE
  const npiUsers = useRecoilValue(allAccountUsersState);
  const statusLov = useRecoilValue(statusLovState);
  const me = useRecoilValue(myState);

  // Watch all values
  const values = Form.useWatch([], replyForm);

  useEffect(() => {
    if (replyForm?.getFieldValue('note')) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  }, [values, replyForm]);

  const fetchInquiry = useCallback(async () => {
    if (!id) return;

    try {
      setFetchingInquiry(true);
      const selectedInquiry = await selectInquiry(id);
      setInquiry(selectedInquiry);
    } finally {
      setFetchingInquiry(false);
    }
  }, [id]);

  useEffect(() => {
    if (!id) return;

    (async () => {
      await fetchInquiry();
    })();
  }, [fetchInquiry, id]);

  useEffect(() => {
    if (open) return;
    setInquiry(undefined);
  }, [open]);

  const cardTitle = (item: Inquiry) => {
    return (
      <Typography.Title level={4} className={'text-gray-200'}>
        <Space>
          <>
            <span>{item.users.name}</span> @ <span>{item.users.client.display_name}</span>
          </>
        </Space>
      </Typography.Title>
    );
  };

  const resetStatusForm = useCallback(() => {
    if (!inquiry) return;
    updateStatusForm.setFieldsValue({
      status_id: inquiry.note_status.length ? inquiry.note_status[0].lov_status.id : undefined,
      assigned_to: inquiry.note_status
        ? inquiry.note_status[0].users_note_status_assigned_toTousers?.auth0_user_id
        : undefined,
      note: undefined
    });
  }, [updateStatusForm, inquiry]);

  const resetReplyForm = useCallback(() => {
    replyForm.setFieldsValue({ note: undefined });
  }, [replyForm]);

  useEffect(() => {
    if (!id) return;
    resetStatusForm();
  }, [updateStatusForm, id, resetStatusForm]);

  const noteReply = (reply: OtherNote) => {
    const initial = reply.users.first_name ? reply.users.first_name.substring(0, 1) : '-';
    return (
      <Comment
        key={reply.id}
        content={
          <div className="rounded-[0.5rem] p-4 min-w-[60%] max-w-[80%] inline-block text-left text-bubble">
            <div className="text-xs">
              {reply.users.auth0_user_id === me?.user_id ? me.name : reply.users.first_name || reply.users.first_name}
            </div>
            <div className="mb-2 text-base">{nl2br(reply.note)}</div>
            <div className="text-xs opacity-[0.5]">{getCommentDateTime(reply.creation_date)}</div>
          </div>
        }
        className={reply.users.auth0_user_id === me?.user_id ? 'my-comment' : ''}
        avatar={
          reply.users.auth0_user_id === me?.user_id ? (
            <Avatar className="mt-2">
              {me.given_name.charAt(0)}
              {me.family_name.charAt(0)}
            </Avatar>
          ) : (
            <Avatar className="mt-2">{initial}</Avatar>
          )
        }
      />
    );
  };

  const onFinishHandler = async (data: { note: string }) => {
    if (!inquiry) return;
    setFetchingInquiry(true);
    const response = await insertInquiryReply(inquiry.id, {
      note: data.note,
      vendor_id: inquiry.vendor?.id
    });
    if (response) {
      message.success('Reply posted successfully!');
      resetReplyForm();
      onUpdate();
      await fetchInquiry();
    }
  };

  const onUpdateStatusFinishHandler = async (data: InquiryStatusForm) => {
    if (!id) return;

    await insertInquiryStatus(id, data);
    onUpdate();
    await fetchInquiry();
  };

  if (inquiry) {
    return (
      <Modal
        {...rest}
        open={open}
        onCancel={onCancel}
        title={cardTitle(inquiry)}
        width={1000}
        bodyStyle={{ overflow: 'auto', maxHeight: 'calc(100vh - 450px)', paddingTop: 0, paddingBottom: 0 }}
        footer={
          <div style={{ padding: '10px 0px' }}>
            <Form size={'large'} form={replyForm} name="validateOnly" onFinish={onFinishHandler}>
              <Form.Item name={'note'} rules={[{ required: true, message: "Reply can't be blank." }]}>
                <Input.TextArea size="large" rows={4} placeholder={'Reply to inquiry or ask a follow up question'} />
              </Form.Item>
              <Form.Item style={{ marginBottom: '0' }}>
                <Space>
                  <Button onClick={onCancel}>Close</Button>
                  <Button type={'primary'} htmlType={'submit'} loading={fetchingInquiry} disabled={!submittable}>
                    Reply
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        }
      >
        <>
          <Form layout={'vertical'} size={'middle'} form={updateStatusForm} onFinish={onUpdateStatusFinishHandler}>
            <Row
              className="px-4 py-5 border-b mb-4 !-mx-6"
              style={{ backgroundColor: '#F7F7F7' }}
              gutter={[16, 0]}
              align={'bottom'}
            >
              <Col>
                <Form.Item
                  label={'Update Inquiry Status'}
                  name={'status_id'}
                  rules={[{ required: true, message: 'A status is required' }]}
                  className="mb-0"
                >
                  <Select
                    options={statusLov}
                    style={{ width: 220 }}
                    fieldNames={{ label: 'name', value: 'id' }}
                    placeholder={'Select Status'}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'Assign To'} name={'assigned_to'} className="mb-0">
                  <Select
                    options={npiUsers}
                    style={{ width: 220 }}
                    placeholder={'Assign to an analyst'}
                    fieldNames={{ label: 'name', value: 'user_id' }}
                    showSearch
                    allowClear
                    optionFilterProp="name"
                    optionLabelProp="name"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item className="mb-0">
                  <Space>
                    <Form.Item noStyle>
                      <Button type={'primary'} htmlType={'submit'} loading={fetchingInquiry}>
                        Update Status
                      </Button>
                    </Form.Item>
                    <Form.Item noStyle>
                      <Button onClick={resetStatusForm}>Reset</Button>
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <div className="mb-2">
            <Row>
              <Col span={3}>
                <Typography.Text type={'secondary'} strong={true}>
                  Subject :
                </Typography.Text>
              </Col>
              <Col span={21}>{`${inquiry?.subject}`}</Col>
            </Row>
            <Row>
              <Col span={3}>
                <Typography.Text type={'secondary'} strong={true}>
                  Vendor :
                </Typography.Text>
              </Col>
              <Col span={21}>{`${inquiry?.vendor?.name}`}</Col>
            </Row>
            <Row className="mb-4">
              <Col span={3}>
                <Typography.Text type={'secondary'} strong={true}>
                  Inquiry :
                </Typography.Text>
              </Col>
              <Col span={21}>{nl2br(inquiry.note)}</Col>
            </Row>

            <Descriptions className="mb-4 ml-[12.5%]">
              <Descriptions.Item label="Posted" style={{ paddingBottom: 0 }}>
                {moment(inquiry.creation_date).utc().format('ll')}
              </Descriptions.Item>
              <Descriptions.Item label={'Current Status'} style={{ paddingBottom: 0 }}>
                {inquiry.note_status.length ? inquiry.note_status[0].lov_status.name : 'Submitted'}
              </Descriptions.Item>
              <Descriptions.Item label={'Assigned To'} style={{ paddingBottom: 0 }}>
                {inquiry.note_status.length && inquiry.note_status[0].users_note_status_assigned_toTousers?.first_name
                  ? inquiry.note_status[0].users_note_status_assigned_toTousers?.first_name
                  : '--'}
              </Descriptions.Item>
              <Descriptions.Item label={'Urgency'} style={{ paddingBottom: 0 }}>
                {
                  URGENCY_LOV.filter((lov) => {
                    return lov.value === inquiry.urgency;
                  })[0].label
                }
              </Descriptions.Item>
              <Descriptions.Item label={'Needed By'} style={{ paddingBottom: 0 }}>
                {inquiry.date_needed ? moment(inquiry.date_needed).utc().format('ll') : '..'}
              </Descriptions.Item>
            </Descriptions>
          </div>

          <Typography.Title level={5}>Replies</Typography.Title>
          <div style={{ backgroundColor: '#F7F7F7' }} className="px-6 py-6 -mx-6">
            <Skeleton loading={fetchingInquiry} active>
              {inquiry.other_note?.length === 0 && <div style={{ padding: '1rem' }}>No replies yet!</div>}
              {reverse(inquiry.other_note).map(noteReply)}
            </Skeleton>
          </div>

          <Collapse bordered={false} className="px-4 bg-white border-t !-mx-6">
            <Collapse.Panel key={1} header={<Typography.Title level={5}>History</Typography.Title>}>
              <Table
                showHeader={false}
                size={'small'}
                dataSource={inquiry.note_status}
                loading={fetchingInquiry}
                columns={[
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (_, rec: NoteStatus) => rec.lov_status.name
                  },
                  {
                    title: 'Assigned To',
                    dataIndex: 'assigned_to',
                    render: (_, rec: NoteStatus) => {
                      if (npiUsers) {
                        return rec.users_note_status_assigned_toTousers
                          ? rec.users_note_status_assigned_toTousers.first_name
                          : '--';
                      } else {
                        return '--';
                      }
                    }
                  },
                  {
                    title: 'Remark',
                    dataIndex: 'remark',
                    render: (_, rec: NoteStatus) => (
                      <Typography.Paragraph
                        style={{ width: 200 }}
                        ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}
                      >
                        {rec.remark || '--'}
                      </Typography.Paragraph>
                    )
                  },
                  {
                    title: 'Date',
                    dataIndex: 'date_created',
                    render: (_, rec: NoteStatus) =>
                      rec.date_created ? moment(rec.date_created).utc().format('lll') : '--'
                  }
                ]}
              />
            </Collapse.Panel>
          </Collapse>
        </>
      </Modal>
    );
  } else {
    return (
      <Modal {...rest} onCancel={onCancel} title={'Workbench Inquiry'} width={800}>
        <Card title={'Fetching Inquiry'}>
          <Skeleton loading={!inquiry} active />
        </Card>
      </Modal>
    );
  }
};
