import { Select, SelectProps } from 'antd';
import React from 'react';
import './MultiSelect.scss';
import { DisplayValueType } from 'rc-select/lib/BaseSelect';

/**
 * Props interface for the Props class.
 *
 * @interface Props
 * @extends {SelectProps}
 */
interface Props extends SelectProps {
  className?: string | undefined;
  placeholder?: React.ReactNode;
  allowClear?: boolean | undefined;
  mode?: 'multiple' | 'tags';
  bordered?: boolean | undefined;
  value?: any[];
  onFocus?: React.FocusEventHandler<HTMLElement>;
  optionLabelProp?: string | undefined;
  dropdownRender?: (menu: React.ReactElement) => React.ReactElement;
  tagRender?: (props: any) => React.ReactElement;
  maxTagPlaceholder?: React.ReactNode | ((omittedValues: DisplayValueType[]) => React.ReactNode);
  children?: React.ReactNode;

  options?: Array<{ value: number | string; label: any }>;
}

/**
 * A reusable Select component that wraps around the antd Select component.
 * @component
 * @param {Object} props - The properties for the SelectReusable component.
 * @param {string} props.className - The class name for styling the Select component.
 * @param {string} props.placeholder - The placeholder text to display when the Select input is empty.
 * @param {boolean} props.allowClear - Whether to show the clear button inside the Select input.
 * @param {string} props.mode - Whether the Select component is in single or multiple mode.
 * @param {boolean} props.bordered - Whether to show border around the Select component.
 * @param {any} props.value - The current value(s) of the Select component.
 * @param {function} props.onChange - The callback function to be called when the value of the Select component changes.
 * @param {function} props.onFocus - The callback function to be called when the Select component receives focus.
 * @param {string} props.optionLabelProp - The prop name for the label of options.
 * @param {function} props.dropdownRender - The custom dropdown renderer for the Select component.
 * @param {function} props.tagRender - The custom tag renderer for the Select component.
 * @param {string|function} props.maxTagPlaceholder - Text to display when there are more selected items than the specified max tag count.
 * @param {ReactNode} props.children - The child elements to be rendered inside the Select component.
 * @param {Array} props.options - The options to be displayed in the dropdown menu of the Select component.
 * @returns {ReactElement} - The rendered SelectReusable component.
 */
const SelectReusable: React.FC<Props> = ({
  className,
  placeholder,
  allowClear,
  mode,
  bordered,
  value,
  onFocus,
  optionLabelProp,
  dropdownRender,
  tagRender,
  maxTagPlaceholder,
  children,
  options,
  onChange,
  ...rest
}: Props): React.ReactElement => {
  return (
    <Select
      className={className}
      placeholder={placeholder}
      allowClear={allowClear}
      mode={mode}
      bordered={bordered}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      optionLabelProp={optionLabelProp}
      dropdownRender={dropdownRender}
      tagRender={tagRender}
      maxTagPlaceholder={maxTagPlaceholder}
      options={options}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default SelectReusable;
