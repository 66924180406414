import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { Permission, PermissionMethods } from 'sb/models/Permission';

export async function getAdminModeratedContent(isReleased: 0 | 1) {
  try {
    const res = await axios.get(`/secure/admin/moderated_content?is_released=${isReleased}`);
    if (res.data.status === 'error') {
      throw new Error(res.data.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getAdminModeratedReviews(isReleased: 0 | 1) {
  try {
    const res = await axios.get(`/secure/admin/moderated_reviews?is_released=${isReleased}`);
    if (res.data.status === 'error') {
      throw new Error(res.data.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateAdminNoteReleased(id: number, isReleased: 0 | 1) {
  try {
    const res = await axios.post(`/secure/admin/note/${id}/is_released`, { is_released: isReleased });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateAdminReviewReleased(id: number, isReleased: 0 | 1) {
  try {
    const res = await axios.post(`/secure/admin/review/${id}/is_released`, { is_released: isReleased });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch user from Auth0
 *
 * @param auth0_user_id
 */
export const fetchAuth0User = async (auth0_user_id: string) => {
  try {
    const url = `/auth0/user/${auth0_user_id}`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export async function getPermissions() {
  try {
    const res = await axios.get(`/secure/admin/permissions`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updatePermission(oldP: Partial<Permission>, newP: Partial<Permission>) {
  const methods = [
    PermissionMethods.create,
    PermissionMethods.read,
    PermissionMethods.update,
    PermissionMethods.delete
  ];
  try {
    const res = await axios.put('/secure/admin/permissions', {
      old: { name: oldP.name, methods: methods.filter((method) => oldP?.[method]) },
      new: { name: newP.name, methods: methods.filter((method) => newP?.[method]) }
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deletePermission(scope: string) {
  try {
    const res = await axios.delete(`/secure/admin/permissions/${scope}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getRoles() {
  try {
    const res = await axios.get(`/secure/admin/roles`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createRole(data: {
  name: string;
  description: string;
  is_client_role: boolean;
  permissions: number[];
}) {
  try {
    const res = await axios.post(`/secure/admin/role`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateRole(
  id: number,
  data: { name: string; description: string; is_client_role: boolean; permissions: number[] }
) {
  try {
    const res = await axios.put(`/secure/admin/role/${id}`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteRole(id: number) {
  try {
    const res = await axios.delete(`/secure/admin/role/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
