import { TablePaginationConfig, StepProps } from 'antd';
import { ProductColumn } from 'sb/models/Product';

export const APP_NAME = import.meta.env['VITE_APP_APP_NAME'] || 'NPI Soft';
export const BASE_URL = import.meta.env['VITE_APP_EXPRESS_URL'];
export const COMPANY_DOMAIN = 'npifinancial.com';
export const NPI_DIGITAL_URL = import.meta.env['VITE_APP_NPI_DIGITAL_URL'];
export const VITE_APP_ENV = import.meta.env['VITE_APP_ENV'] || 'development';
export const NODE_ENV = import.meta.env.NODE_ENV;
export const LOGROCKET = import.meta.env['VITE_APP_LOGROCKET'] || 'npi-digital/smartbench-aywb3';
export const BUGSNAG_APIKEY = import.meta.env['VITE_APP_BUGSNAG_APIKEY'] || '';
export const PIWIK_CONTAINER_ID = import.meta.env['VITE_APP_PIWIK_CONTAINER_ID'] || '';
export const PIWIK_CONTAINER_URL = import.meta.env['VITE_APP_PIWIK_CONTAINER_URL'] || '';

export const AUTH0_CONFIG = {
  audience: import.meta.env['VITE_APP_AUTH0_AUDIENCE'] || '',
  clientId: import.meta.env['VITE_APP_AUTH0_CLIENT_ID'] || '',
  domain: import.meta.env['VITE_APP_AUTH0_DOMAIN'] || ''
};

export const cardBodyStyleStatistic = { backgroundColor: '#f8f8f8', borderBottom: '1px solid #DF7A00' };

export const Columns: Array<ProductColumn> = [
  { value: 'line_number', name: 'Line Number', description: 'Line Number.', isPartNumber: false },
  { value: 'part_number', name: 'Part No.', description: 'Part Number, SKU, etc.', isPartNumber: true },
  {
    value: 'product_description',
    serverField: 'product_name',
    name: 'Description',
    description: 'Product description or product name.',
    isPartNumber: false
  },
  {
    value: 'usage_indicator',
    name: 'Usage Indicator',
    description: 'Found in Microsoft contracts, usually "Adjustable"',
    isPartNumber: false
  },
  {
    value: 'unit_of_measure',
    serverField: 'authorized_use',
    name: 'Unit of Measure',
    description: 'E.g. "Users", "Months", "CPU", "Device", etc.',
    isPartNumber: false
  },
  {
    value: 'unit_quantity',
    name: 'Unit Quantity',
    description: 'A number typically paired with Unit of Measure, e.g. "12 months", "3000 Users"',
    isPartNumber: false
  },
  {
    value: 'start_date',
    name: 'Start Date',
    description: 'The date a subscription or license begins',
    isPartNumber: false
  },
  { value: 'end_date', name: 'End Date', description: 'The date a subscription or license ends', isPartNumber: false },
  {
    value: 'net_unit_price',
    serverField: 'unit_license_cost',
    name: 'Unit Price',
    description: 'Unit price or unit cost',
    isPartNumber: false
  },
  {
    value: 'usage_country',
    name: 'Usage Country',
    description: 'In what country is a subscription or license is intended',
    isPartNumber: false
  },
  {
    value: 'license_quantity',
    serverField: 'capacity',
    name: 'Quantity',
    description: 'A number indicating quantity of items, licenses, etc.',
    isPartNumber: false
  },
  { value: 'discount', name: 'Discount', description: 'Discount', isPartNumber: false },
  { value: 'list_price', name: 'List Price', description: 'List Price', isPartNumber: false },
  {
    value: 'extended_price',
    serverField: 'total_license_cost',
    name: 'Extended Price',
    description: 'Extended Price',
    isPartNumber: false
  }
];

export const scrollOptions = {
  duration: 1200,
  smooth: 'easeOutSine',
  offset: -50
};

export const CONTRACT_STEPS: Array<StepProps> = [
  {
    title: 'Step 1',
    description: 'Summary'
  },
  {
    title: 'Step 2',
    description: 'Documents'
  },
  {
    title: 'Step 3',
    description: 'Details'
  },
  {
    title: 'Step 4',
    description: 'Deal Context'
  },
  {
    title: 'Step 5',
    description: 'Bill of Materials'
  }
];

export const AGREEMENT_TERMS = [
  '1 Year',
  '2 Years',
  '3 Years',
  '4 Years',
  '5 Years',
  '6 Years',
  '7 Years',
  '8 Years',
  '9 Years',
  '10 Years'
];

export const MARKS = {
  [-12]: -12,
  [-11]: -11,
  [-10]: -10,
  [-9]: -9,
  [-8]: -8,
  [-7]: -7,
  [-6]: -6,
  [-5]: -5,
  [-4]: -4,
  [-3]: -3,
  [-2]: -2,
  [-1]: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: 10,
  11: 11,
  12: 12
};

export interface ChartTypes {
  value: string;
  label: string;
}

export const CHART_TYPES = [
  { value: 'solidGauge', label: 'Solid Gauge' },
  { value: 'linearScale', label: 'Linear Scale' },
  { value: 'upDownArrow', label: 'Up/Down Arrow' }
];

export const DEAL_DYNAMIC_TYPES = [
  { value: 'dealDynamic', label: 'Deal Dynamics' },
  { value: 'pricingDynamic', label: 'Pricing Dynamics' }
];

export const DEFAULT_TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
  position: ['topRight', 'bottomRight'],
  hideOnSinglePage: false,
  showSizeChanger: true,
  defaultPageSize: 20,
  showTotal: (total) => `Total: ${total}`
};

export const PERMISSION_ROLE_TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
  position: ['topRight', 'bottomRight'],
  hideOnSinglePage: false,
  showSizeChanger: true,
  defaultPageSize: 100,
  showTotal: (total) => `Total: ${total}`
};

export const CONFIDENCE_RATING = [
  { value: -2, label: 'Very Low' },
  { value: -1, label: 'Low' },
  { value: 0, label: 'Medium' },
  { value: -2, label: 'High' },
  { value: -2, label: 'Very High' }
];

export const ANALYSIS_SCORE = [
  { value: -2, label: 'Much Above FMV', color: '#fe6e6e' },
  { value: -1, label: 'Above FMV', color: '#fdcc36' },
  { value: 0, label: 'Fair Market Value', color: '#555' },
  { value: 1, label: 'Below FMV', color: '#a1cd6c' },
  { value: 2, label: 'Much Below FMV', color: '#30aa9e' }
];

export const COMPS_PLOT_COLORS = {
  default: `rgba(119, 152, 191, .75)`,
  thisContract: `rgba(211, 84, 0, 1)`,
  bestInClass: '#81c53f',
  linkedSkus: `rgba(255, 76, 48, .75)`
};

type SelectType = {
  value: number;
  label: string;
};

export const PRIMARY_DEAL_TYPES: Array<SelectType> = [
  { value: 4, label: 'Divestiture' },
  { value: 3, label: 'New purchase' },
  { value: 2, label: 'Add-on purchase' },
  { value: 1, label: 'Renewal' }
];

export const ENTERED_AFTER_OPTIONS = [
  { value: '1', label: 'In the past month' },
  { value: '3', label: 'In the past 3 months' },
  { value: '6', label: 'In the past 6 months' },
  { value: '12', label: 'In the past 1 year' },
  { value: '24', label: 'In the past 2 years' }
];

export const RENEWING_SELECT_OPTIONS = [
  { value: '1', label: 'Within 1 month' },
  { value: '3', label: 'Within 3 months' },
  { value: '6', label: 'Within 6 months' },
  { value: '9', label: 'Within 9 months' },
  { value: '12', label: 'Within 1 year' },
  { value: '24', label: 'Within 2 years' },
  { value: '36', label: 'Within 3 years' },
  { value: '60', label: 'Within 5 years' }
];

export const DEAL_STATUS = [
  'Open',
  'Pending Assignment',
  'Submitted',
  'In Progress',
  'Research',
  'Negotiation',
  'Findings Provided',
  'Closed',
  'On Hold'
];

export const COLOR_SCALE_FIVE_ASC = ['#7abd7e', '#8cd47e', '#f8d66d', '#ffb54c', '#ff6961'];

export const URGENCY_LOV = [
  { value: 0, label: 'Highest' },
  { value: 1, label: 'High' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'Low' },
  { value: 4, label: 'Lowest' }
];

export enum STORAGE_KEY {
  ReportsSearchBy = 'reportsSearchBy',
  ReportsSalesforceClient = 'salesforceClient',
  ReportsSupplier = 'reportsSupplier'
}

export enum COLORS {
  Neutral = '#30aa9e',
  Green = '#a1cd6c',
  Yellow = '#ffd12f',
  Orange = '#ff9a3e',
  Red = '#ff6961'
}

export enum RELATIONSHIP_KIND {
  PARENT = 'parent',
  CHILD = 'child'
}

export const vendorList = [
  '1Password',
  '3M',
  '8x8',
  'ABBYY Software House',
  'ACCENTURE',
  'ADP, Inc.',
  'AT&T',
  'AVAYA INC',
  'AVI-SPL',
  'AXWAY',
  'Ab Initio',
  'Accuity',
  'Achievers',
  'Acorio',
  'Acoustic',
  'ActivePDF',
  'Adobe',
  'Advantix Solutions',
  'Aethereus',
  'Aha!',
  'Ahead',
  'Airtable',
  'Akamai Technologies',
  'Alation',
  'Alfresco (a Hyland Company)',
  'Alipro',
  'Allocadia',
  'Alteryx',
  'Amazon Web Services (AWS)',
  'Amazon.com',
  'Amperity',
  'Anaplan',
  'Anchore',
  'Anomali',
  'Ansys',
  'Apica Systems',
  'Apigee Corporation',
  'Apollo',
  'AppDynamics',
  'AppOmni',
  'Apple',
  'Apptio',
  'Aptitude',
  'Aptos',
  'Argus Software',
  'Arista Networks',
  'Armis',
  'Aruba Networks',
  'Asana',
  'Asignet',
  'Athenahealth',
  'Atlassian',
  'Audiocodes',
  'Auditboard',
  'Auth0',
  'AutoDesk',
  'Automation Anywhere',
  'Avalara',
  'AvePoint',
  'Aviatrix',
  'Avocent Corporation',
  'BMC Software',
  'BONbLOC Technologies Private Limited',
  'Beeline',
  'Bell Mobility',
  'BeyondTrust',
  'BigID',
  'BigPanda',
  'BigTinCan',
  'Birst',
  'Black Kite',
  'BlackLine Systems',
  'Blackbaud',
  'Blue Prism',
  'Blue Yonder (JDA)',
  'BlueCat Networks',
  'Bluecore',
  'Booz Allen Hamilton',
  'Box',
  'Braze',
  'BrightSpot',
  'Brocade Communications Systems, Inc',
  'Bynder',
  'CA Technologies (CA)',
  'CA/Broadcom',
  'Calero Software, LLC',
  'Calypso',
  'Capgemini',
  'CaptivateIQ',
  'Catchpoint',
  'Centercode',
  'Check Point Software',
  'Checkmarx',
  'Cheq',
  'Chronicle Security',
  'Ciena',
  'Cisco Systems',
  'Citibank',
  'Citrix',
  'Clearwater Compliance',
  'Cloud Software Group',
  'CloudBolt',
  'CloudFlare',
  'CloudHealth',
  'Cloudera',
  'Cloudian',
  'Code42',
  'Coginiti',
  'Cognizant',
  'Cohesity',
  'Collibra',
  'Commvault',
  'CompuCom',
  'Computacenter',
  'Confluent',
  'Conga',
  'Contentful',
  'Contrast Security',
  'ControlUp',
  'Cornerstone OnDemand',
  'Corvil',
  'Coupa',
  'Creative Force',
  'Cribl',
  'CrowdStrike',
  'Crown Castle',
  'CybelAngel',
  'CyberArk',
  'D.A.S. Integrators',
  'Darktrace',
  'Data Domain (EMC)',
  'Data Foundry',
  'Data Intensity',
  'Databricks',
  'Datadog',
  'Deepwatch',
  'Delinea',
  'Dell',
  'Dell Boomi',
  'Delphix',
  'Demandbase',
  'Denali Advanced Integration',
  'Devo',
  'Docebo',
  'Docker',
  'DocuSign',
  'Domino',
  'Domo',
  'Dovetail',
  'Dozuki',
  'Dragos',
  'Druva',
  'Dtex',
  'Dynatrace',
  'E2open',
  'EMC Corporation',
  'Edcast',
  'Edgerock Technology & Services',
  'Ekahau',
  'ElastiFlow',
  'Elastic',
  'Elastic Path Software',
  'Epic',
  'Equinix',
  'Espressive',
  'Esri',
  'Everbridge',
  'Evolv Technology',
  'Experian',
  'ExtraHop',
  'F5',
  'FIS Global',
  'FM Systems',
  'Feitian Technologies US',
  'Fifty-Five',
  'Figma',
  'FireHydrant',
  'Fishtown Analytics',
  'Fivetran',
  'Flashpoint',
  'Flexera',
  'Flexera Software',
  'FloQast',
  'Forcepoint',
  'Forescout',
  'ForgeRock',
  'Forrester Research',
  'Fortinet',
  'Fortra',
  'Freshworks',
  'Fuji Film',
  'Gainsight Inc.',
  'Gartner',
  'Gathi Analytics',
  'Genesys',
  'Gigamon',
  'GitHub',
  'GitLab',
  'Glint, Inc',
  'Global Relay',
  'Globant',
  'GoTo',
  'Google',
  'Grafana Labs',
  'Greenlight Guru',
  'Gurucul',
  'HCL Technologies',
  'HR Acuity',
  'HVR Software',
  'HackerOne',
  'HackerRank',
  'Harness',
  'Hashicorp',
  'Hearsay Systems',
  'HelpSystems',
  'Hewlett Packard (HP)',
  'Hewlett Packard Enterprise (HPE)',
  'Higher Logic',
  'Highspot',
  'HireVue',
  'Hitachi',
  'Hubstar',
  'Hyde Group',
  'Hyland Software',
  'IBM Corporation',
  'IGEL',
  'ITRS',
  'Icertis',
  'Idera',
  'Illumio',
  'Immuta',
  'Impact Advisors',
  'Impartner',
  'Imperva',
  'Imprivata',
  'Incorta',
  'Infoblox',
  'Infor',
  'Informatica',
  'Infosys USA',
  'Infragistics',
  'Insight',
  'Inspur',
  'Insurity',
  'Interactions',
  'Isilon',
  'Ivalua',
  'Ivanti',
  'JAMF Software',
  'JetBrains',
  'Juniper Networks',
  'KFORCE',
  'Kainos Group PLC',
  'Kasada',
  'Kinaxis',
  'Kio Networks',
  'Kiteworks (formerly Accellion)',
  'KnowBe4',
  'Kochava',
  'Koerber',
  'Kofax',
  'Kong',
  'Kronos',
  'Kyndryl',
  'LTI (Larson and Toubro Infotech)',
  'Lakeside Software',
  'Language I/O',
  'LeanData',
  'Lenovo',
  'Levi Ray & Shoup',
  'LexisNexis',
  'Lieberman Software',
  'LinkedIn',
  'Llamasoft',
  'LogicGate',
  'Looker',
  'Lucid Chart',
  'Lucid Software',
  'Lumen',
  'MacStadium',
  'Magnet Forensics',
  'MajorKey Technologies',
  'Malwarebytes',
  'Mandiant',
  'Manhattan Associates',
  'Marketo',
  'MathWorks',
  'Maverick Solutions',
  'Medallia',
  'Metric Insights',
  'Micro Focus International',
  'MicroStrategy',
  'Microland Limited',
  'Microsoft Corporation',
  'Mindtouch',
  'Miro',
  'MongoDB',
  'MuleSoft',
  'Mural',
  'NGINX',
  'NICE Systems',
  'NS1',
  'NVIDIA',
  'Nagarro',
  'NetApp',
  'NetScout',
  'NetScout Systems',
  'Netcraft Ltd.',
  'Netskope',
  'Neustar',
  'New Relic',
  'Nexthink',
  'Nintex',
  'Nisos Holdings',
  'Noname',
  'Nutanix',
  'O9',
  'OB10 (Tungsten)',
  'ON24',
  'ObservePoint',
  'Okta',
  'Oliver Wyman',
  'OneReach',
  'OneStream',
  'OneTrust',
  'Ontra',
  'OpenText',
  'Optimizely',
  'Oracle',
  'Oracle Netsuite',
  'Orgvue',
  'Orion Global Solutions',
  'Outreach',
  'OwnBackup',
  'PTC',
  'PagerDuty',
  'Palantir Technologies',
  'Palo Alto Networks, Inc.',
  'Palo Alto Software',
  'Panasonic',
  'Pegasystems',
  'Pendo',
  'Philips',
  'Pindrop',
  'Ping Identity',
  'Planview',
  'Pluralsight',
  'Posit',
  'Pro Unlimited',
  'Program Planning Professionals (PCU3ED)',
  'Projekt202',
  'Proofpoint',
  'Protegrity',
  'Puppet',
  'Pure Storage',
  'PwC',
  'Qlik',
  'Qualtrics',
  'Qualys',
  'Quest Software',
  'Rackspace Technology',
  'Rainfocus',
  'Rapid7',
  'RapidRatings',
  'Recorded Future',
  'Red Hat',
  'Redis Labs',
  'Reliaquest',
  'Reltio',
  'Reprise',
  'Resolver',
  'Reuters',
  'Riverbed',
  'Rocket Software',
  'Rubrik',
  'Ruckus Wireless',
  'S3 Matching Technologies',
  'SAP',
  'SAP Ariba',
  'SAP Concur',
  'SAS',
  'SOFTWARE AG',
  'SUSE',
  'SailPoint Technologies',
  'Sakon',
  'Salesforce',
  'Sapience Analytics Corp',
  'SecurityScorecard',
  'SeekOut',
  'Segment.io',
  'Seismic Software',
  'SentinelOne',
  'Serraview',
  'ServiceNow',
  'Shape Security (part of F5)',
  'Showpad',
  'SideFx',
  'Siemens',
  'SimilarWeb',
  'SkillSoft',
  'Skilljar',
  'Slack',
  'Slalom',
  'Smartsheet',
  'Snowflake',
  'Snyk',
  'SoftwareONE AG',
  'Solidworks',
  'Sonarsource',
  'Sonatype',
  'Spinnaker Support',
  'Splunk',
  'Sprinklr',
  'Stack Overflow',
  'StarCompliance',
  'Stardog',
  'Stibo Systems',
  'Stripe',
  'SumTotal Systems, Inc.',
  'SuperMicro',
  'Sybase (SAP)',
  'Symantec (Broadcom)',
  'Syndigo',
  'Synrcon',
  'TENABLE',
  'Tableau',
  'Talend',
  'Tamr',
  'Tanium',
  'Taos',
  'Tata Consultancy Services',
  'Tealium',
  'Teleport',
  'Teradata',
  'Thales',
  'Thales Cloud Protection & Licensing Solutions',
  'Thoughtspot',
  'ThousandEyes',
  'ThreatConnect',
  'Tibco Software',
  'Totango',
  'Transmit Security, Inc.',
  'TrapX Security',
  'Trend Micro',
  'Tricentis',
  'TruTeam Staffing Advisors',
  'TrustArc',
  'Twilio',
  'Tyler Technologies',
  'UIPath',
  'UKG',
  'Uberflip',
  'Udemy',
  'Unison Software, Inc.',
  'Unknown Staff Aug Firm',
  'Upland Software',
  'UserTesting',
  'VMware (Broadcom)',
  'Varicent',
  'Varonis',
  'Venafi',
  'Veracode',
  'Veritas',
  'Verizon',
  'Verizon Wireless',
  'Versa Networks',
  'Vertex',
  'Videoken, Inc',
  'Virtusa',
  'Vocera',
  'WAN Dynamics',
  'WalkMe',
  'Wellsky',
  'Whatfix',
  'Whitehat Security',
  'Wipro',
  'Wiz',
  'Wolters Kluwer',
  'WorkHuman',
  'Workato',
  'Workday',
  'Workfront',
  'Workiva',
  'XTM',
  'Xactly Corporation',
  'Xactware',
  'Xceptor',
  'Xerox Corporation',
  'Zebra Technologies',
  'Zendesk, Inc.',
  'ZeroFox',
  'Zerto',
  'Zoom Video Communications',
  'Zoominfo',
  'Zscaler',
  'Zuora',
  'etiVenture',
  'iCIMS',
  'inTouch Health',
  'mPulse Mobile',
  'monday.com'
];

export enum GanttChartMode {
  OWN_CALENDER = 'ownCalender',
  PORTFOLIO = 'portfolio'
}

export enum FilterRange {
  'threeMonths' = '3',
  'sixMonths' = '6',
  'twelveMonths' = '12',
  'year' = 'year',
  'reset' = 'reset'
}

export enum SupplierTabType {
  BRIEFING = 'briefing',
  REPORT = 'report'
}

export enum DealFilterType {
  All,
  ErrorsAndWarnings,
  NONE
}
