export interface ExcelCreateRequest {
  data: any[];
  columns: Column[];
  options: Options;
}

export interface Column {
  header: string;
  key: string;
  width: number;
  type: FieldType;
  alignment?: Alignment;
  numFmt?: NumberFormat | string;
  font?: Font;
  fill?: Fill;
  headerRow?: HeaderRow;
}

export interface Fill {
  type: string;
  pattern: string;
  bgColor: Color;
  fgColor: Color;
}

export enum FieldType {
  Date = 'date',
  String = 'string',
  Number = 'number'
}

export enum NumberFormat {
  percent = '0.0%',
  currency = '"$"#,##0.00;[Red]-"$"#,##0.00',
  integer = '0',
  float = '0.0'
}

export interface Alignment {
  vertical: Vertical;
  horizontal: Horizontal;
  wrapText: boolean;
}

export enum Vertical {
  top = 'top',
  middle = 'middle',
  bottom = 'bottom'
}

export enum Horizontal {
  left = 'left',
  center = 'center',
  right = 'right'
}

export interface Color {
  argb: string;
}

export interface Font {
  color: Color;
  name: string;
  size: number;
  italic?: boolean;
  bold?: boolean;
}

export interface HeaderRow {
  fill: Fill;
  font: Font;
}

export interface Options {
  fileName: string;
  workSheetName: string;
}
