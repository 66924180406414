import { forwardRef, useMemo, useState } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { SearchOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

import { useSalesforceAccounts } from 'sb/api/salesforce';

export interface SalesforceAccount {
  Id: string;
  Name: string;
  Is_Vendor__c: boolean;
  Type: string;
}

interface Props extends SelectProps {
  width?: string | number;
  vendorOnly?: boolean;

  getValue?(account: SalesforceAccount): string;
}

export const SalesforceAccountSearch = forwardRef<RefSelectProps, Props>(function SalesforceAccountSearch(
  { width = 480, vendorOnly = false, getValue = (account) => account.Id, ...rest },
  ref
) {
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, data: accounts } = useSalesforceAccounts(searchTerm, { enabled: !!searchTerm });
  const filteredAccounts = useMemo(
    () => accounts?.filter((account) => !vendorOnly || account.Is_Vendor__c) || [],
    [accounts, vendorOnly]
  );

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setSearchTerm(value);
  }, 300);

  const handleClear = () => {
    setSearchTerm('');
  };

  return (
    <Select
      ref={ref}
      placeholder="Search Salesforce for Clients & Suppliers"
      size="large"
      style={{ width }}
      showSearch
      allowClear
      showArrow={false}
      labelInValue
      virtual
      filterOption={false}
      notFoundContent={isLoading && searchTerm ? <Spin size="default" /> : null}
      suffixIcon={<SearchOutlined />}
      {...rest}
      onSearch={debouncedSearch}
      onClear={handleClear}
    >
      {filteredAccounts?.map((account: SalesforceAccount) => (
        <Select.Option key={account.Id} value={getValue(account)}>
          {account.Name}
        </Select.Option>
      ))}
    </Select>
  );
});
