import { useEffect } from 'react';
import { Form, Input, Modal, ModalProps, Switch } from 'antd';

interface Props extends ModalProps {
  onOk?: (values: any) => void;
}

export const ProductMergeModal: React.FC<Props> = ({ onOk, ...rest }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (rest.visible) {
      form?.resetFields();
    }
  }, [rest.visible, form]);

  const handleOk = () => {
    onOk?.(form.getFieldsValue());
  };

  return (
    <Modal {...rest} title="Merge Products" onOk={handleOk}>
      <Form
        form={form}
        colon={false}
        size="large"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        requiredMark={false}
        initialValues={{ is_confirmed: false }}
      >
        <Form.Item
          label="Product Number"
          name="product_code"
          rules={[{ required: true, message: 'Please input product number!' }]}
        >
          <Input placeholder="Product Number" />
        </Form.Item>
        <Form.Item
          label="Product Name"
          name="product_name"
          rules={[{ required: true, message: 'Please input product name!' }]}
        >
          <Input placeholder="Product Name" />
        </Form.Item>
        <Form.Item name="is_confirmed" valuePropName="checked" label="Confirmed">
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};
