import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { RenewalNotificationRequest } from 'components/renewals/RenewalAlertsModal';
import { FollowContract } from 'models/Notification';
import { AxiosResponse } from 'axios';

export interface BulkFollowContractRequest {
  contract_id: string;
  notification_type: string;
  frequency: string;
  day?: number;
}

/**
 * Update or delete note_reaction
 *
 * @param data
 */
export const createRenewalNotifications = async (data: RenewalNotificationRequest[]) => {
  try {
    const res = await axios.put(`/v2/renewal/notifications`, data);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetches follow-up data for a specified contract.
 *
 * This async function makes a GET request to retrieve follow-up information
 * for the given contract ID. If the request is successful and the status
 * is 'success', it returns the data from the response. If the request
 * fails or the status is not 'success', it throws an error with an
 * appropriate message.
 *
 * @param {number} contract_id - The unique identifier of the contract to retrieve follow-up data for.
 * @return {Promise<FollowContract>} Returns a promise that fulfills with the follow-up data if the request is successful.
 */
export const readContractFollow = async (contract_id: number): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`/v2/contract/${contract_id}/follow`);
    if (res.data?.status === 'success') {
      return res.data.data as FollowContract;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Asynchronously creates a follow contract.
 *
 * This function takes a contract ID and a body object containing notification type
 * and frequency, and optionally a day. It sends a POST request to follow the contract.
 *
 * @param {number} contract_id - The ID of the contract to follow.
 * @param {{ notification_type: string; frequency: string; day?: number }} body -
 *    The body object containing notification preferences.
 * @returns {Promise<any>} - A promise that resolves to the follow contract data, or
 *    rejects with an error.
 */
export const createFollowContract = async (
  contract_id: number,
  body: { notification_type: string; frequency: string; day?: number }
): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/v2/contract/${contract_id}/follow`, body);
    if (res.data?.status === 'success') {
      return res.data.data as FollowContract;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Deletes a follow contract based on the provided contract ID.
 *
 * This asynchronous function sends a DELETE request to the backend to remove a follow contract.
 * If the contract is successfully deleted, it returns the response data.
 *
 * @param {number} contract_id - The unique identifier of the follow contract to be deleted.
 * @return {Promise<any>} A promise that resolves to the response data if the deletion is successful,
 * or rejects with an error message if the deletion fails.
 * @throws Will throw an error if the request fails or if the contract deletion is unsuccessful.
 */
export const deleteFollowContract = async (contract_id: number): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.delete(`/v2/contract/${contract_id}/follow`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Creates bulk follow contracts.
 *
 * @param {BulkFollowContractRequest[]} body - The array of follow contract requests to be created.
 * @returns {Promise<any>} - A promise that resolves with the created follow contracts or rejects with an error.
 */
export const createBulkFollowContracts = async (body: BulkFollowContractRequest[]): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/v2/contracts/follow`, body);
    if (res.data?.status === 'success') {
      return res.data.data as FollowContract[];
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
