import { Deal } from 'models/Deal';
import { Col, Descriptions, Divider, Row, Space, Statistic, Typography } from 'antd';
import { round } from 'lodash-es';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import './DealModalStats.scss';

interface Props {
  deal?: Deal;
}

const DealModalStats: React.FC<Props> = ({ deal, ...rest }: Props) => {
  if (typeof deal?.sfDeal === 'undefined') return <></>;
  return (
    <Row {...rest} className="deal-modal-stats">
      <Col span={19}>
        <Space size="large">
          <Statistic
            title={'Initial Quote'}
            prefix={'$'}
            suffix={
              deal && deal.sfDeal?.Initial_Quote_Full_Contract_Term__c
                ? deal.sfDeal?.Initial_Quote_Full_Contract_Term__c > 999999
                  ? 'M'
                  : undefined
                : undefined
            }
            // valueStyle={{ textAlign: 'right' }}
            className="font-bold tracking-tight leading-tight"
            value={
              deal && deal.sfDeal?.Initial_Quote_Full_Contract_Term__c
                ? deal.sfDeal?.Initial_Quote_Full_Contract_Term__c > 999999
                  ? round(deal.sfDeal?.Initial_Quote_Full_Contract_Term__c / 1000000, 2)
                  : deal.sfDeal?.Initial_Quote_Full_Contract_Term__c
                : 0
            }
          />

          <Divider type="vertical" />

          <Statistic
            title={'NPI Savings Target'}
            prefix={'$'}
            suffix={
              deal ? (deal.sfDeal?.NPI_Savings_Target_Full_Contract_Term__c > 999999 ? 'M' : undefined) : undefined
            }
            // valueStyle={{ textAlign: 'right' }}
            className="font-bold tracking-tight leading-tight"
            value={
              deal
                ? deal.sfDeal?.NPI_Savings_Target_Full_Contract_Term__c > 999999
                  ? round(deal.sfDeal?.NPI_Savings_Target_Full_Contract_Term__c / 1000000, 2)
                  : deal.sfDeal?.NPI_Savings_Target_Full_Contract_Term__c
                : 0
            }
          />

          <Divider type="vertical" />

          <Statistic
            title={'NPI Target Savings %'}
            suffix={'%'}
            // valueStyle={{ textAlign: 'center' }}
            className="font-bold tracking-tight leading-tight"
            value={
              deal && deal.sfDeal && deal.sfDeal?.Initial_Quote_Full_Contract_Term__c
                ? round(
                    (1 -
                      (deal.sfDeal?.Initial_Quote_Full_Contract_Term__c -
                        deal.sfDeal?.NPI_Savings_Target_Full_Contract_Term__c) /
                        deal.sfDeal?.Initial_Quote_Full_Contract_Term__c) *
                      100,
                    1
                  )
                : 0
            }
          />

          <Divider type="vertical" />

          <Statistic
            // prefix={deal?.sfDeal.FMV_Report_Link__c ? <DownloadOutlined className="color-primary" /> : undefined}
            title="FMV Report"
            className="font-bold tracking-tight leading-tight"
            value={deal?.sfDeal?.FMV_Report_Link__c || undefined}
            valueStyle={{ fontSize: 18, lineHeight: 2 }}
            formatter={(value: any) =>
              value ? (
                <Typography.Link
                  href={deal?.sfDeal && deal?.sfDeal.FMV_Report_Link__c ? deal?.sfDeal.FMV_Report_Link__c : ''}
                  target="_blank"
                >
                  <DownloadOutlined className="mr-1" /> Download
                </Typography.Link>
              ) : (
                '--'
              )
            }
          />
        </Space>
      </Col>
      <Col span={5} className="flex flex-col flex-grow place-content-center text-[0.75rem] leading-7 text-right">
        <Descriptions
          size="small"
          colon={false}
          column={1}
          // labelStyle={{ fontSize: 12, lineHeight: 1.9 }}
          contentStyle={{ textAlign: 'right', display: 'inline-block' }}
        >
          <Descriptions.Item label="Date Received by NPI">
            {deal?.sfDeal?.CreatedDate ? moment(deal?.sfDeal.CreatedDate).format('ll') : '--'}
          </Descriptions.Item>
          <Descriptions.Item label="Date Delivered">
            {deal?.sfDeal?.Feedback_Date__c ? moment(deal?.sfDeal.Feedback_Date__c).format('ll') : '--'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default DealModalStats;
