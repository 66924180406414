import { ModalProps, Popover } from 'antd';
import React from 'react';
import { AvatarValue } from './AvatarValue';

interface Props extends ModalProps {
  name: string;
  value: string;
  avatarValue: string;
  avatarColor: string;
  description: string;
  width?: number;
}

const SupplierDynamicsPopover: React.FC<Props> = ({
  name,
  value,
  avatarValue,
  avatarColor,
  description,
  width
}: Props) => {
  const renderAvatar = () => {
    return (
      <AvatarValue
        shape={'square'}
        value={avatarValue}
        style={{ backgroundColor: avatarColor, width: `${width}px` }}
        className="flex-none"
      />
    );
  };

  return (
    <Popover
      trigger="click"
      overlayClassName="custom-popover"
      title={
        <>
          <span className="purchase-item-heading">
            {name}: {value}
          </span>
          <br />
          <hr className="border-dotted border-gray-300" />
          <div className="flex space-x-4">
            <span className={'mt-1'}>{renderAvatar()}</span>
            <span>{description}</span>
          </div>
        </>
      }
    >
      {renderAvatar()}
    </Popover>
  );
};
SupplierDynamicsPopover.defaultProps = {
  width: 32
};
export default SupplierDynamicsPopover;
