import { DeleteOutlined, EditOutlined, FileImageOutlined, FontSizeOutlined, UndoOutlined } from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import { IBannerAd } from 'types/SiteAdministration';
import { Button, Col, Input, Popconfirm, Row, Table, Typography } from 'antd';
import moment from 'moment';
import { FieldSorter } from 'shared/Helpers';
import { BannerAdStatus, audienceFilters, statusFilters } from 'models/BannerAd';

interface BannerAdTableProps {
  bannerAds: IBannerAd[];
  onAddNewBanner: () => void;
  onEditBanner: (bannerId: number) => void;
  onDeleteBanner: (bannerId: number) => void;
  onUnpublishBanner: (bannerId: number) => void;
}

export const UserMessageTable: React.FC<BannerAdTableProps> = ({
  bannerAds,
  onAddNewBanner,
  onEditBanner,
  onDeleteBanner,
  onUnpublishBanner
}) => {
  const [search, setSearch] = useState('');
  const onSearch = (val: string) => {
    setSearch(val);
  };

  const filteredBanners = useMemo(() => {
    if (!bannerAds) return [];
    if (!search) return bannerAds;
    return bannerAds.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  }, [search, bannerAds]);

  const isRecordInSelectedStatus = (record: IBannerAd, status: string | number | boolean) => {
    let isExists = false;
    if (status === BannerAdStatus.Scheduled) {
      isExists = !!moment(record.publish_date).isAfter(moment(), 'date');
    } else if (status === BannerAdStatus.Inactive) {
      isExists = !!record.expiration_date && moment(record.expiration_date).isBefore(moment(), 'date');
    } else if (status === BannerAdStatus.Active) {
      isExists =
        !(record.expiration_date && moment(record.expiration_date).isBefore(moment(), 'date')) &&
        !moment(record.publish_date).isAfter(moment(), 'date');
    }
    return isExists;
  };

  const columns = [
    {
      title: 'Banner Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <p>{text}</p>
    },
    {
      title: 'Type',
      dataIndex: 'banner_type',
      key: 'banner_type',
      render: (banner_type: string) => {
        return <>{banner_type === 'text' ? <FontSizeOutlined /> : <FileImageOutlined />}</>;
      }
    },
    {
      title: 'Banner Link',
      dataIndex: 'url',
      key: 'url',
      render: (url: string) => (
        <a target="_blank" href={url} rel="noreferrer">
          {url}
        </a>
      )
    },
    {
      title: 'Publish Date',
      dataIndex: 'publish_date',
      key: 'publish_date',
      render: (publish_date: string) => <p>{moment(publish_date).format('MM/DD/YY').toString()}</p>,
      sorter: (a: IBannerAd, b: IBannerAd) => FieldSorter(a.publish_date.toString(), b.publish_date.toString())
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      render: (expiration_date: string) => <>{expiration_date ? moment(expiration_date).format('MM/DD/YY') : 'N/A'}</>,
      sorter: (a: IBannerAd, b: IBannerAd) =>
        FieldSorter(
          a.expiration_date ? a.expiration_date?.toString() : '',
          b.expiration_date ? b.expiration_date.toString() : ''
        )
    },
    {
      title: 'Audience',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (permissions: string[] | null) => {
        const hasFullPermissions =
          permissions?.includes('read:portofino_full') ||
          (permissions?.includes('read:portofino') && permissions?.includes('read:portofino_gold'));
        const hasBasicPermissions = permissions?.includes('read:portofino');
        const hasGoldPermissions = permissions?.includes('read:portofino_gold');

        //   revisit this
        return (
          <>
            {hasFullPermissions
              ? 'All'
              : hasBasicPermissions
              ? 'Basic Only'
              : hasGoldPermissions
              ? 'Gold Only'
              : 'None'}
          </>
        );
      },
      filters: audienceFilters,
      onFilter: (value: string | number | boolean, record: IBannerAd) => record.permissions.includes(value as string)
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_status: string, x: IBannerAd) => {
        return (
          <>
            {moment(x.publish_date).isAfter(moment(), 'date')
              ? BannerAdStatus.Scheduled
              : x.expiration_date && moment(x.expiration_date).isBefore(moment(), 'date')
              ? BannerAdStatus.Inactive
              : BannerAdStatus.Active}
          </>
        );
      },
      filters: statusFilters,
      onFilter: (value: string | number | boolean, record: IBannerAd) => isRecordInSelectedStatus(record, value)
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <>
          <Button
            type="link"
            onClick={() => {
              onEditBanner(id);
            }}
          >
            <EditOutlined />
          </Button>

          <Popconfirm
            title="Are you sure to unpublish this banner?"
            onConfirm={() => {
              onUnpublishBanner(id);
            }}
          >
            <UndoOutlined className="text-orange-600 px-3" />
          </Popconfirm>

          <Popconfirm
            title="Are you sure to delete this banner?"
            onConfirm={() => {
              onDeleteBanner(id);
            }}
          >
            <DeleteOutlined className="text-red-600" />
          </Popconfirm>
        </>
      )
    }
  ];

  return (
    <>
      <Row className="mb-4">
        <Col flex="auto">
          <Typography.Title level={5} className="!font-normal !pt-3 !mb-0 opacity-60">
            Marketing messages
          </Typography.Title>
          <Typography.Title level={4} className="!font-light !m-0 opacity-80">
            Push messages for announcements, seminars, events and more
          </Typography.Title>
        </Col>
      </Row>
      <Row align="bottom" justify="space-between">
        <Col>
          <Input.Search
            placeholder="Search Banner Ads"
            onChange={(e) => {
              onSearch(e.target.value);
            }}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={onAddNewBanner}>
            Add New Banner
          </Button>
        </Col>
      </Row>
      <Table rowKey={'id'} columns={columns} dataSource={filteredBanners} className="my-5" />
    </>
  );
};
