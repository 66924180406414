import { Button, Cascader, Col, Form, FormProps, Input, Row, Space } from 'antd';
import { getMonthDaySelectOptions } from 'sb/shared/Helpers';

interface Props extends FormProps {
  loading?: boolean;
  onCancel?: () => void;
}

export const ResellerForm: React.FC<Props> = ({ loading, onCancel, ...rest }) => {
  return (
    <Form layout="vertical" size="middle" requiredMark={false} {...rest}>
      <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input vendor name' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="symbol" label="Stock Symbol">
        <Input />
      </Form.Item>
      <Form.Item name="fiscal_year_end" label="Fiscal Year End">
        <Cascader options={getMonthDaySelectOptions()} displayRender={(label) => label?.join(' ')} />
      </Form.Item>
      <Form.Item name="salesforce_id" label="Salesforce ID">
        <Input />
      </Form.Item>
      <Form.Item name="profile" label="Profile">
        <Input.TextArea rows={6} />
      </Form.Item>
      <Form.Item>
        <Row justify="end">
          <Col>
            <Space>
              <Button type="primary" htmlType="submit" loading={loading}>
                Save
              </Button>
              <Button type="default" onClick={onCancel}>
                Cancel
              </Button>
            </Space>
          </Col>
          <Col></Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
