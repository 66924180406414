import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, message, Row, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';
import { VendorForm } from 'components/atoms/VendorForm';
import { createVendor, getVendorDetails, updateVendor } from 'sb/api/vendor';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { VendorAdmin } from 'sb/models/prisma/VendorAdmin';
import { useRecoilValue } from 'recoil';
import { uiState } from 'recoil/atoms';
import { RefSelectProps } from 'antd/lib/select';
import { animateScroll } from 'react-scroll';
import { scrollOptions } from 'sb/shared/constants';

const thisMenuKey = 'supplier';

const Vendors: React.FC = () => {
  const vendorSearchRef = useRef<RefSelectProps>(null);
  const [form] = Form.useForm();
  const { aclCheck } = useScopes();

  // LOCAL STATE
  const [updating, setUpdating] = useState(false);
  const [addOrEditVendor, setAddOrEditVendor] = useState(false);
  const [vendor, setVendor] = useState<VendorAdmin>();
  const [selectedVendor, setSelectedVendor] = useState<number>();

  // RECOIL STATE
  const { menuKey } = useRecoilValue(uiState);

  useEffect(() => {
    if (menuKey === thisMenuKey) {
      vendorSearchRef.current?.focus();
      animateScroll.scrollToTop(scrollOptions);
    }
  }, [menuKey]);

  const handleVendorChange = async (_vendorId?: number) => {
    if (_vendorId) {
      const res = await getVendorDetails(_vendorId);
      if (res) {
        setVendor(res);
        setSelectedVendor(res?.id);
        form.setFieldsValue({ ...res, fiscal_year_end: vendor?.fiscal_year_end?.split('-') });
      }
    }
  };

  const handleSubmit = async (_values: any) => {
    const values = { ..._values, fiscal_year_end: _values.fiscal_year_end?.join('-') };
    setUpdating(true);
    if (selectedVendor) {
      // Update selected vendor
      const res = await updateVendor(selectedVendor, values);
      if (res) {
        setVendor(res);
        message.success('Vendor successfully updated.');
      }
    } else {
      // Create a new vendor
      const res = await createVendor(values);
      if (res) {
        setVendor(res);
        message.success('Vendor successfully added.');
      }
    }
    setAddOrEditVendor(false);
    setUpdating(false);
  };

  const handleAdd = () => {
    setSelectedVendor(undefined);
    form?.resetFields();
    setAddOrEditVendor(true);
  };

  const handleEdit = () => {
    setSelectedVendor(vendor?.id);
    form.setFieldsValue({ ...vendor, fiscal_year_end: vendor?.fiscal_year_end?.split('-') });
    setAddOrEditVendor(true);
  };

  const handleCancel = () => {
    setAddOrEditVendor(false);
    setSelectedVendor(vendor?.id);
    form.setFieldsValue({ ...vendor, fiscal_year_end: vendor?.fiscal_year_end?.split('-') });
  };

  return (
    <>
      <h1 className="mb-2">Suppliers</h1>
      <Card className="pt-4">
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>Search Suppliers</Typography.Title>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <VendorsSearch
              ref={vendorSearchRef}
              placeholder="Search by supplier Name or Salesforce ID"
              onChange={handleVendorChange}
            />
          </Col>
          <Col>
            {aclCheck(['create:suppliers_resellers']) && vendor && !addOrEditVendor && (
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                Add
              </Button>
            )}
          </Col>
        </Row>

        {/* <Divider className="my-4" /> */}
        <div className="relative mt-8">
          {vendor && !addOrEditVendor && (
            <Button onClick={handleEdit} type="primary" icon={<EditOutlined />} className="absolute top-0 right-0 z-10">
              Edit Info
            </Button>
          )}
          {vendor && (
            <VendorForm
              vendor={vendor}
              selectedVendor={selectedVendor}
              loading={updating}
              form={form}
              addOrEditVendor={addOrEditVendor}
              onFinish={handleSubmit}
              onCancel={() => handleCancel()}
            />
          )}
        </div>
      </Card>
    </>
  );
};

export default Vendors;
