import React, { useState, useEffect } from 'react';
import { Modal, ModalProps, Select, notification, Radio, RadioChangeEvent, Button, Tag, Row, Col } from 'antd';
import { SalesforceAccountSearch } from 'components/atoms/SalesforceAccountSearch';
import {
  useClientRelationTypes,
  useCreateClientParentRelation,
  useCreateClientChildRelation,
  useClientDetail
} from 'sb/api/client';
import { useQueryClient } from '@tanstack/react-query';
import { RELATIONSHIP_KIND } from 'sb/shared/constants';
import { PlusSquareOutlined } from '@ant-design/icons';
import { ChildCompany } from 'sb/models/Client';

interface Props extends ModalProps {
  onCancel: () => void;
  open: boolean;
  clientId: number;
  salesforceId: string;
}

interface SalesforceAccount {
  value: string;
  label: string;
  key: string;
}

export const CompanyRelationshipModal: React.FC<Props> = ({
  onCancel,
  open,
  clientId,
  salesforceId,
  ...rest
}: Props) => {
  const [salesforceAcc, setSalesforceAcc] = useState<SalesforceAccount>();
  const [relationType, setRelationType] = useState<string | undefined>(undefined);
  const [relationshipKind, setRelationshipKind] = useState<string>(RELATIONSHIP_KIND.PARENT);
  const { data: clientRelationTypes } = useClientRelationTypes();
  const [childCompanies, setChildCompanies] = useState<ChildCompany[]>([]);
  const queryClient = useQueryClient();

  const { data: client } = useClientDetail(salesforceAcc?.value || '', {
    enabled: !!salesforceAcc?.value
  });

  useEffect(() => {
    return () => {
      resetFields();
    };
  }, []);

  useEffect(() => {
    resetFields();
  }, [relationshipKind]);

  const { mutate: createClientParentRelation } = useCreateClientParentRelation({
    onSuccess: () => {
      queryClient.invalidateQueries(['clientDetail', salesforceId]);
      onCancel();
      notification.success({
        message: 'Parent company added successfully!',
        duration: 4
      });
    },
    onError: () => {
      notification.error({
        message: 'Something went wrong while adding parent company. Please try again.',
        duration: 4
      });
    }
  });

  const { mutate: createClientChildRelation } = useCreateClientChildRelation({
    onSuccess: () => {
      queryClient.invalidateQueries(['clientDetail', salesforceId]);
      onCancel();
      notification.success({
        message: 'Child company added successfully!',
        duration: 4
      });
    },
    onError: () => {
      notification.error({
        message: 'Something went wrong while adding child company. Please try again.',
        duration: 4
      });
    }
  });

  const handleClientChange = async (_salesforceAcc: SalesforceAccount) => {
    setSalesforceAcc(_salesforceAcc);
  };

  const resetFields = () => {
    setRelationType('');
    setChildCompanies([]);
  };

  const onOkayHandler = () => {
    if (client) {
      if (relationshipKind === RELATIONSHIP_KIND.PARENT) {
        const payload = {
          parent_client_id: client.id,
          relation_type_id: Number(relationType)
        };
        createClientParentRelation({ ...payload, client_id: clientId });
      } else if (relationshipKind === RELATIONSHIP_KIND.CHILD) {
        const payload = childCompanies.map((c) => {
          return {
            client_id: c.clientId,
            relation_type_id: c.relationshipTypeId
          };
        });
        createClientChildRelation({ payload, parent_client_id: clientId });
      }
    }
  };

  const onRelationshipKindChange = (e: RadioChangeEvent) => {
    setRelationshipKind(e.target.value);
  };

  const handleAddChildRelation = () => {
    if (client) {
      const isExists = childCompanies.find(
        (c) => c.clientId === client?.id && c.relationshipTypeId === Number(relationType)
      );
      if (isExists) {
        return;
      } else {
        const childCompany = {
          clientId: client?.id,
          relationshipTypeName: clientRelationTypes?.find((c) => c.id === Number(relationType))?.name || '',
          relationshipTypeId: relationType,
          name: client.encrypted_name || ''
        };
        const newArray = JSON.parse(JSON.stringify(childCompanies));
        newArray.push(childCompany);
        setChildCompanies(newArray);
      }
      setRelationType('');
    }
  };

  const onClose = (childCompany: ChildCompany) => {
    const copyArray = JSON.parse(JSON.stringify(childCompanies));
    const filteredArray = copyArray.filter(
      (c: ChildCompany) =>
        c.clientId !== childCompany.clientId || c.relationshipTypeId !== childCompany.relationshipTypeId
    );
    setChildCompanies(filteredArray);
  };

  const renderPills = (childCompany: ChildCompany) => {
    return (
      <Tag
        key={childCompany.id}
        closable={true}
        onClose={() => onClose(childCompany)}
        className="bg-gray-200 pl-3 py-1 pr-2 text-gray-700 rounded-full tag-icon"
      >
        <span className="font-semibold">{childCompany.name}</span> - {childCompany.relationshipTypeName}
      </Tag>
    );
  };

  const disabledSaveButton =
    relationshipKind === RELATIONSHIP_KIND.PARENT ? !relationType : childCompanies.length === 0;

  return (
    <Modal
      title="Add Company Relationship"
      width={530}
      open={open}
      onCancel={onCancel}
      {...rest}
      cancelText="Close"
      onOk={onOkayHandler}
      okText={'Save Changes'}
      okButtonProps={{ disabled: disabledSaveButton }}
    >
      <div>
        <h4 className="mb-3 font-semibold">Select relationship kind</h4>

        <Radio.Group
          onChange={onRelationshipKindChange}
          name="radiogroup"
          defaultValue={RELATIONSHIP_KIND.PARENT}
          className="mb-6"
        >
          <Radio value={RELATIONSHIP_KIND.PARENT}>Parent Company</Radio>
          <Radio value={RELATIONSHIP_KIND.CHILD}>Child Company</Radio>
        </Radio.Group>

        <Row className="gap-3 mb-4">
          <Col flex="auto">
            <SalesforceAccountSearch
              placeholder="Search by supplier Name"
              onChange={handleClientChange}
              width={'100%'}
            />
          </Col>
          <Col flex="160px" className="truncate">
            <Select
              size="large"
              className="select-after w-full"
              onChange={(value) => setRelationType(value)}
              placeholder="Select Relationship Type"
              value={relationType || undefined}
            >
              {clientRelationTypes?.map((type) => (
                <Select.Option key={type.id} id={type.id} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          {relationshipKind === RELATIONSHIP_KIND.CHILD && (
            <Col flex="40px">
              <Button
                type="primary"
                size="large"
                className="!rounded-md"
                disabled={!relationType || !client}
                icon={<PlusSquareOutlined />}
                onClick={() => handleAddChildRelation()}
              ></Button>
            </Col>
          )}
        </Row>
        {relationshipKind === RELATIONSHIP_KIND.CHILD && childCompanies.length > 0 && (
          <div className="flex gap-y-3 flex-wrap">{childCompanies.map((c) => renderPills(c))}</div>
        )}
      </div>
    </Modal>
  );
};
