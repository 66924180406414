import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { Vendor } from 'sb/models/vendor';
import { PriceList } from 'sb/models/PriceList';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export async function searchVendors(reseller: boolean, str: string, limitToVendorDynamics?: boolean) {
  try {
    const res = await axios.get(
      `/${VITE_APP_UNSECURE_ROUTE}/${!reseller ? 'vendors' : 'resellers'}/search?str=${str}${
        limitToVendorDynamics !== undefined ? `&vdi=${+limitToVendorDynamics}` : ''
      }`
    );
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select supplier info
 *
 * @param id
 * @return
 */
export async function getVendor(id: number) {
  try {
    const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/vendor/${id}`);
    return res.data;
  } catch (e) {
    return e;
  }
}

/**
 * Select vendor details
 *
 * @param id
 * @return
 */
export async function getVendorDetails(id: number) {
  try {
    const res = await axios.get(`/secure/vendors/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    return e;
  }
}

export async function getVendors(str?: string) {
  try {
    const res = await axios.get(str ? `/secure/vendors?str=${str}` : `/secure/vendors`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createVendor(values: Omit<Vendor, 'id'>) {
  try {
    const res = await axios.post(`/secure/vendor`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendor(id: number, values: Partial<Vendor>) {
  try {
    const res = await axios.patch(`/secure/vendor/${id}`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorCategories(id: number) {
  try {
    const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/vendor/${id}/categories`);
    return res.data;
  } catch (e) {
    return e;
  }
}

export async function getVendorContracts(id: string) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/contracts`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorProducts(id: number, str: string, unverified?: boolean) {
  try {
    const res = await axios.get(
      `/secure/vendor/${id}/products/search?str=${str}${unverified ? '&is_confirmed=0' : ''}`
    );
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function mergeVendorProducts(id: number, data: any) {
  try {
    const res = await axios.post(`/secure/vendor/${id}/products/merge`, data);
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendorProduct(id: number, productId: number, data: any) {
  try {
    const res = await axios.patch(`/secure/vendor/${id}/product/${productId}`, data);
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message);
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendorProductConfirmed(id: number, productId: number, isConfirmed: boolean) {
  try {
    const res = await axios.put(`/secure/vendor/${id}/product/${productId}/is_confirmed`, {
      is_confirmed: isConfirmed
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendorProductCategories(id: number, productId: number, categories: any[]) {
  try {
    const res = await axios.put(`/secure/vendor/${id}/product/${productId}/categories`, { categories });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function batchUpdateVendorProductCategories(
  id: number,
  productIds: (number | string)[],
  categories: any[]
) {
  try {
    const res = await axios.put(`/secure/vendor/${id}/products/categories`, {
      categories,
      product_ids: productIds
    });

    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorDealDynamics(id: number, categoryId?: number) {
  try {
    const res = await axios.get(
      `${VITE_APP_UNSECURE_ROUTE}/vendor/${id}/dealDynamics${categoryId ? `?category_id=${categoryId}` : ''}`
    );
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createVendorDealDynamics(id: number, supplierDynamics: any, categoryId: any, profile: any) {
  try {
    const res = await axios.post(`/secure/vendor/${id}/dealDynamics`, {
      supplierDynamics,
      category_id: categoryId,
      profile
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorPriceLists(id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${id}/price_lists`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorPriceList(id: number, productListId: number, str: string, purchaseLevelId?: number) {
  let url = `/secure/vendor/${id}/price_list/${productListId}?str=${str}`;

  if (purchaseLevelId !== undefined) {
    url += `&purchase_level_id=${purchaseLevelId}`;
  }

  try {
    const res = await axios.get(url);
    if (res.data?.status === 'error') {
      throw new Error(res.data?.message || 'Error');
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateVendorPriceList(id: number, values: Partial<PriceList>) {
  try {
    const res = await axios.put(`/secure/vendor/price_lists/${id}`, values);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteVendorPriceList(id: number) {
  try {
    const res = await axios.delete(`/secure/vendor/price_lists/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getVendorProductsByName(id: number, products: any[]) {
  try {
    const res = await axios.post(`/secure/vendor/${id}/products_by_names`, { products });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Search for products of a given vendor
 *
 * @param vendor_id
 * @param str
 */
export const searchVendorProducts = async (vendor_id: number, str: string) => {
  if (str.length > 1) {
    try {
      const res = await axios.get(`/secure/vendor/${vendor_id}/search?str=${str}`);
      return res.data;
    } catch (e) {
      AxiosErrorHandler(e);
    }
  }
};

/**
 * Retrieve a vendor's ESG scores
 *
 * @param vendor_id
 */
export async function fetchVendorESGScores(vendor_id: number) {
  try {
    const res = await axios.get(`/secure/vendor/${vendor_id}/esg`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Get Vendor or Client from salesforce id
 *
 * @param { string } sf_account_id
 * @returns
 */
export const getVendorBySalesforceId = async (sf_account_id: string) => {
  try {
    const res = await axios.get(`/secure/vendor/sf_account/${sf_account_id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update Vendor Category
 *
 * @param supplier_id
 * @param supplier_category_id
 * @param values
 */
export async function updateSupplierCategory(
  supplier_id: number,
  supplier_category_id: number,
  values: { profile?: string; category?: string }
) {
  try {
    const res = await axios.patch(`/secure/supplier/${supplier_id}/category/${supplier_category_id}`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Create Vendor Category
 *
 * @param supplier_id
 * @param values
 */
export async function createSupplierCategory(supplier_id: number, values: { profile?: string; category?: string }) {
  try {
    const res = await axios.post(`/secure/supplier/${supplier_id}/category`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update Vendor Logo filename field
 *
 * @param vendor_id
 * @param logo_filename
 */
export const updateSupplierLogoFilename = async (vendor_id: number, logo_filename: string) => {
  try {
    const res = await axios.patch(`/secure/supplier/${vendor_id}/logoFilename`, { logo_filename });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update competitor
 *
 * @param id
 * @param values
 */
export async function updateCompetitor(
  id: number,
  values: { competitor_id?: number; competitor: string; supplier_category_id?: number }
) {
  try {
    const res = await axios.patch(`/secure/competitor/${id}`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update competitor
 *
 * @param id
 */
export async function deleteCompetitor(id: number) {
  try {
    const res = await axios.delete(`/secure/competitor/${id}`);
    if (res.data?.status === 'success') {
      return true;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Create competitor
 *
 * @param id
 * @param values
 */
export async function addCompetitor(
  id: number,
  values: { competitor_id?: number; competitor: string; supplier_category_id?: number }
) {
  try {
    const res = await axios.post(`/secure/supplier/${id}/competitor`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
