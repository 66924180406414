import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';

const { VITE_APP_UNSECURE_ROUTE } = import.meta.env;

export async function getMe() {
  try {
    const res = await axios.get(`/secure/me`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateMe(data: any) {
  try {
    const res = await axios.put(`/secure/me`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getSupplierDynamicsStats() {
  try {
    const res = await axios.get(`${VITE_APP_UNSECURE_ROUTE}/supplierDynamics/stats`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const sendVerificationEmail = async () => {
  try {
    const res = await axios.post(`/secure/send-verification`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get File from API
 *
 * @param filename
 */
export const getFile = async (filename: string) => {
  const { data } = await axios.get(`/secure/file/${encodeURIComponent(filename)}`, {
    responseType: 'blob'
  });
  return data;
};

export async function getStatusLov() {
  try {
    const res = await axios.get(`/secure/status/lov`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
