import { Avatar, AvatarProps } from 'antd';

interface Props extends AvatarProps {
  value: number | string;
  backgroundColor?: string;
}

export const AvatarValue: React.FC<Props> = ({ value, backgroundColor, ...rest }: Props) => {
  return (
    <Avatar
      style={{ backgroundColor: backgroundColor, verticalAlign: 'middle', fontSize: 16, borderRadius: 6 }}
      {...rest}
    >
      {value}
    </Avatar>
  );
};
