import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { InquiryForm, InquiryStatusForm } from 'sb/models/Inquiry';

/**
 * Fetch inquiries
 *
 */
export async function selectInquiries() {
  try {
    const res = await axios.get(`/secure/admin/inquiries`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch assigned inquiries
 *
 */
export async function selectAssignedInquiries() {
  try {
    const res = await axios.get(`/secure/admin/inquiries/assigned`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Select Inquiry
 *
 */
export async function selectInquiry(id: number) {
  try {
    const res = await axios.get(`/secure/admin/inquiry/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Inssert Inquiry Status record
 *
 * @param id
 * @param data
 */
export const insertInquiryStatus = async (id: number, data: InquiryStatusForm) => {
  try {
    const res = await axios.post(`/secure/admin/inquiry/${id}/status`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Insert Inquiry Reply
 *
 * @param id
 * @param data
 */
export const insertInquiryReply = async (id: number, data: { note: string; vendor_id?: number }) => {
  try {
    const res = await axios.post(`/secure/admin/inquiry/${id}/reply`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Insert Inquiry
 *
 * @param data
 */
export const insertInquiry = async (data: InquiryForm) => {
  try {
    const res = await axios.post(`/secure/admin/inquiry`, data);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
