import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Card, Collapse, Divider, Empty, message, Skeleton, Typography } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import { Role, SelectItem } from 'types/global';
import { clientContractState, loadingUsersState, selectedClientState, usersState } from 'recoil/atoms';
import { SfAccountsSearch } from 'components/salesforce/SfAccountsSearch';
import './AccountAdmin.scss';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { getSalesforceAccount } from '../../api/salesforce';
import {
  createAuth0Organization,
  fetchDefaultRoles,
  fetchSavedDefaultRoles,
  getSelectedClientUsers,
  selectClientEmailDomains,
  selectUserRoles
} from '../../api/admin';
import { AccountInfoDetails } from '../account-settings/AccountInfoDetails';
import { UserManagement } from 'components/user-management/UserManagement';
import { AccountData } from '../../models/AccountData';
import { ACLCheck } from '../../shared/Helpers';
import { DefaultRole, DefaultRolesRes } from 'sb/models/Role';

const { Panel } = Collapse;

const AccountAdmin: FC = () => {
  const searchRef = useRef<RefSelectProps>(null);
  const setSelectedClient = useSetRecoilState(selectedClientState);
  const selectedClient = useRecoilValue(selectedClientState);
  const [contract, setContract] = useRecoilState(clientContractState);
  const [accountInfo, setAccountInfo] = useState<AccountData>();
  const [emailDomains, setEmailDomains] = useState<any[]>([]);
  const [activeUsers, setActiveUsers] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const setLoadingUsers = useSetRecoilState(loadingUsersState);
  const setUsers = useSetRecoilState(usersState);
  const [accountPanelCollapseKeys, setAccountPanelCollapseKeys] = useState<string[]>(['account-info']);
  const [availableRoles, setAvailableRoles] = useState<DefaultRole[]>([]);
  const [savedRoles, setSavedRoles] = useState<DefaultRole[]>([]);
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<Role[]>([]);

  useEffect(() => {
    (async () => {
      if (selectedClient && selectedClient?.key) {
        setLoading(true);
        setSavedRoles([]);
        const sfAccountInfo = await getSalesforceAccount(selectedClient?.key?.toString());
        if (sfAccountInfo.CurrentContract && sfAccountInfo && !sfAccountInfo.auth0_organization_id) {
          message.warning('Account is not provisioned yet.');
          setUsers([]);
        } else if (!sfAccountInfo.client_id) {
          message.error('ClientID is not available for the selected client account');
          setUsers([]);
        }
        setAccountInfo(sfAccountInfo);
        setContract(sfAccountInfo?.CurrentContract);

        const emailDomains = await selectClientEmailDomains(selectedClient?.key?.toString());
        setEmailDomains(emailDomains);
        setLoading(false);
      }
    })();
  }, [selectedClient, setContract, setUsers]);

  const fetchSelectedClientUsers = useCallback(async () => {
    if (accountInfo?.client_id) {
      setLoadingUsers(true);
      const res = await getSelectedClientUsers(accountInfo?.client_id);
      if (Array.isArray(res?.data)) setUsers(res?.data);
      if (Array.isArray(res?.data)) setActiveUsers(res?.data?.filter((user: any) => !user.blocked).length);
      setLoadingUsers(false);
    } else {
      setActiveUsers(0);
    }
  }, [accountInfo?.client_id, setLoadingUsers, setUsers]);

  const fetchSavedAndDefaultRoles = async () => {
    try {
      setLoadingRoles(true);
      const defaultRoles: DefaultRolesRes | undefined = await fetchDefaultRoles(accountInfo?.client_id);
      const savedRoles: DefaultRolesRes | undefined = await fetchSavedDefaultRoles(accountInfo?.client_id);
      defaultRoles?.data && setAvailableRoles(defaultRoles?.data);
      savedRoles?.data && setSavedRoles(savedRoles.data);
      const fetchedUserRoles = await selectUserRoles(selectedClient?.key?.toString());
      setUserRoles(fetchedUserRoles || []);
    } catch (e) {
      message.error('Problem retrieving default roles');
    } finally {
      setLoadingRoles(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchSelectedClientUsers();
      await fetchSavedAndDefaultRoles();
    })();
  }, [accountInfo?.client_id, fetchSelectedClientUsers]);

  const handleSfAccountSearchChange = async (val: SelectItem | undefined) => {
    if (val) {
      setSelectedClient(val);
    } else {
      setSelectedClient(undefined);
    }
  };

  useEffect(() => {
    // Set focus in SfAccountsSearch component
    searchRef.current?.focus();
  }, []);

  const onUsersLinkClick = () => {
    if (!accountPanelCollapseKeys.includes('user-mgmt')) {
      setAccountPanelCollapseKeys([...accountPanelCollapseKeys, 'user-mgmt']);
    }
  };

  const handleCollapseSectionClick = (key: string[]) => {
    setAccountPanelCollapseKeys(key);
  };

  const handleProvisionAccountClick = async () => {
    if (!accountInfo?.Id) return;

    const results = await createAuth0Organization(accountInfo?.Id + '', accountInfo?.Name);
    /**
     * If auth0 account is returned, add auth0_organization_id to account
     * and set account to recoil state
     */
    if (results) {
      setAccountInfo((prev) => (prev ? { ...prev, auth0_organization_id: results.id } : undefined));
      message.success('Client account provisioned!');
    }
  };

  return (
    <div className="account-admin ml-4">
      <Typography.Title level={4}>Account Management</Typography.Title>
      <Divider />
      <Card>
        <Typography.Title level={4}>Search Account</Typography.Title>
        <SfAccountsSearch
          ref={searchRef}
          className="w-[380px]"
          onChange={handleSfAccountSearchChange}
          placeholder="Search by Client Name"
        />
        {!selectedClient && <Empty description="Please search for any account to view the details" />}
        {selectedClient && (
          <>
            <Divider />
            <Collapse
              bordered={false}
              defaultActiveKey={['account-info']}
              activeKey={accountPanelCollapseKeys}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <MinusSquareOutlined className="text-blue-500" />
                ) : (
                  <PlusSquareOutlined className="text-blue-500" />
                )
              }
              onChange={(key) => {
                handleCollapseSectionClick(key as string[]);
              }}
            >
              <Panel header={selectedClient?.label} key="account-info">
                <Skeleton loading={loading} active>
                  <AccountInfoDetails
                    accountInfo={accountInfo}
                    emailDomains={emailDomains}
                    setEmailDomains={setEmailDomains}
                    activeUsers={activeUsers}
                    siteAdmin={true}
                    onUsersLinkClick={onUsersLinkClick}
                    hasContract={!!contract}
                    updateEnabled={ACLCheck(['update:all_companies', 'create:all_companies'])}
                    onProvisionAccount={handleProvisionAccountClick}
                    savedRoles={savedRoles}
                    availableRoles={availableRoles}
                    fetchSavedAndDefaultRoles={fetchSavedAndDefaultRoles}
                    loadingRoles={loadingRoles}
                  />
                </Skeleton>
              </Panel>
              <Panel header="Associated Users" key="user-mgmt">
                <UserManagement
                  siteAdmin={true}
                  fetchSelectedClientUsers={fetchSelectedClientUsers}
                  emailDomains={emailDomains}
                  userRoles={userRoles}
                  disableAddUser={!accountInfo?.auth0_organization_id}
                />
              </Panel>
            </Collapse>
          </>
        )}
      </Card>
    </div>
  );
};

export default AccountAdmin;
