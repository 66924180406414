import { Select, SelectProps, Spin } from 'antd';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { RefSelectProps } from 'antd/lib/select';
import { debounce } from 'lodash-es';
import { SearchOutlined } from '@ant-design/icons';
import { searchAuth0Users } from 'sb/api/users';
import { Auth0UserSearch } from 'sb/models/User';

interface Props extends SelectProps<number, Auth0UserSearch> {
  defaultOptions?: Auth0UserSearch[];
}

export const UsersSearch = forwardRef<RefSelectProps, Props>(function UsersSearch(
  { options, defaultOptions = [], ...rest },
  ref
) {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<Auth0UserSearch[]>([]);

  useEffect(() => {
    if (options && options.length) setUsers(options);
  }, [options]);

  const handleSearch = debounce(async (str: string) => {
    if (str.length < 2) return;

    try {
      setLoading(true);
      const res = await searchAuth0Users(str);
      console.log(res);
      if (Array.isArray(res)) setUsers([...defaultOptions, ...res]);
    } finally {
      setLoading(false);
    }
  }, 325);

  const handleClear = () => {
    setUsers([]);
  };

  const selectOptions = useMemo(
    () =>
      users.map((a) => (
        <Select.Option key={a.user_id} value={a.user_id}>
          <span>{a.name}</span> <span style={{ color: '#999999', fontSize: 13 }}>({a.email})</span>
        </Select.Option>
      )),
    [users]
  );

  return (
    <Select
      ref={ref}
      allowClear
      showArrow
      showSearch
      virtual={false}
      filterOption={false}
      notFoundContent={loading ? <Spin size="default" /> : null}
      suffixIcon={<SearchOutlined />}
      {...rest}
      onSearch={handleSearch}
      onClear={handleClear}
    >
      {selectOptions}
    </Select>
  );
});
