import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Divider, Input, message, Row, Space, Typography } from 'antd';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { nanoid } from 'nanoid';
import { useScopes } from 'hooks/useScopes';
import { PermissionTable } from 'components/atoms/PermissionTable';
import { Permission } from 'sb/models/Permission';
import { deletePermission, getPermissions, updatePermission } from 'sb/api/admin';
import { permissionState } from 'recoil/atoms';

const Permissions: React.FC = () => {
  const { aclCheck } = useScopes();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [editingPermission, setEditingPermission] = useState('');
  const [permissions, setPermissions] = useRecoilState(permissionState);

  const getAllPermissions = useCallback(async () => {
    setLoading(true);

    const res = await getPermissions();
    if (res?.status === 'success') setPermissions(res.data);

    setLoading(false);
  }, [setPermissions]);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    getAllPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const handleAdd = () => {
    const newPermissionId = nanoid();
    setPermissions((prev) => [{ id: newPermissionId, name: '', read: 1, create: 1, update: 1, delete: 1 }, ...prev]);
    setTimeout(() => {
      setEditingPermission(newPermissionId);
    }, 100);
  };

  const handleUpdate = async (row: Permission, data: Omit<Permission, 'id'>) => {
    const temp = [...permissions];
    setPermissions((prev) =>
      prev.map((permission) => (permission.id === row.id ? { ...permission, ...data } : permission))
    );
    setEditingPermission('');

    const res = await updatePermission(row, data);
    if (res?.status === 'success') {
      message.success('Permission successfully updated.');
    } else {
      setPermissions(temp);
      setEditingPermission(row.id);
    }
  };

  const handleDelete = async (row: Permission) => {
    const temp = [...permissions];
    setPermissions((perv) => perv.filter((permission) => permission.id !== row.id));

    if (!row.name) return;

    const res = await deletePermission(row.name);
    if (res?.status === 'success') {
      message.success('Permission successfully deleted.');
    } else {
      setPermissions(temp);
    }
  };

  const filteredPermissions = useMemo(
    () => permissions.filter((permission) => permission.name.toLowerCase().includes(query.toLowerCase())),
    [permissions, query]
  );

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Site Permissions</Typography.Title>
        </Col>
        <Col>
          <Space>
            {aclCheck(['create:site_wide_admin']) && (
              <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
                Add
              </Button>
            )}
            <Button type="default" loading={loading} icon={<SyncOutlined spin={loading} />} onClick={getAllPermissions}>
              Refresh
            </Button>
          </Space>
        </Col>
      </Row>

      <Input className="w-1/2" size="large" placeholder="Search for permissions" onChange={handleSearch} />

      <Divider className="my-4" />

      <PermissionTable
        loading={loading}
        dataSource={filteredPermissions}
        editKey={editingPermission}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    </>
  );
};

export default Permissions;
