import { useMemo } from 'react';
import { Button, Table, TableProps, TableColumnsType, Popconfirm, Tooltip } from 'antd';
import moment from 'moment';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import { useScopes } from 'hooks/useScopes';
import { PriceList } from 'sb/models/PriceList';
import { FieldSorter } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';

interface Props extends TableProps<PriceList> {
  priceListId?: number;
  onItemClick: (rec: PriceList) => void;
  onItemDelete?: (rec: PriceList) => void;
}

export const PriceListTable: React.FC<Props> = ({ priceListId, onItemClick, onItemDelete, ...rest }) => {
  const { aclCheck } = useScopes();

  const columns = useMemo(() => {
    const columns: TableColumnsType<PriceList> = [
      {
        title: 'Name',
        dataIndex: 'price_list_name',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: PriceList, b: PriceList) => FieldSorter(a.price_list_name, b.price_list_name),
        render: (_, rec: PriceList) => (
          <Button type="link" size="small" className="table-link" onClick={() => onItemClick(rec)}>
            {rec.price_list_name || <i>Un-named</i>}
          </Button>
        )
      },
      {
        title: 'Product Count',
        dataIndex: 'product_count',
        align: 'center',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: PriceList, b: PriceList) => a.product_count - b.product_count,
        render: (_, rec: PriceList) => new Intl.NumberFormat().format(rec.product_count)
      },
      {
        title: 'Effective Date',
        dataIndex: 'effective_date',
        defaultSortOrder: 'descend',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: PriceList, b: PriceList) => FieldSorter(a.effective_date, b.effective_date),
        render: (_, rec: PriceList) => (rec.effective_date ? moment(rec.effective_date).format('ll') : '--')
      },
      {
        title: 'Expiration Date',
        dataIndex: 'expiration_date',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: PriceList, b: PriceList) => FieldSorter(a.expiration_date, b.expiration_date),
        render: (_, rec: PriceList) => (rec.expiration_date ? moment(rec.expiration_date).format('ll') : '--')
      },
      {
        title: 'Date Created',
        dataIndex: 'creation_date',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: PriceList, b: PriceList) => FieldSorter(a.effective_date, b.effective_date),
        render: (_, rec: PriceList) => (rec.creation_date ? moment(rec.creation_date).format('ll') : '--')
      }
    ];

    if (aclCheck(['delete:all_supplier_price_lists'])) {
      columns.push({
        title: '',
        key: 'action',
        align: 'center',
        width: 70,
        render: (_, rec: PriceList) =>
          priceListId === rec.price_list_id ? (
            <LoadingOutlined style={{ fontSize: 16 }} spin />
          ) : (
            <Popconfirm title="Are you sure to delete?" placement="leftBottom" onConfirm={() => onItemDelete?.(rec)}>
              <Tooltip title="Click to delete row">
                <Button type="link" size="small" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          )
      });
    }

    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceListId, onItemClick, onItemDelete]);

  return (
    <Table
      rowKey="price_list_id"
      size="small"
      columns={columns}
      {...rest}
      pagination={DEFAULT_TABLE_PAGINATION_CONFIG}
    />
  );
};
