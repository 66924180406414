import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { Reseller } from 'sb/models/Reseller';

export async function getResellers(str: string) {
  try {
    const res = await axios.get(`/secure/resellers?str=${str}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createReseller(values: Omit<Reseller, 'id'>) {
  try {
    const res = await axios.post(`/secure/reseller`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateReseller(id: number, values: Partial<Reseller>) {
  try {
    const res = await axios.patch(`/secure/reseller/${id}`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
