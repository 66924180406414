import { useEffect, useState } from 'react';
import { Col, Collapse, Row, Space, Tag, Typography } from 'antd';
import { SupplierDynamicScore } from 'models/SupplierDynamics';
import 'components/supplier-dynamics/SupplierCard.scss';
import './DealDynamics.scss';
import { useRecoilValue } from 'recoil';
import { selectedSupplierDynamicsVendorState } from 'recoil/atoms';
import { fetchSDSupplierScores } from 'api/vendor';

const { Panel } = Collapse;

const panelMarginBottom = 24;

/**
 * Represents a component that displays deal dynamics.
 *
 * @component
 * @returns {React.ReactElement} A React element representing the DealDynamics component.
 */
export const DealDyanmics = (): React.ReactElement => {
  const [supplierScores, setSupplierScores] = useState<SupplierDynamicScore[]>();
  const [activeKey, setActiveKey] = useState([1]);

  // RECOIL STATE
  const selectedSupplier = useRecoilValue(selectedSupplierDynamicsVendorState);

  const scroresHint: any = {
    1: 'LOW',
    2: 'MED',
    3: 'HIGH'
  };

  useEffect(() => {
    const activeKey = localStorage.getItem('supplierDynamicsCollapseKey');
    if (activeKey) {
      setActiveKey(JSON.parse(activeKey));
    }
  }, []);

  useEffect(() => {
    if (!selectedSupplier) return;

    (async () => {
      const scores = await fetchSDSupplierScores(selectedSupplier.id);
      setSupplierScores(scores);
    })();
  }, [selectedSupplier]);

  /**
   * Calculates the class for the needle based on the given value.
   *
   * @param {number} value - The value to calculate the needle class for.
   * @returns {string} The class for the needle.
   */
  const getNeedleClass = (value: number): string => {
    switch (value) {
      case 1:
        return 'rotate(-82deg)';
      case 3:
        return 'rotate(82deg)';
      default:
        return '';
    }
  };

  /**
   * Returns the transformation string needed for rotating a needle based on the score percentage.
   *
   * @param {SupplierDynamicScore} score - The dynamic score of the supplier.
   * @return {string} - The transformation string for rotating the needle.
   */
  const getPercentNeedleTransform = (score: SupplierDynamicScore): string => {
    return `rotate(${score.value * 600}deg)`;
  };

  /**
   * Retrieves the renewal negotiation value.
   *
   * @returns {number} The renewal negotiation value.
   */
  const getRenewalNegotiationValue = (): number => {
    return supplierScores?.find((score) => score.deal_dynamic.key === 'negotiationRunway')?.value || 3;
  };

  /**
   * Renders the negotiation runway element.
   *
   * @returns {React.ReactElement} The rendered negotiation runway element.
   */
  const negotiationRunwayElement = (): React.ReactElement => {
    return (
      <>
        <Space>
          <div className="months text-center">
            <div className="text-5xl font-medium ff-system tracking-tight leading-normal">
              {getRenewalNegotiationValue()}
            </div>
          </div>
          <div className="text-lg font-medium pl-2">Months</div>
        </Space>
        {LinearScale(supplierScores?.find((score) => score.deal_dynamic.key === 'negotiationRunway'))}
      </>
    );
  };

  /**
   * Renders a linear scale component based on the given dynamic score.
   *
   * @param {SupplierDynamicScore | undefined} sds - The dynamic score object or undefined if not available.
   * @returns {React.ReactElement} - The rendered linear scale component.
   */
  const LinearScale = (sds: SupplierDynamicScore | undefined): React.ReactElement => {
    /**
     * sd represents either provided supplier dynamic score or default value if
     * score doesn't exist in reponse.
     */
    const sd = sds || {
      value: 3,
      deal_dynamic: {
        scale_low: 1,
        scale_high: 12
      }
    };
    return (
      <div className={'linear-scale-container'}>
        {(() => {
          const td = [];
          for (let i = sd.deal_dynamic.scale_low; i <= sd.deal_dynamic.scale_high; i++) {
            td.push(
              <Tag
                key={i}
                color={i < sd.value + sd.deal_dynamic.scale_low ? '#ff6961' : '#ececec'}
                className={`linear-tag`}
              />
            );
          }
          return td;
        })()}
      </div>
    );
  };

  /**
   * Toggles the open state of the component, activating or deactivating the key.
   *
   * @function handleToggleOpen
   * @returns {void}
   */
  const handleToggleOpen = (): void => {
    const _activeKey = activeKey.length > 0 ? [] : [1];
    localStorage.setItem('supplierDynamicsCollapseKey', JSON.stringify(_activeKey));
    setActiveKey(_activeKey);
  };

  return (
    <div className="deal-dynamics">
      <div style={{ marginBottom: panelMarginBottom }}>
        <Collapse
          ghost
          expandIconPosition="end"
          expandIcon={() =>
            activeKey.length === 0 ? (
              <div style={{ fontSize: 13 }}>Show description</div>
            ) : (
              <div style={{ fontSize: 13 }}>Hide description</div>
            )
          }
          onChange={handleToggleOpen}
          activeKey={activeKey}
          className="mb-5"
        >
          <Panel header={'Purchase Characteristics'} key={1} showArrow={true}>
            <div className="-mt-1">
              These scores, gleaned from NPI&apos;s extensive market data, serve as a critical resource for IT sourcing
              professionals navigating the complex vendor landscape. Leveraging advanced analytics and historical
              trends, they offer a nuanced understanding of vendor behavior, pricing flexibility, and deal size trends.
              Using these scores, you can identify vendors whose offerings, pricing models, and market dynamics best fit
              your procurement strategies, ultimately leading to more favorable terms, cost savings, and successful
              vendor relationships.
            </div>
          </Panel>
        </Collapse>

        {supplierScores
          ?.filter((score) => score.deal_dynamic.key !== 'negotiationRunway')
          .map((score: SupplierDynamicScore, key: number) => (
            <Row key={key} className="mb-6">
              <Col flex={'200px'}>
                <div
                  className={`gauge-wrapper ${
                    score.deal_dynamic.chart_type === 'percent' ? 'gauge-percentage' : 'gauge-high-low'
                  }`}
                >
                  <div
                    className="needle"
                    style={{
                      transform:
                        score.deal_dynamic.chart_type === 'percent'
                          ? `${getPercentNeedleTransform(score)}`
                          : `${getNeedleClass(score.value)}`
                    }}
                  ></div>
                  <div className="score">
                    {score.deal_dynamic.chart_type === 'percent'
                      ? `${Math.round(score.value * 100)}%`
                      : scroresHint[score.value]}
                  </div>
                </div>
              </Col>
              <Col
                className="pr-4"
                span={key > 0 ? 19 : 14}
                style={{ borderRight: key === 0 ? '1px solid #ccc' : 'none' }}
              >
                <div className="deal-dynamic">
                  <span className="name">{score.deal_dynamic.name}</span>:{' '}
                  <span className="score-hint">
                    {score.deal_dynamic.chart_type === 'lowMediumHigh'
                      ? score.deal_dynamic.score_hints[score.value - 1]
                      : score.deal_dynamic.chart_type === 'percent'
                      ? score.deal_dynamic.score_hints[
                          score.value < 0 ? 0 : score.value === 0 ? 1 : score.value > 0 ? 2 : 1
                        ]
                      : ''}
                  </span>
                </div>
                <div className="score-tooltips">
                  {score.deal_dynamic.chart_type === 'lowMediumHigh'
                    ? score.deal_dynamic.score_tooltips[score.value - 1]
                    : score.deal_dynamic.chart_type === 'percent'
                    ? score.deal_dynamic.score_tooltips[
                        score.value < 0 ? 0 : score.value === 0 ? 1 : score.value > 0 ? 2 : 1
                      ]
                    : ''}
                </div>
              </Col>
              {key === 0 && (
                <Col span={5} className="text-center">
                  <Typography.Title level={5}>Negotiation Runway</Typography.Title>
                  <div className="text-center mt-4">{negotiationRunwayElement()}</div>
                </Col>
              )}
            </Row>
          ))}
      </div>
    </div>
  );
};
