import { AccountAdminMenu, AccountSettingsMenu, UserAdminMenu } from 'layout/Menus';
import { BreadcrumbItem } from 'types/BreadcrumbItem';

import { APP_NAME } from './constants';

/**
 * Retrieves page metadata based on the given location.
 * @param {string} location - The location of the page.
 * @return {{
 *  title: string;
 *  pageTitle?: string;
 *  breadcrumbs: BreadcrumbItem[];
 *  menu: any;
 * }} - The page metadata.
 */
export function pageMetadata(location: string): {
  title: string;
  pageTitle?: string;
  breadcrumbs: BreadcrumbItem[];
  menu: any;
} {
  let title: string;
  let breadcrumbs: BreadcrumbItem[];
  let menu: any;

  if (location === '/site_administration') {
    title = `Site Administration`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Site Administration' }];
  } else if (location === '/site_administration/suppliers') {
    title = `Suppliers`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Suppliers' }
    ];
  } else if (location === '/site_administration/products') {
    title = `Supplier Products`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Supplier Products' }
    ];
  } else if (location === '/site_administration/price_lists') {
    title = `Supplier Price Lists`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Supplier Price Lists' }
    ];
  } else if (location === '/site_administration/triage') {
    title = `Inquiry Triage`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Inquiry Triage' }
    ];
  } else if (location === '/site_administration/product_accuracy') {
    title = `Bill of Materials SKU Accuracy`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Bill of Materials SKU Accuracy' }
    ];
  } else if (location === '/site_administration/supplier_dynamics') {
    title = `Supplier Dynamics`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Supplier Dynamics' }
    ];
  } else if (location === '/site_administration/resellers') {
    title = `Resellers`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Resellers' }
    ];
  } else if (location === '/site_administration/clients') {
    title = `Clients`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Clients' }
    ];
  } else if (location === '/site_administration/users') {
    title = `Users`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Users' }
    ];
  } else if (location === '/site_administration/account_admin') {
    title = `Account Admin`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Account Management' }
    ];
  } else if (location === '/site_administration/workbench') {
    title = `Workbench`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Workbench' }
    ];
  } else if (location === '/site_administration/permissions') {
    title = `Site Permissions`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Site Permissions' }
    ];
  } else if (location === '/site_administration/roles') {
    title = `User Roles`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'User Roles' }
    ];
  } else if (location === '/site_administration/community_moderation') {
    title = `Community Moderation`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Community Moderation' }
    ];
  } else if (location === '/site_administration/review_moderation') {
    title = `Supplier Review Moderation`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Supplier Review Moderation' }
    ];
  } else if (location === '/site_administration/banner_administration') {
    title = `Banner Administration`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Site Administration', linkTo: '/site_administration' },
      { label: 'Banner Administration' }
    ];
  } else if (location === '/add_single_deal') {
    title = `${APP_NAME}: Add Single Deal`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Smart Calendar™', linkTo: '/deal_calendar' },
      { label: 'Add Deal to Smart Calendar™' }
    ];
  } else if (location === '/add_multiple_deals') {
    title = `${APP_NAME}: Add Multiple Deals`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Smart Calendar™', linkTo: '/deal_calendar' },
      { label: 'Add Multiple Deals to Smart Calendar™' }
    ];
  } else if (location === '/upload_deals') {
    title = `${APP_NAME}: Add Bulk Deals`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/' },
      { label: 'Smart Calendar™', linkTo: '/deal_calendar' },
      { label: 'Add Bulk Deals to Smart Calendar™' }
    ];
  } else if (location === '/account_admin') {
    title = `${APP_NAME}: Account Administration`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Account Administration' }];
    menu = AccountAdminMenu;
  } else if (location === '/user_admin') {
    title = `${APP_NAME}: Personal Settings`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Personal Settings' }];
    menu = UserAdminMenu;
  } else if (location === '/notification_preferences') {
    title = `${APP_NAME}: Notification Preferences`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Notification Preferences' }];
    menu = UserAdminMenu;
  } else if (location === '/account_settings') {
    title = `${APP_NAME}: Account Settings`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Account Settings' }];
    menu = AccountSettingsMenu;
  } else if (location === '/banner_admin') {
    title = `${APP_NAME}: Banner Administration`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Banner Administration' }];
  } else if (location === '/subscription_purchases') {
    title = `${APP_NAME}: Subscription Purchases`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'NPI Subscription Deal Data' }];
  } else if (location === '/deal_calendar') {
    title = `${APP_NAME}: Deals`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Smart Calendar™' }];
  } else if (location === '/supplier_dynamics') {
    title = `${APP_NAME}: Supplier Dynamics`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Supplier Dynamics™' }];
  } else if (location === '/sb/benchmarks/product') {
    title = `Product Pricing Benchmark`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '20' },
      { label: 'Product Pricing Benchmark', key: '21' }
    ];
    menu = null;
  } else if (location.startsWith('/research/analyst')) {
    title = `${APP_NAME}: NPI Research`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'NPI Research' }];
  } else if (location.startsWith('/pinpoint')) {
    title = `${APP_NAME}: NPI Pinpoint`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'NPI PinPoint™' }];
  } else if (location.startsWith('/submit_purchase')) {
    title = `${APP_NAME}: Submit Purchase`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Submit Purchase for Analysis' }];
    menu = null;
  } else if (location.startsWith('/deal_creation_success/')) {
    title = `${APP_NAME}: Deal Creation Success`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Deal Creation Success' }];
    menu = null;
  } else if (location.startsWith('/deal_details/')) {
    title = `${APP_NAME}: Deal Details`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'Deal Details' }];
    menu = null;
  } else if (location.startsWith('/resources')) {
    title = `${APP_NAME}: NPI Sourcing Insights`;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }, { label: 'NPI Sourcing Insights' }];
    menu = null;
  } else if (location === '/sb') {
    title = `${APP_NAME}: SmartBench Home`;
    breadcrumbs = [{ label: 'Home' }];
    menu = null;
  } else if (location.startsWith('/sb/contract/')) {
    title = `Contract`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '15' },
      { label: 'Contracts', linkTo: '/sb/contracts', key: '16' },
      { label: 'Contract', key: '17' }
    ];
    menu = null;
  } else if (location.startsWith('/sb/deal/')) {
    title = `Deal`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '15' },
      { label: 'Contracts', linkTo: '/sb/contracts', key: '16' },
      { label: 'Deal', key: '17' }
    ];
    menu = null;
  } else if (location === '/sb/contracts') {
    title = `Contracts`;
    // pageTitle = `Contracts`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '18' },
      { label: 'Contracts', key: '19' }
    ];
    menu = null;
  } else if (location === '/sb/reports') {
    title = `Reports`;
    // pageTitle = `Reports`;
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '23' },
      { label: 'Reports', key: '25' }
    ];
  } else if (location.startsWith('/sb/reports/builder')) {
    title = 'Report';
    // pageTitle = 'Start a new report';
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '25' },
      { label: 'Reports', linkTo: '/sb/reports', key: '101' },
      { label: 'FMV Builder', key: '27' }
    ];
    menu = null;
  } else if (location.startsWith('/sb/reports/')) {
    title = 'Report';
    // pageTitle = 'Fair Market Value ReportViewer';
    breadcrumbs = [
      { label: 'Home', linkTo: '/sb', key: '25' },
      { label: 'Reports', linkTo: '/sb/reports', key: '105' },
      { label: 'Report', key: '27' }
    ];
    menu = null;
  } else {
    title = APP_NAME;
    breadcrumbs = [{ label: 'Home', linkTo: '/' }];
    menu = null;
  }

  return {
    title,
    breadcrumbs,
    menu
  };
}
