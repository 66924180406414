// import { Suspense, useEffect } from 'react';
import { Suspense } from 'react';
// import { useRecoilValue } from 'recoil';
// import { useNavigate } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

// import { authState } from 'recoil/atoms';

interface Props {
  as: React.ComponentType<any>;
  tab?: string;
}

export const PrivateRoute: React.FC<Props> = ({ as, ...rest }) => {
  // const navigate = useNavigate();
  // const { user } = useRecoilValue(authState);

  const Component = withAuthenticationRequired(as);

  // useEffect(() => {
  //   if (!user?.email_verified) {
  //     navigate('/verify_email');
  //   }
  // }, [user, navigate]);

  return (
    <Suspense fallback={null}>
      <Component {...rest} />
    </Suspense>
  );
};
