import { Button, Popconfirm, Space, TableColumnsType, Tooltip } from 'antd';
import { Competitor } from 'sb/models/DealDynamicsSupplier';
import { useMemo } from 'react';
import { FieldSorter } from '../../sb/shared/Helpers';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';

interface Props {
  hideCategoryColumn: boolean;
  onEditClick: (rec: Competitor) => void;
  onDeleteCompetitorClick: (rec: Competitor) => void;
}

export const DealDynamicsCompetitorColumnTable = ({
  hideCategoryColumn,
  onEditClick,
  onDeleteCompetitorClick
}: Props) => {
  const { aclCheck } = useScopes();

  const columns: TableColumnsType<Competitor> = useMemo(
    () => [
      {
        title: 'Competitor',
        dataIndex: 'competitor_name',
        sorter: (a: Competitor, b: Competitor) =>
          FieldSorter(
            a.vendor_competitor_competitor_idTovendor?.name || a.competitor,
            b.vendor_competitor_competitor_idTovendor?.name || b.competitor
          ),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Competitor) => <>{rec.vendor_competitor_competitor_idTovendor?.name || '--'}</>
      },
      {
        title: 'Additional Label',
        dataIndex: 'product_category',
        sorter: (a: Competitor, b: Competitor) => FieldSorter(a.competitor, b.competitor),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Competitor) => <>{rec.competitor || '--'}</>
      },
      {
        title: 'Product Category',
        dataIndex: 'competitor_name',
        sorter: (a: Competitor, b: Competitor) =>
          FieldSorter(a.supplier_category?.category, b.supplier_category?.category),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: Competitor) => rec.supplier_category?.category || '--'
      },
      {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        width: 80,
        render: (_, rec: Competitor) => (
          <Space>
            <Tooltip title="Edit Competitor.." placement="bottom">
              <Button
                disabled={!aclCheck(['update:supplier_dynamics_admin'])}
                type="link"
                icon={<EditOutlined />}
                onClick={() => onEditClick(rec)}
              />
            </Tooltip>
            <Tooltip title="Delete Competitor.." placement="bottom">
              <Popconfirm
                title="Delete competior?"
                overlayStyle={{ width: 240 }}
                disabled={!aclCheck(['delete:supplier_dynamics_admin'])}
                placement="topLeft"
                onConfirm={() => onDeleteCompetitorClick(rec)}
              >
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          </Space>
        )
      }
    ],
    [aclCheck, onDeleteCompetitorClick, onEditClick]
  );
  // Filter out Category column if the vendor has no product categories
  return hideCategoryColumn ? columns.filter((col: any) => col.dataIndex !== 'category') : columns;
};
