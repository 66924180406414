import { selector } from 'recoil';
import { getAcodisExtractionAccuracies, getAcodisExtractionFeedbacks } from 'sb/api/acodisAccuracy';
import { AcodisExtractionAccuracy, AcodisExtractionFeedback } from 'sb/models/Acodis';
import { uiState, vendorState } from './atoms';
import { getStatusLov } from 'api/extra';
import { ReportLayoutDb, ReportLayoutElement } from 'sb/models/Reports';
import { fetchReportTemplates } from 'sb/api/report';
import { selectTaxonomy } from 'sb/api/products';
import { CategoryRoot } from 'sb/models/Product';

/*****************************
 * Menus
 ****************************/

/**************************
 * Account Administration
 **************************/
export const selectedVendor = selector({
  key: 'selectedVendor',
  get: ({ get }) => get(vendorState).selected
});

export const statusLovState = selector({
  key: 'statusLovState',
  get: async () => {
    const results: { id: number; name: string }[] = await getStatusLov();
    return Array.isArray(results) ? results : [];
  }
});

export const reportTemplatesState = selector({
  key: 'reportTemplatesState',
  get: async () => {
    const reportTemplates: ReportLayoutDb[] = await fetchReportTemplates();
    if (Array.isArray(reportTemplates)) {
      reportTemplates.map((template: ReportLayoutDb) => {
        if (template.report_layout_element) {
          template.topElements = template.report_layout_element.filter(
            (element: ReportLayoutElement) => element.report_element_template.level === 'top'
          );

          template.findingsElements = template.report_layout_element.filter(
            (element: ReportLayoutElement) => element.report_element_template.level === 'findings'
          );
        }
        template.report_layout_element = undefined;
        return template;
      });
    }
    return Array.isArray(reportTemplates) ? reportTemplates : [];
  }
});

export const allAcodisExtractionAccuracyState = selector({
  key: 'allAcodisExtractionAccuracyState',
  get: async () => {
    const accuracies: AcodisExtractionAccuracy[] = await getAcodisExtractionAccuracies();
    return Array.isArray(accuracies) ? accuracies : [];
  }
});

export const allAcodisExtractionFeedbackState = selector({
  key: 'allAcodisExtractionFeedbackState',
  get: async () => {
    const feedbacks: AcodisExtractionFeedback[] = await getAcodisExtractionFeedbacks();
    return Array.isArray(feedbacks) ? feedbacks : [];
  }
});

/**
 * Retrieves the taxonomy state.
 *
 * @async
 * @returns {Promise<Array<CategoryRoot>>} A promise that resolves with the taxonomy state, represented by an array of CategoryRoot objects.
 */
export const taxonomyState = selector({
  key: 'taxonomyState',
  get: async () => {
    const results: CategoryRoot[] = await selectTaxonomy();
    return Array.isArray(results) ? results : [];
  }
});

export const menuKeyState = selector({
  key: 'menuKeyState',
  get: ({ get }) => get(uiState).menuKey
});
