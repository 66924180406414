import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { ExcelCreateRequest } from 'sb/models/ExcelJs';

/**
 * Create Excel file
 * @param formData
 */
export const createExcel = async (formData: ExcelCreateRequest) => {
  try {
    const res = await axios.post(`/api/excel/create`, formData, {
      responseType: 'blob'
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
