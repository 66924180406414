import { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Table,
  TableProps,
  Tooltip,
  Switch,
  Drawer,
  message,
  Checkbox,
  Tag,
  TableColumnsType
} from 'antd';
import { EditOutlined, CheckOutlined } from '@ant-design/icons';

import { FieldSorter, FormatCurrency } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { ProductCategorySecondRow, ProductSearchType, SupplierProductRow } from 'sb/models/Product';
import { ProductLinkingModal } from 'components/molecules/ProductLinkingModal';
import { useSetRecoilState } from 'recoil';
import { refreshProductsTableState } from 'recoil/atoms';
import { nanoid } from 'nanoid';
import { ProductForm } from 'components/atoms/ProductForm';
import moment from 'moment';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  type: 'input' | 'checkbox';
  dataIndex: string;
  title: any;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, type, dataIndex, title, children, ...restProps }) => {
  const input = useMemo(() => {
    if (type === 'input') {
      return (
        <Form.Item className="m-0" name={dataIndex} rules={[{ required: true, message: `Please input ${title}!` }]}>
          <Input />
        </Form.Item>
      );
    } else if (type === 'checkbox') {
      return (
        <Form.Item className="m-0" name={dataIndex} valuePropName="checked">
          <Checkbox />
        </Form.Item>
      );
    }
    return null;
  }, [type, dataIndex, title]);

  return <td {...restProps}>{editing ? input : children}</td>;
};

interface Props extends TableProps<any> {
  showVendor: boolean;
  onChangeIsConfirmed: (rec: any) => void;
  onEditCategories: (rec: any) => void;
  onUpdateProduct: (rec: any, data: any) => void;
  onClickContract: (rec: any) => void;
}

export const ProductTable: React.FC<Props> = ({
  showVendor,
  onChangeIsConfirmed,
  onEditCategories,
  onUpdateProduct,
  onClickContract,
  ...rest
}) => {
  const [form] = Form.useForm();

  // LOCAL STATE
  const [selectedProductId, setSelectedProductId] = useState<number | undefined>(undefined);
  const [selectedProduct, setSelectedProduct] = useState<ProductSearchType | undefined>(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);

  // RECOIL STATE
  const setRefreshProductsTableState = useSetRecoilState(refreshProductsTableState);

  const handleEdit = useCallback(
    (record: any) => () => {
      form.setFieldsValue({
        ...record,
        end_of_support_date: record.end_of_support_date ? moment.utc(record.end_of_support_date) : undefined,
        end_of_life_date: record.end_of_life_date ? moment.utc(record.end_of_life_date) : undefined
      });
      setSelectedProduct(record);
      setOpenDrawer(true);
    },
    [form]
  );

  const handleCancelEdit = () => {
    setOpenDrawer(false);
    setSelectedProduct(undefined);
  };

  const handleSaveEdit = async () => {
    try {
      const row = await form.validateFields();
      onUpdateProduct?.(selectedProduct, row);
      setOpenDrawer(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onProductLinkageClickHandler = useCallback((rec: SupplierProductRow) => {
    setSelectedProductId(rec.id);
  }, []);

  const columns: TableColumnsType<SupplierProductRow> = useMemo(() => {
    const columns: any[] = [
      {
        title: 'Product Code',
        dataIndex: 'code',
        sortDirections: ['ascend', 'descend'],
        width: 200,
        type: 'input',
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => FieldSorter(a.code, b.code)
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 350,
        sortDirections: ['ascend', 'descend'],
        editable: true,
        type: 'input',
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => FieldSorter(a.name, b.name),
        render: (text: string) => <div className="table-render">{text}</div>
      },
      {
        title: 'Marker SKU',
        dataIndex: 'is_indicator_sku',
        editable: true,
        type: 'checkbox',
        width: 100,
        align: 'center',
        sortDirections: ['ascend', 'descend'],
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => +a.is_indicator_sku - +b.is_indicator_sku,
        render: (_: string, rec: SupplierProductRow) => (rec.is_indicator_sku ? <CheckOutlined /> : '--')
      },
      {
        title: 'Product Group',
        dataIndex: 'product_group_name',
        sorter: (a: SupplierProductRow, b: SupplierProductRow) =>
          (a.linked_products ? a.linked_products.length : 0) - (b.linked_products ? b.linked_products.length : 0),
        sortDirections: ['descend', 'ascend'],
        render: (_: any, rec: SupplierProductRow) => (
          <Button type="link" onClick={() => onProductLinkageClickHandler(rec)}>
            {rec.product_group_product.length > 0
              ? `${rec.product_group_product[0].product_group.name} (${
                  rec.linked_products ? rec.linked_products.length : 0
                })`
              : '--'}
          </Button>
        )
      },
      {
        title: 'Avg. Cost',
        dataIndex: 'avg_unit_cost',
        align: 'center',
        width: 100,
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => a.avg_unit_cost - b.avg_unit_cost,
        sortDirections: ['ascend', 'descend'],
        render: (_: any, rec: SupplierProductRow) => FormatCurrency(rec.avg_unit_cost)
      },
      {
        title: 'Outliers',
        dataIndex: 'outliers',
        align: 'center',
        width: 100,
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => a.outliers.length - b.outliers.length,
        sortDirections: ['descend', 'ascend'],
        render: (_: any, rec: SupplierProductRow) => rec.outliers.length
      },
      {
        title: 'Contracts',
        dataIndex: 'contract_count',
        align: 'center',
        width: 100,
        sorter: (a: SupplierProductRow, b: SupplierProductRow) => a.contracts_count - b.contracts_count,
        sortDirections: ['descend', 'ascend'],
        render: (_: any, rec: SupplierProductRow) =>
          rec.contracts_count > 0 ? (
            <Button type="link" onClick={() => onClickContract(rec)}>
              {rec.contracts_count}
            </Button>
          ) : (
            rec.contracts_count
          )
      },
      {
        title: 'Category(s)',
        dataIndex: 'category',
        width: 220,
        sortDirections: ['ascend', 'descend'],
        render: (_: any, rec: SupplierProductRow) => (
          <>
            {rec.product_category_second?.length && (
              <div className="table-render">
                {rec.product_category_second?.map((item: ProductCategorySecondRow, index: number) => (
                  <Tag key={index} style={{ borderRadius: 4, marginBottom: 6 }}>
                    {item.category_second.name}
                  </Tag>
                ))}
              </div>
            )}

            <Tooltip title="Click to update categories">
              <Button type="link" size="small" onClick={() => onEditCategories(rec)} icon={<EditOutlined />} />
            </Tooltip>
          </>
        )
      },
      {
        title: 'Confirmed',
        dataIndex: 'is_confirmed',
        align: 'center',
        render: (_: any, rec: SupplierProductRow) => (
          <Switch checked={rec.is_confirmed} onChange={() => onChangeIsConfirmed(rec)} />
        )
      },
      {
        title: ' ',
        dataIndex: 'action',
        align: 'center',
        width: 70,
        render: (_: string, rec: SupplierProductRow) => (
          <Tooltip title="Click to edit row">
            <Button type="link" size="small" icon={<EditOutlined />} onClick={handleEdit(rec)} />
          </Tooltip>
        )
      }
    ];

    if (showVendor) {
      columns.unshift({
        title: 'Supplier',
        dataIndex: 'vendor_name',
        width: 200,
        sortDirections: ['ascend', 'descend'],
        sorter: (a: any, b: any) => FieldSorter(a.vendor_name, b.vendor_name)
      });
    }

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          type: col.type
        })
      };
    });
  }, [showVendor, handleEdit, onChangeIsConfirmed, onEditCategories, onClickContract, onProductLinkageClickHandler]);

  const handleProductLinkModalOk = async () => {
    message.success('Saved successfully!');
    setSelectedProductId(undefined);

    // Change refreshProductsTable recoil state to trigger refresh of table
    setRefreshProductsTableState(nanoid());
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          className="sm-font"
          rowKey="id"
          size="small"
          rowClassName="table-editable-row"
          {...rest}
          columns={columns}
          components={{
            body: {
              cell: EditableCell
            }
          }}
          pagination={{
            ...DEFAULT_TABLE_PAGINATION_CONFIG,
            hideOnSinglePage: false
          }}
        />
      </Form>

      <ProductLinkingModal
        productId={selectedProductId}
        onOk={handleProductLinkModalOk}
        onCancel={() => setSelectedProductId(undefined)}
      />
      <Drawer title={'Edit Product'} width={600} visible={openDrawer} onClose={handleCancelEdit}>
        <ProductForm onFinish={handleSaveEdit} form={form} onCancel={handleCancelEdit} />
      </Drawer>
    </>
  );
};
