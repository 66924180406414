import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, Table, TableProps, Tooltip, Popconfirm, Checkbox, notification } from 'antd';
import { EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';
import { FieldSorter } from 'sb/shared/Helpers';
import { Permission } from 'sb/models/Permission';
import { PERMISSION_ROLE_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';

const ROW_KEY = 'id';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  type: string;
}

const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, type, children, ...restProps }) => {
  const renderControl = () => {
    switch (type) {
      case 'input':
        return (
          <Form.Item className="m-0" name={dataIndex} rules={[{ required: true, message: `Please input ${title}!` }]}>
            <Input />
          </Form.Item>
        );
      case 'checkbox':
        return (
          <Form.Item className="m-0" name={dataIndex} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  return <td {...restProps}>{editing ? renderControl() : children}</td>;
};

interface Props extends TableProps<Permission> {
  editKey?: string;
  onUpdate?: (row: Permission, data: Omit<Permission, 'id'>) => void;
  onDelete?: (row: Permission) => void;
}

export const PermissionTable: React.FC<Props> = ({ editKey, onUpdate, onDelete, ...rest }) => {
  const [form] = Form.useForm<Omit<Permission, 'id'>>();
  const [editingKey, setEditingKey] = useState('');
  const { aclCheck } = useScopes();

  useEffect(() => {
    if (editKey) {
      const rec = rest.dataSource?.find((permission) => permission[ROW_KEY] === editKey);
      if (rec) {
        form.setFieldsValue({
          name: rec.name,
          create: rec.create,
          read: rec.read,
          update: rec.update,
          delete: rec.delete
        });
        setEditingKey(editKey);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editKey]);

  const handleEdit = useCallback(
    (rec: Permission) => () => {
      if (!editingKey) {
        form.setFieldsValue({
          name: rec.name,
          create: rec.create,
          read: rec.read,
          update: rec.update,
          delete: rec.delete
        });
        setEditingKey(rec[ROW_KEY]);
      } else {
        notification.warning({ message: 'Please finish editing current row' });
      }
    },
    [form, editingKey]
  );

  const handleDelete = useCallback(
    (rec: Permission) => () => {
      onDelete?.(rec);
    },
    [onDelete]
  );

  const handleCancel = useCallback(
    (rec: Permission) => () => {
      setEditingKey('');
      if (!rec.name) {
        onDelete?.(rec);
      }
    },
    [onDelete]
  );

  const handleSave = useCallback(
    (rec: Permission) => async () => {
      try {
        const row = await form.validateFields();
        onUpdate?.(rec, row);
        setEditingKey('');
      } catch (errInfo) {
        console.log('Validate Failed:', errInfo);
      }
    },
    [form, onUpdate]
  );

  const columns = useMemo(() => {
    const columns: any[] = [
      {
        title: 'Name',
        dataIndex: 'name',
        editable: true,
        type: 'input',
        sorter: (a: Permission, b: Permission) => FieldSorter(a.name, b.name)
      },
      {
        title: 'Read',
        dataIndex: 'read',
        align: 'center',
        editable: true,
        type: 'checkbox',
        render: (_: string, rec: Permission) => (rec.read ? <CheckOutlined /> : null)
      },
      {
        title: 'Create',
        dataIndex: 'create',
        align: 'center',
        editable: true,
        type: 'checkbox',
        render: (_: string, rec: Permission) => (rec.create ? <CheckOutlined /> : null)
      },
      {
        title: 'Update',
        dataIndex: 'update',
        align: 'center',
        editable: true,
        type: 'checkbox',
        render: (_: string, rec: Permission) => (rec.update ? <CheckOutlined /> : null)
      },
      {
        title: 'Delete',
        dataIndex: 'delete',
        align: 'center',
        editable: true,
        type: 'checkbox',
        render: (_: string, rec: Permission) => (rec.delete ? <CheckOutlined /> : null)
      },
      {
        title: '',
        key: 'action',
        align: 'center',
        width: 70,
        render: (_: string, rec: Permission) =>
          rec[ROW_KEY] === editingKey ? (
            <>
              <Tooltip title="Click to save row">
                <Button type="link" size="small" icon={<SaveOutlined />} onClick={handleSave(rec)} />
              </Tooltip>
              <Tooltip title="Cancel">
                <Button type="link" size="small" icon={<CloseOutlined />} onClick={handleCancel(rec)} />
              </Tooltip>
            </>
          ) : (
            <>
              {aclCheck(['update:site_wide_admin']) && (
                <Tooltip title="Click to edit row">
                  <Button type="link" size="small" icon={<EditOutlined />} onClick={handleEdit(rec)} />
                </Tooltip>
              )}
              {aclCheck(['update:site_wide_admin']) && (
                <Popconfirm
                  title="Please double-check code base to ensure that removing or editing this permission doesn't break any end points."
                  placement="leftBottom"
                  onConfirm={handleDelete(rec)}
                >
                  <Tooltip title="Click to delete row">
                    <Button type="link" size="small" icon={<DeleteOutlined />} />
                  </Tooltip>
                </Popconfirm>
              )}
            </>
          )
      }
    ];

    return columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: record[ROW_KEY] === editingKey,
          type: col.type
        })
      };
    });
  }, [editingKey, handleSave, handleCancel, aclCheck, handleEdit, handleDelete]);

  return (
    <Form form={form} component={false}>
      <Table
        size="small"
        rowKey={ROW_KEY}
        rowClassName="table-editable-row"
        columns={columns}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        pagination={PERMISSION_ROLE_TABLE_PAGINATION_CONFIG}
        {...rest}
      />
    </Form>
  );
};
