import React from 'react';
import { Button, Card, Typography } from 'antd';
import './DealCreationSuccess.scss';
import AddDealTasksIcon from 'assets/AddDealTasksIcon.svg';
import { useNavigate } from 'react-router-dom';

interface DealActionsProps {
  contract_id: number;
}

export const AddDealTasks = (props: DealActionsProps) => {
  const { contract_id } = props;
  const navigate = useNavigate();

  return (
    <Card bordered={false} className="bg-transparent text-center w-96">
      <Button
        type={'link'}
        icon={<img className="mr-2" src={AddDealTasksIcon} alt="Icon" height={20} width={20} />}
        size="large"
        onClick={() => navigate(`/deal_details/${contract_id}?tab=tasks`, { state: 'returnToDashboard' })}
      >
        Add NPI-recommended tasks
      </Button>
      <Typography.Title level={5} className="!font-light text-xs leading-4 text-center">
        Add NPI-recommended tasks to this deal to help you and your team stay on top of everything, increasing the
        likelihood of a successful outcome.
      </Typography.Title>
    </Card>
  );
};
