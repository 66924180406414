import { useEffect, useState, forwardRef, ReactNode } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { RefSelectProps } from 'antd/lib/select';
import { debounce } from 'lodash-es';

import { searchVendors } from 'sb/api/vendor';

type Supplier = {
  id: number;
  name: ReactNode;
};

interface Props extends SelectProps<number, Supplier> {
  width?: string | number;
  reseller?: boolean;
  limitToVendorDynamics?: boolean;
  defaultOptions?: Supplier[];
}

export const VendorsSearch = forwardRef<RefSelectProps, Props>(function VendorSearch(
  { width = 480, reseller, options = [], defaultOptions = [], limitToVendorDynamics, ...rest },
  ref
) {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState<Supplier[]>([]);

  useEffect(() => {
    if (options.length) setVendors(options);
  }, [options]);

  const handleSearch = debounce(async (str: string) => {
    if (str.length < 2) return;

    setLoading(true);
    const res = await searchVendors(reseller || false, str, limitToVendorDynamics);
    if (Array.isArray(res)) setVendors([...defaultOptions, ...res]);
    setLoading(false);
  }, 325);

  const handleClear = () => {
    setVendors([]);
  };

  return (
    <Select<number, Supplier>
      ref={ref}
      placeholder="Search for Supplier"
      size="large"
      style={{ width }}
      allowClear
      showArrow
      showSearch
      virtual={false}
      filterOption={false}
      notFoundContent={loading ? <Spin size="default" /> : null}
      suffixIcon={<SearchOutlined />}
      {...rest}
      fieldNames={{ label: 'name', value: 'id' }}
      options={vendors}
      onSearch={handleSearch}
      onClear={handleClear}
    />
  );
});
