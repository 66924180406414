import { Button, Col, Divider, Row, Table, Typography } from 'antd';
import { useRecoilValue } from 'recoil';
import { uiState } from 'recoil/atoms';
import { useCallback, useEffect, useState } from 'react';
import { Inquiry } from 'sb/models/Inquiry';
import { selectAssignedInquiries } from 'sb/api/note';
// import { User } from 'sb/models/User';
import { SyncOutlined } from '@ant-design/icons';
import { InquiryWorkbenchCard } from '../../components/molecules/admin/InquiryWorkbenchCard';
import { InquiryTableColumnsAnalyst } from 'components/columns/InquiryTableColumnsAnalyst';

const InquiryWorkbench: React.FC = () => {
  // LOCAL STATE
  const [inquries, setInquiries] = useState<Inquiry[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState<undefined | Inquiry>(undefined);
  const [fetchingInquiries, setFetchingInquiries] = useState(false);

  // RECOIL STATE
  const { menuKey } = useRecoilValue(uiState);

  const fetchInquiries = useCallback(async () => {
    try {
      setFetchingInquiries(true);
      const inquiries: Inquiry[] | undefined = await selectAssignedInquiries();
      if (Array.isArray(inquiries)) {
        setInquiries(inquiries);
      }
    } finally {
      setFetchingInquiries(false);
    }
    const handleEsc = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setShowDetails(false);
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    if (menuKey !== 'inquiry_workbench') return;
    setShowDetails(false);
    (async () => {
      await fetchInquiries();
    })();
  }, [fetchInquiries, menuKey]);

  const onSubjectClickHandler = (selectedInquiry: Inquiry) => {
    setSelectedInquiry(selectedInquiry);
    setShowDetails(true);
  };

  const onInquiryWorkbenchUpdateHandler = async () => {
    await fetchInquiries();
  };

  const onWorkbenchCloseHandler = () => {
    setShowDetails(false);
    setSelectedInquiry(undefined);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Inquiry Workbench</Typography.Title>
        </Col>
        <Col>
          <Button
            type="default"
            loading={fetchingInquiries}
            icon={<SyncOutlined spin={fetchingInquiries} />}
            onClick={fetchInquiries}
          >
            Refresh
          </Button>
        </Col>
      </Row>

      <Divider />
      <Table<Inquiry>
        loading={fetchingInquiries}
        className={'sm-font'}
        showSorterTooltip={false}
        dataSource={inquries}
        rowKey={'id'}
        size={'small'}
        columns={InquiryTableColumnsAnalyst({
          onSubjectClick: onSubjectClickHandler
        })}
      />
      {selectedInquiry && (
        <InquiryWorkbenchCard
          id={selectedInquiry.id}
          onCancel={onWorkbenchCloseHandler}
          onUpdate={onInquiryWorkbenchUpdateHandler}
          open={showDetails}
        />
      )}
    </div>
  );
};

export default InquiryWorkbench;
