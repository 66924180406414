import { useState, forwardRef } from 'react';
import { Select, SelectProps, Spin } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash-es';

import { searchSalesforceAccounts } from 'api/salesforce';

interface Account {
  Id: string;
  Name: string;
  Is_Vendor__c: boolean;
  Type: string;
}

export const SfAccountsSearch = forwardRef<RefSelectProps, SelectProps>(function SfAccountsSearch(props, ref) {
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState<Account[]>([]);

  const handleSearch = debounce(async (str: string) => {
    if (str.length >= 2) {
      setLoading(true);
      const res = await searchSalesforceAccounts(str);
      if (res) {
        setAccounts(res);
      }
      setLoading(false);
    }
  }, 325);

  const handleClear = () => {
    setAccounts([]);
  };

  return (
    <Select
      ref={ref}
      size="large"
      placeholder={<div className="text-left">Search Salesforce for Clients & Suppliers</div>}
      notFoundContent={loading ? <Spin size="default" /> : null}
      suffixIcon={<SearchOutlined />}
      showSearch
      allowClear
      showArrow={false}
      labelInValue
      virtual
      filterOption={false}
      onSearch={handleSearch}
      onClear={handleClear}
      {...props}
    >
      {accounts.map((a) => (
        <Select.Option value={`${a.Id}_${a.Is_Vendor__c ? `vendor_${a.Type}` : `client_${a.Type}`}`} key={a.Id}>
          {a.Name}
        </Select.Option>
      ))}
    </Select>
  );
});
