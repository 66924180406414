import { Button, Popconfirm, Space, Table, TableColumnsType, TableProps, Tag, Tooltip } from 'antd';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';
import { Role } from 'sb/models/Role';
import { FieldSorter } from 'sb/shared/Helpers';
import { PERMISSION_ROLE_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { AllPermissionMethods } from 'sb/models/Permission';

interface Props extends TableProps<Role> {
  onEdit?: (item: Role) => void;
  onDelete?: (item: Role) => void;
}

export const RoleTable: React.FC<Props> = ({ onEdit, onDelete, ...rest }) => {
  const { aclCheck } = useScopes();

  const columns: TableColumnsType<Role> = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '30%',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Role, b: Role) => FieldSorter(a.name, b.name),
      render: (_, rec: Role) => rec.name || '--'
    },
    {
      title: 'Client Role?',
      dataIndex: 'is_client_role',
      width: '15%',
      align: 'center',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Role, b: Role) => +a.is_client_role - +b.is_client_role,
      render: (_: string, rec: Role) => (rec.is_client_role ? <CheckOutlined /> : null)
    },
    {
      title: 'Permissions',
      dataIndex: 'permissions',
      render: (_, rec: Role) =>
        rec.permissions.map((permission) => (
          <Tag key={permission.name}>
            {permission.name}: {AllPermissionMethods.filter((method) => permission[method]).join(', ')}
          </Tag>
        ))
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 70,
      render: (_: string, rec: Role) => (
        <Space>
          {aclCheck(['update:site_wide_admin']) && (
            <Tooltip title="Click to edit row">
              <Button type="link" size="small" icon={<EditOutlined />} onClick={() => onEdit?.(rec)} />
            </Tooltip>
          )}

          {aclCheck(['delete:site_wide_admin']) && (
            <Popconfirm title="Are you sure to delete?" placement="leftBottom" onConfirm={() => onDelete?.(rec)}>
              <Tooltip title="Click to delete row">
                <Button type="link" size="small" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          )}
        </Space>
      )
    }
  ];

  return (
    <Table rowKey="id" {...rest} columns={columns} size="small" pagination={PERMISSION_ROLE_TABLE_PAGINATION_CONFIG} />
  );
};
