import { useCallback, useEffect, useState } from 'react';
import { selectInquiries } from 'sb/api/note';
import { Inquiry } from 'sb/models/Inquiry';
import { useRecoilValue } from 'recoil';
import { uiState } from 'recoil/atoms';
import { Button, Col, Divider, Row, Space, Table, Typography } from 'antd';
import { InquiryTableColumnsAnalyst } from 'components/columns/InquiryTableColumnsAnalyst';
import { InquiryStatusModal } from '../../components/molecules/admin/InquiryStatusModal';
import { PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { InquirySubmissionModal } from 'components/molecules/admin/InquirySubmissionModal';
import { useScopes } from 'hooks/useScopes';

const InquiryAdministration: React.FC = () => {
  const { aclCheck } = useScopes();

  // LOCAL STATE
  const [inquries, setInquiries] = useState<Inquiry[]>([]);
  const [selectedInquiry, setSelectedInquiry] = useState<undefined | Inquiry>(undefined);
  const [statusOpen, setStatusOpen] = useState(false);
  const [fetchingInquiries, setFetchingInquiries] = useState(false);
  const [inquirySubmissionOpen, setInquirySubmissionOpen] = useState(false);

  // RECOIL STATE
  // const npiUsers = useRecoilValue(allAccountUsersState);
  const { menuKey } = useRecoilValue(uiState);

  const fetchInquiries = useCallback(async () => {
    try {
      setFetchingInquiries(true);
      const inquiries: Inquiry[] | undefined = await selectInquiries();
      if (Array.isArray(inquiries)) {
        setInquiries(inquiries);
      }
    } finally {
      setFetchingInquiries(false);
    }
  }, []);

  useEffect(() => {
    if (menuKey !== 'inquiry_admin') return;
    (async () => {
      await fetchInquiries();
    })();
  }, [fetchInquiries, menuKey]);

  const onStatusClickHandler = async (rec: Inquiry) => {
    setSelectedInquiry(rec);
    setStatusOpen(true);
  };

  const onStatusModalCancelHandler = () => {
    setStatusOpen(false);
    setSelectedInquiry(undefined);
  };

  const onStatusModalUpdateHandler = async () => {
    await fetchInquiries();
  };

  const inquirySubmissionCancelHandeler = () => {
    setInquirySubmissionOpen(false);
  };

  const onAddInquiryHandler = async () => {
    setInquirySubmissionOpen(false);
    await fetchInquiries();
  };

  return (
    <div>
      <InquirySubmissionModal
        onCancel={inquirySubmissionCancelHandeler}
        open={inquirySubmissionOpen}
        onSave={onAddInquiryHandler}
      />
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Inquiry Triage</Typography.Title>
        </Col>
        <Col>
          <Space>
            <Button
              type="default"
              loading={fetchingInquiries}
              icon={<SyncOutlined spin={fetchingInquiries} />}
              onClick={fetchInquiries}
            >
              Refresh
            </Button>
            {aclCheck(['create:inquiry_admin']) && (
              <Button type={'primary'} icon={<PlusOutlined />} onClick={() => setInquirySubmissionOpen(true)}>
                Add Inquiry
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <Divider />

      <Table<Inquiry>
        loading={fetchingInquiries}
        className={'sm-font'}
        showSorterTooltip={false}
        dataSource={inquries}
        rowKey={'id'}
        size={'small'}
        columns={InquiryTableColumnsAnalyst({
          onStatusClick: onStatusClickHandler
        })}
      />
      <InquiryStatusModal
        id={selectedInquiry?.id}
        open={statusOpen}
        onCancel={onStatusModalCancelHandler}
        onUpdate={onStatusModalUpdateHandler}
      />
    </div>
  );
};

export default InquiryAdministration;
