import { Button, message, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { UploadRequestOption as RcCustomRequestOptions } from 'rc-upload/lib/interface';
import { uploadFileToS3 } from 'api/file';
import { FileUploadResult } from 'models/Files';
import { useState } from 'react';

interface Props extends UploadProps {
  onUpload: (res: FileUploadResult) => void;
  uploadLabel?: string | JSX.Element;
  buttonType?: 'default' | 'link' | 'text' | 'dashed' | 'ghost' | 'primary';
}

export const UploadFile: React.FC<Props> = ({ onUpload, uploadLabel, buttonType, ...rest }: Props) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(false);

  const beforeUploadHandler = async (file: RcFile) => {
    const isImage = file.type.startsWith('image');
    if (!isImage) {
      message.error(`${file.name} is not an image file`);
    }
    return isImage;
  };

  const customRequestHandler = async (options: RcCustomRequestOptions) => {
    const { file } = options;

    const form = new FormData();
    form.append('file', file);

    try {
      setLoading(true);
      const res = await uploadFileToS3(form);
      onUpload(res);
    } catch (e) {
      message.error('There was a problem uploading the file');
    } finally {
      setLoading(false);
    }
  };

  const props: UploadProps = {
    accept: '.png, .jpg, .jpeg, .gif, .tif',
    beforeUpload: beforeUploadHandler,
    customRequest: customRequestHandler,
    showUploadList: false
  };
  return (
    <Upload {...props} {...rest}>
      <Button loading={loading} type={buttonType} size="small" icon={<UploadOutlined />}>
        {uploadLabel || 'Upload'}
      </Button>
    </Upload>
  );
};
