const STORAGE = localStorage;
const TOKEN = 'npi-token';

class Storage {
  static setToken(token: string) {
    STORAGE.setItem(TOKEN, token);
  }

  static removeToken() {
    STORAGE.removeItem(TOKEN);
  }

  static getToken() {
    return STORAGE.getItem(TOKEN);
  }

  static set(name: string, value: string) {
    STORAGE.setItem(name, value);
  }

  static get(name: string) {
    return STORAGE.getItem(name);
  }

  static remove(name: string) {
    STORAGE.removeItem(name);
  }

  static clear() {
    STORAGE.clear();
  }
}

export { Storage };
