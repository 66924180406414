import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  ModalProps,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { insertInquiryStatus, selectInquiry } from 'sb/api/note';
import { Inquiry, InquiryStatusForm } from 'sb/models/Inquiry';
import { InquiryStatusModalColumns } from '../../columns/InquiryStatusModalColumns';
import { MessageOutlined, PlusOutlined } from '@ant-design/icons';
import { Collapse } from 'react-collapse';
import { useRecoilValue } from 'recoil';
import { allAccountUsersState } from 'recoil/atoms';
import { statusLovState } from 'recoil/selectors';

interface Props extends ModalProps {
  id?: number;
  onCancel: () => void;
  onUpdate: () => void;
}

export const InquiryStatusModal: React.FC<Props> = ({ id, onCancel, onUpdate, ...rest }: Props) => {
  const [form] = Form.useForm();

  // LOCAL STATE
  const [inquiry, setInquiry] = useState<undefined | Inquiry>(undefined);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);

  // RECOIL STATE
  const npiUsers = useRecoilValue(allAccountUsersState);
  const statusLov = useRecoilValue(statusLovState);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 14 }
  };

  const fetchInquiry = useCallback(async () => {
    if (!id) return;

    try {
      setLoading(true);
      const result: Inquiry | undefined = await selectInquiry(id);
      if (result) {
        setInquiry(result);
        const currentStatusIndex = result.note_status.length - 1;
        form.setFieldsValue({
          assigned_to: result.note_status[currentStatusIndex].users_note_status_assigned_toTousers?.auth0_user_id,
          notify: true
        });
      }
    } finally {
      setLoading(false);
    }
  }, [form, id]);

  useEffect(() => {
    if (!id) {
      setInquiry(undefined);
      return;
    }

    (async () => {
      await fetchInquiry();
    })();
  }, [fetchInquiry, form, id]);

  const onAddButtonClickHandler = () => {
    setShowTable(!showTable);
  };

  const onCancelHandler = () => {
    onCancel();
    form.resetFields();
    setShowTable(true);
  };

  const onCancelStatusHandler = () => {
    setShowTable(true);
    form.resetFields();
  };

  const onFinishHandler = async (data: InquiryStatusForm) => {
    if (!id) return;

    await insertInquiryStatus(id, data);
    await fetchInquiry();
    setShowTable(true);
    onUpdate();
  };

  return (
    <Modal
      title={inquiry?.subject}
      width={800}
      transitionName={''}
      {...rest}
      onCancel={onCancelHandler}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={'Close'}
    >
      <Row>
        <Col span={2} className={'text-center'}>
          <MessageOutlined style={{ fontSize: 28, color: '#bbb' }} />
        </Col>
        <Col span={22}>
          <Typography.Paragraph
            style={{ color: '#666' }}
            ellipsis={{
              rows: 5,
              expandable: true,
              symbol: <span style={{ fontSize: 11 }}>more</span>,
              tooltip: inquiry?.note
            }}
          >
            {inquiry?.note}
          </Typography.Paragraph>
        </Col>
      </Row>

      <Collapse isOpened={showTable}>
        <div className={'text-right mb-2'}>
          <Button icon={<PlusOutlined />} onClick={onAddButtonClickHandler}>
            Update Status
          </Button>
        </div>
        <Table
          loading={loading}
          className={'sm-font'}
          showSorterTooltip={false}
          dataSource={inquiry?.note_status}
          rowKey={'id'}
          size={'small'}
          columns={InquiryStatusModalColumns({ npiUsers })}
        />
      </Collapse>

      <Collapse isOpened={!showTable}>
        <Card size={'small'} title={'Update Status'}>
          <Form
            form={form}
            size={'large'}
            layout={'horizontal'}
            {...formItemLayout}
            initialValues={{ notify_user: true }}
            onFinish={onFinishHandler}
          >
            <Form.Item
              label={'Status'}
              name={'status_id'}
              rules={[{ required: true, message: 'A status is required' }]}
            >
              <Select
                options={statusLov}
                style={{ width: 300 }}
                fieldNames={{ label: 'name', value: 'id' }}
                placeholder={'Select Status'}
                allowClear
              />
            </Form.Item>
            <Form.Item label={'Assign To'} name={'assigned_to'}>
              <Select
                options={npiUsers}
                style={{ width: 300 }}
                placeholder={'Assign to an analyst'}
                fieldNames={{ label: 'name', value: 'user_id' }}
                showSearch
                allowClear
                optionFilterProp="name"
                optionLabelProp="name"
              />
            </Form.Item>
            <Form.Item label={'Comment'} name={'remark'}>
              <Input.TextArea rows={2} placeholder={'Provide a comment'} />
            </Form.Item>
            <Form.Item label={'Notify User'} name={'notify_user'} valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <div className={'text-right'}>
              <Space>
                <Button size={'middle'} onClick={onCancelStatusHandler}>
                  Cancel
                </Button>
                <Button size={'middle'} type={'primary'} htmlType={'submit'}>
                  Save
                </Button>
              </Space>
            </div>
          </Form>
        </Card>
      </Collapse>
    </Modal>
  );
};
