import React from 'react';
import { Button, Col, DatePicker, Form, Input, Modal, ModalProps, Popconfirm, Row, Select } from 'antd';
import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { useDealHelper } from '../../../hooks/useDealHelper';
import { Auth0User } from '@npi-digital/shared';
import { useAccountUsers } from '../../../sb/api/extra';
import moment from 'moment-timezone';
import { EditTasksPayload } from 'types/Contract';
import { profileSwitchState } from 'recoil/atoms';
import { useRecoilValue } from 'recoil';

const { RangePicker } = DatePicker;

interface Props extends ModalProps {
  handleEditTask: (dealId: number, taskId: number, taskData: any) => void;
  handleCancel: () => void;
  handleDeleteTask: () => void;
  hideDelete?: boolean;
}

export const EditTaskModal: React.FC<Props> = ({
  handleEditTask,
  handleCancel,
  handleDeleteTask,
  hideDelete,
  ...rest
}: Props) => {
  const { dealIdToEditTask } = useDealHelper();

  // RECOIL STATE
  const profileSwitch = useRecoilValue(profileSwitchState);

  const [form] = Form.useForm();

  const { data: users } = useAccountUsers(profileSwitch ? profileSwitch.id : undefined);

  const handleSubmit = (values: any) => {
    const payload = {
      ...values,
      startDate: moment(values?.dateRange[0]),
      endDate: moment(values?.dateRange[1])
    };
    delete payload?.dateRange;
    return handleEditTask(dealIdToEditTask?.dealId || 0, dealIdToEditTask?.taskId || 0, payload);
  };

  // const { name, note, assignments, startDate, endDate } = dealIdToEditTask?.task as EditTasksPayload;
  const { name, note, assignments, startDate, endDate } = dealIdToEditTask?.task as EditTasksPayload;
  const taskInitialValues = {
    name,
    note,
    assignments,
    dateRange: [moment(startDate), moment(endDate)]
  };

  return (
    <Modal
      title="Edit Tasks"
      closeIcon={<CloseOutlined onClick={handleCancel} />}
      width={600}
      open={!!dealIdToEditTask?.dealId}
      footer={[
        <Row justify="space-between" align="middle" key="dealId">
          <Col>
            {!hideDelete && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => handleDeleteTask()}
                okText="Delete"
                cancelText="Cancel"
                placement={'topLeft'}
              >
                <Button type="link" danger>
                  <DeleteOutlined className="mr-1" />
                  Delete Task
                </Button>
              </Popconfirm>
            )}
          </Col>
          <Col>
            <Button type="link" onClick={() => handleCancel()} key={'cancel'}>
              Cancel
            </Button>
            <Button type="primary" onClick={form.submit} key={'submit'}>
              Save Changes
            </Button>
          </Col>
        </Row>
      ]}
      {...rest}
    >
      <Form form={form} onFinish={handleSubmit} initialValues={taskInitialValues} labelCol={{ span: 5 }}>
        <Form.Item
          label="Task Name"
          name={'name'}
          key={'name'}
          rules={[
            {
              validator: (_, value) => {
                if (value && value.trim()) {
                  return Promise.resolve();
                }
                return Promise.reject('Please enter task name');
              }
            }
          ]}
        >
          <Input size={'large'} className="rounded-md" />
        </Form.Item>
        <Form.Item label="Description" name={'note'} key={'note'}>
          <Input.TextArea size={'large'} className="rounded-md" />
        </Form.Item>
        <Form.Item label="Assigned To" name={'assignments'} key={'assignments'}>
          <Select
            size={'large'}
            mode={'multiple'}
            options={users?.map((u: Auth0User) => ({
              label: u.name,
              value: u.user_id
            }))}
            placeholder={'Assign to'}
            filterOption={(input, option) =>
              option && typeof option.label === 'string'
                ? (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                : false
            }
            allowClear
            maxTagCount={2}
            dropdownRender={(menu) => <>{menu}</>}
          />
        </Form.Item>
        <Form.Item
          label="Date Range"
          name="dateRange"
          rules={[{ required: true, message: 'Please enter the start and end date' }]}
        >
          <RangePicker className="w-full" size="large" format="MM/DD/YYYY" />
        </Form.Item>
      </Form>
    </Modal>
  );
};
