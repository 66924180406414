import { useState, useCallback } from 'react';
import { Form, Drawer, List, Input, Button, Row, Col } from 'antd';
import { SupplierCategory } from 'sb/models/DealDynamicsSupplier';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { UnmountClosed } from 'react-collapse';
import { createSupplierCategory, updateSupplierCategory } from 'sb/api/vendor';

interface Props {
  categories?: SupplierCategory[];
  supplierName?: string;
  supplierId?: number;
  visible: boolean;
  onClose: () => void;
  onCategoryUpdate: (category: SupplierCategory) => void;
  onCategoryCreate: (category: SupplierCategory) => void;
}

export const DealProductDrawer: React.FC<Props> = ({
  visible,
  onClose,
  onCategoryUpdate,
  onCategoryCreate,
  supplierName,
  supplierId,
  categories
}) => {
  const [form] = Form.useForm();
  const [openForm, setOpenForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedProductCategory, setSelectedProductCategory] = useState<SupplierCategory>();
  const onClickEdit = useCallback(
    (category: SupplierCategory) => {
      setOpenForm(true);
      form.setFieldsValue({ category: category.category });
      setSelectedProductCategory(category);
    },
    [form]
  );

  const onClickSubmit = useCallback(async () => {
    setLoading(true);
    if (supplierId && selectedProductCategory) {
      await updateSupplierCategory(supplierId, selectedProductCategory.id, {
        category: form.getFieldValue('category')
      });
      onCategoryUpdate({ ...selectedProductCategory, category: form.getFieldValue('category') });
    } else {
      if (supplierId) {
        const result = await createSupplierCategory(supplierId, {
          category: form.getFieldValue('category')
        });
        onCategoryCreate(result);
      }
    }
    setLoading(false);
    setOpenForm(false);
  }, [form, supplierId, selectedProductCategory, onCategoryCreate, onCategoryUpdate]);

  const onClickAdd = useCallback(async () => {
    setOpenForm(true);
    form.setFieldsValue({ category: '' });
    setSelectedProductCategory(undefined);
  }, [form]);

  const handleCancel = () => {
    setOpenForm(false);
  };

  const handleDrawerClose = () => {
    setOpenForm(false);
    onClose();
  };

  return (
    <Drawer title="Product Categories" width={780} open={visible} onClose={handleDrawerClose}>
      <UnmountClosed isOpened={!openForm}>
        <Row justify="end" gutter={[0, 8]}>
          <Col>
            <Button icon={<PlusOutlined />} onClick={onClickAdd}>
              Add
            </Button>
          </Col>
          <Col span={24}>
            <List
              header={supplierName}
              bordered
              dataSource={categories}
              renderItem={(item: SupplierCategory) => (
                <List.Item actions={[<EditOutlined key="edit" onClick={() => onClickEdit(item)} />]}>
                  {item.category}
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </UnmountClosed>

      <UnmountClosed isOpened={openForm}>
        <Form
          layout={'vertical'}
          form={form}
          style={{
            maxWidth: 600
          }}
        >
          <Form.Item name={'category'} label="Category">
            <Input placeholder="Type category" />
          </Form.Item>
          <Form.Item>
            <Row>
              <Button onClick={handleCancel} type="default" className="mr-2">
                Cancel
              </Button>
              <Button loading={loading} onClick={onClickSubmit} type="primary">
                {selectedProductCategory ? 'Update' : 'Submit'}
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </UnmountClosed>
    </Drawer>
  );
};
