import { useEffect, useMemo } from 'react';
import { useDealHelper } from 'hooks/useDealHelper';
import { Button, Divider, Form, Input, InputNumber, Modal, Select, Space, Typography } from 'antd';
import { useAccountUsers } from 'sb/api/extra';
import { Auth0User } from '@npi-digital/shared';
import { Task } from 'types/Contract';
import { profileSwitchState } from 'recoil/atoms';
import { useRecoilValue } from 'recoil';

type HandleDealSubmit = (
  dealId: number,
  formValues: {
    tasks: Task[];
  }
) => void;

interface AddDealTaskFormProps {
  handleDealSubmit: HandleDealSubmit;
  handleCancel: () => void;
}

const AddDealTaskForm: React.FC<AddDealTaskFormProps> = ({ handleCancel, handleDealSubmit }): React.ReactElement => {
  const { dealIdToAddTasks, getDefaultTasksForCategory } = useDealHelper();
  const [form] = Form.useForm();
  const defaultTasks = useMemo(
    () => getDefaultTasksForCategory(dealIdToAddTasks?.categoryId || 0),
    [dealIdToAddTasks?.categoryId, getDefaultTasksForCategory]
  );

  // RECOIL STATE
  const profileSwitch = useRecoilValue(profileSwitchState);

  const { data: users } = useAccountUsers(profileSwitch ? profileSwitch.id : undefined);

  const tasks = Form.useWatch('tasks', form);

  useEffect(() => {
    return () => {
      form.resetFields(['tasks']);
    };
  }, [form]);

  const formInitialValues = useMemo(() => {
    if (!defaultTasks?.length) {
      return {
        tasks: []
      };
    }
    return {
      tasks: defaultTasks?.map((task) => ({
        name: task.name,
        duration: task.duration,
        assignments: task.assignments,
        categoryId: dealIdToAddTasks?.categoryId
      }))
    };
  }, [defaultTasks, dealIdToAddTasks?.categoryId]);

  const handleSubmit = (v: { tasks: Task[] }) => {
    return handleDealSubmit(dealIdToAddTasks?.dealId || 0, v);
  };

  return (
    <div>
      <Modal
        width={840}
        title={<h5 className="font-bold pt-1 mb-0">Add Recommended Tasks</h5>}
        open={!!dealIdToAddTasks?.dealId}
        cancelText={'Cancel'}
        okText={'Save'}
        onCancel={() => {
          form.resetFields(['tasks']);
          handleCancel();
        }}
        onOk={form.submit}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Form.List name={'tasks'} initialValue={formInitialValues.tasks}>
            {(fields, { add, remove }) => (
              <div>
                <div className="flex gap-3 -mt-4 items-end mb-2">
                  <Typography.Title level={5} className="p-0 w-1/2">
                    Task Name
                  </Typography.Title>
                  <Typography.Title level={5} className="p-0 w-[88px]">
                    Duration <div className="text-xs opacity-50">(In days)</div>
                  </Typography.Title>
                  <Typography.Title level={5} className="p-0 w-1/4 flex justify-between items-end">
                    <span>Assignments</span>
                    <Button
                      className="h-full p-0"
                      type="link"
                      onClick={() => {
                        //   clear all assignments
                        form.setFieldsValue({
                          tasks: tasks?.map((t: Task) => ({
                            ...t,
                            assignments: []
                          }))
                        });
                      }}
                    >
                      Clear All
                    </Button>
                  </Typography.Title>
                </div>
                {fields.map((field, index) => {
                  return (
                    <div key={field.key} className="flex gap-4">
                      <Form.Item
                        className="w-1/2"
                        {...field}
                        name={[field.name, 'name']}
                        key={`name_${index}`}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (value && value.trim()) {
                                return Promise.resolve();
                              }
                              return Promise.reject('Please enter task name');
                            }
                          }
                        ]}
                      >
                        <Input size={'large'} className="rounded-md" />
                      </Form.Item>
                      <Form.Item
                        className="w-[88px]"
                        {...field}
                        name={[field.name, 'duration']}
                        key={`duration_${index}`}
                        rules={[{ required: true, message: 'Please add duration' }]}
                      >
                        <InputNumber size={'large'} />
                      </Form.Item>
                      <Form.Item
                        className="w-1/4"
                        {...field}
                        name={[field.name, 'assignments']}
                        key={`assignments_${index}`}
                      >
                        <Select
                          size={'large'}
                          mode={'multiple'}
                          options={users?.map((u: Auth0User) => ({
                            label: u.name,
                            value: u.user_id
                          }))}
                          placeholder={'Assign to'}
                          allowClear
                          maxTagCount={1}
                          filterOption={(input, option) =>
                            option && typeof option.label === 'string'
                              ? (option.label ?? '').toLowerCase().includes(input.toLowerCase())
                              : false
                          }
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <Divider style={{ margin: '8px 0' }} />
                              <Space style={{ padding: '0 8px 4px' }}>
                                <Button
                                  type="link"
                                  onClick={() => {
                                    //   get the assignments value of the current index and set the same to all other indexes if checked, else reset all other indices
                                    const currentAssignments = tasks?.[index]?.assignments || [];
                                    form.setFieldsValue({
                                      tasks: tasks?.map((t: Task, i: number) => {
                                        if (i !== index) {
                                          return {
                                            ...t,
                                            assignments: currentAssignments
                                          };
                                        } else {
                                          return t;
                                        }
                                      })
                                    });
                                  }}
                                  className={'text-wrap'}
                                >
                                  Assign all tasks to the selected user(s)
                                </Button>
                              </Space>
                            </>
                          )}
                        />
                      </Form.Item>
                      <Button className="h-full p-2" type={'dashed'} onClick={() => remove(index)}>
                        Remove
                      </Button>
                    </div>
                  );
                })}
                <Button type={'dashed'} onClick={() => add()}>
                  Add custom task
                </Button>
              </div>
            )}
          </Form.List>
        </Form>
      </Modal>
    </div>
  );
};

export default AddDealTaskForm;
