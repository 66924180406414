import { useEffect, useState } from 'react';
import { Button, Card, CardProps, Checkbox, Col, Form, Input, Row, Space, Table } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';

import { Permission, AllPermissionMethods, PermissionMethods } from 'sb/models/Permission';
import { permissionState } from 'recoil/atoms';
import { PERMISSION_ROLE_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { Role, RoleUpdateData } from 'sb/models/Role';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

interface Props extends Omit<CardProps, 'onSubmit'> {
  data?: Role;
  onSubmit?: (data: RoleUpdateData) => void;
  onClose?: () => void;
}

export const RoleCard: React.FC<Props> = ({ loading, data, onSubmit, onClose, ...rest }) => {
  const [form] = Form.useForm();
  const permissions = useRecoilValue(permissionState);
  const [selectedPermissionsIds, setSelectedPermissionsIds] = useState<number[]>([]);

  useEffect(() => {
    form.setFieldsValue(data || { name: '', description: '', is_client_role: true });

    const _selectedPermissionIds: any = [];
    data?.permissions?.forEach((permission: any) => {
      Object.keys(PermissionMethods).forEach((method) => {
        if (typeof permission[method] === 'number' && permission[method] > 0) {
          _selectedPermissionIds.push(Number(permission[method]));
        }
      });
    });
    setSelectedPermissionsIds(_selectedPermissionIds);
  }, [form, data]);

  const handleFinish = (values: Omit<RoleUpdateData, 'scopes'>) => {
    if (selectedPermissionsIds.length > 0) onSubmit?.({ ...values, permissions: selectedPermissionsIds });
  };

  const handlePermissionChange = (e: CheckboxChangeEvent, permission: number) => {
    if (e.target.checked) {
      setSelectedPermissionsIds([...selectedPermissionsIds, permission]);
    } else {
      setSelectedPermissionsIds(selectedPermissionsIds.filter((id) => id !== permission));
    }
  };

  const handleRowSelection = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const _selectedPermissionIds: any = [];
      permissions?.forEach((permission: any) => {
        Object.keys(PermissionMethods).forEach((method) => {
          if (typeof permission[method] === 'number' && permission[method] > 0) {
            _selectedPermissionIds.push(Number(permission[method]));
          }
        });
      });
      setSelectedPermissionsIds(_selectedPermissionIds);
    } else {
      setSelectedPermissionsIds([]);
    }
  };
  const layout = {
    labelCol: {
      span: 2
    },
    wrapperCol: {
      span: 22
    }
  };
  return (
    <Card
      {...rest}
      type="inner"
      size="small"
      extra={<Button type="text" size="small" icon={<CloseOutlined />} onClick={onClose} />}
    >
      <Form {...layout} form={form} onFinish={handleFinish}>
        <Row gutter={20} align="middle">
          <Col span={16}>
            <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required!' }]}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={16}>
            <Form.Item label="Description" name="description">
              <Input />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="is_client_role" valuePropName="checked">
              <Checkbox style={{ marginLeft: 85 }}>Is Client Role?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Table<Permission>
            size="small"
            rowKey="name"
            dataSource={permissions}
            pagination={PERMISSION_ROLE_TABLE_PAGINATION_CONFIG}
          >
            <Table.Column<Permission> title="Scope" dataIndex="name" />
            <Table.Column<Permission>
              key="method"
              title={() => (
                <div>
                  <Checkbox onChange={handleRowSelection} /> Method
                </div>
              )}
              render={(_, record) =>
                AllPermissionMethods.map((method) =>
                  record[method] ? (
                    <Checkbox
                      checked={selectedPermissionsIds.includes(record[method])}
                      key={method}
                      onChange={(e) => handlePermissionChange(e, record[method])}
                    >
                      {method}
                    </Checkbox>
                  ) : null
                )
              }
            />
          </Table>
        </Form.Item>
        <Form.Item className="text-right">
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Card>
  );
};
