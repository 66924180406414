import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler, ErrorMessage } from 'shared/Helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { SfDeal, UploadDealDocumentResponse } from 'models/Deal';

export interface Contact {
  Id: string;
  Name: string;
}

/**
 * Get list of accounts that matches string
 *
 * @param {string} str
 * @returns array of accounts
 */
export const searchSalesforceAccounts = async (str: string) => {
  try {
    const res = await axios.post('/secure/sf/accounts/search', {
      str
    });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get account
 *
 * @param {number} id
 * @returns account object
 */
export const getSalesforceAccount = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/account/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get list of deals for account
 *
 * @param {string} id
 * @param {string} type
 * @returns array of deals
 */
export const getAccountDeals = async (id: string, type: string) => {
  try {
    const res = await axios.get(`/secure/sf/${type === 'client' ? 'account' : 'vendor'}/${id}/deals`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const getDeal = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/deal/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get contacts for the account
 *
 * @param {string} id
 */
export const getAccountContacts = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/account/${id}/contacts`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get account
 *
 * @param {string} account_id
 * @returns account object
 */
export const getClientBySalesforceAccount = async (account_id: string) => {
  try {
    const res = await axios.get(`/secure/account/${account_id}/client`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetches the subscription deals from the server.
 *
 * @async
 * @param {number} [profile_id] - The unique identifier of the user's profile.
 * @param {string} [contract] - The desired type of contract.
 * @param {boolean} [includeAll] - Whether to include all deals or not.
 * @returns {Promise<any>} - A Promise that resolves to the retrieved subscription deals data, or rejects with an error message.
 */
export const getSubscriptionDeals = async (
  profile_id?: number,
  contract?: string,
  includeAll?: boolean
): Promise<any> => {
  try {
    const res = await axios.get('/api/deals', {
      params: { profile_id, contract, includeAll }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const useSubscriptionDeals = (profile_id?: number, contract?: string): UseQueryResult<SfDeal[], AxiosError> =>
  useQuery({
    queryKey: ['deals', profile_id, contract],
    queryFn: () => getSubscriptionDeals(profile_id, contract)
  });

export const getSfAccountContacts = async (profile_id?: number) => {
  try {
    const res = await axios.get('/api/account/contacts', {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const submitDeal = async (formData: any, profile_id?: number) => {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/api/deal/submit`, formData, {
      transformRequest: () => formData,
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const uploadDealDocuments = async (
  formData: any,
  deal_id: string
): Promise<UploadDealDocumentResponse[] | undefined> => {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/api/deal/${deal_id}/files/upload`, formData, {
      transformRequest: () => formData
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Unlocks deal documents for client access.
 *
 * @param {string} latestPublishedVersionId - The latest published version ID.
 * @param {boolean} release - Specifies whether to release the documents.
 * @return {Promise<any>} - A promise that resolves to the unlocked deal documents if successful, or rejects with an error message.
 *
 * @throws {Error} - If the request fails or the response status is not "success".
 *
 * @see AxiosResponse
 * @see axios.patch
 * @see Promise.reject
 * @see AxiosErrorHandler
 */
export const unlockDealDocument = async (latestPublishedVersionId: string, release: boolean): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.patch(`/api/document/${latestPublishedVersionId}/client_access`, {
      release
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Updates the `is_fmv` property of a deal document.
 *
 * @param {string} latestPublishedVersionId - The ID of the latest published version of the document.
 * @param {boolean} is_fmv - The new value for the `is_fmv` property.
 * @param {string} deal_id - Salesforce Deal ID for the selected deal.
 * @returns {Promise<any>} - A promise that resolves to the updated document data if the update is successful, or rejects with an error message if it fails.
 *
 * @throws {Error} - If the API call fails or returns an error message.
 */
export const updateDealDocumentIsFmv = async (
  latestPublishedVersionId: string,
  is_fmv: boolean,
  deal_id: string
): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.patch(`/api/document/${latestPublishedVersionId}/is_fmv`, {
      is_fmv,
      deal_id
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetches Salesforce documents for a given salesforce_id.
 *
 * @param {string} salesforce_id - The salesforce_id for which to fetch documents.
 * @returns {Promise<any>} - A promise that resolves with the fetched documents, or rejects with an error.
 */
export const fetchSalesforceDocs = async (salesforce_id: string): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`api/deal/${salesforce_id}/doc_links`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(ErrorMessage(res));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Retrieves the open delivery deals from the API.
 *
 * @async
 * @returns {Promise<any>} A Promise that resolves with the open delivery deals data.
 * @throws {Error} An error will be thrown if the API returns a non-success status or an error occurs during the API call.
 */
export const getDeliveryOpenDeals = async (): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`/api/analysts/deals`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetches vendor deals by delivery based on the provided vendor ID.
 *
 * @param {number | string} vendor_id - The unique identifier of the vendor.
 * @returns {Promise<any>} A promise that resolves to the vendor deals data if the request is successful.
 * @throws Will reject the promise with an error message if the request fails or if the response status is not 'success'.
 */
export const getVendorDealsByDelivery = async (vendor_id: number | string): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`/api/supplier/${vendor_id}/deals`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Updates the analyst assigned to a deal.
 *
 * @param {string} Id - The ID of the deal.
 * @param {number | undefined} contract_id - The ID of the contract.
 * @param {string | null} analyst_id - The ID of the analyst or null if no analyst is assigned.
 * @returns {Promise<any>} - A promise that resolves with the updated deal data if the update is successful, or rejects with an error message if the update fails.
 */
export const updateDealAnalyst = async (
  Id: string,
  contract_id: number | undefined,
  analyst_id: string | null
): Promise<any> => {
  try {
    const res: AxiosResponse<any, any> = await axios.patch(`api/deal/${Id}/analyst`, { contract_id, analyst_id });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
