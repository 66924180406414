import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { Role } from '../types/global';

/**
 * Update Client Logo filename field
 *
 * @param client_id
 * @param logo_filename
 */
export const updateClientLogoFilename = async (client_id: number, logo_filename: string) => {
  try {
    const res = await axios.patch(`/secure/client/${client_id}/logoFilename`, { logo_filename });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Select client access roles
 */
export const fetchClientUserRoles = async (): Promise<Role[] | undefined> => {
  try {
    const url = `/secure/client/user/roles`;
    const { data } = await axios.get(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
