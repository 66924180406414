import React from 'react';
import { Divider } from 'antd';
import './DealCreationSuccess.scss';
import { ViewDeal } from './ViewDeal';
import { AddAnotherPurchase } from './AddAnotherPurchase';
import { AddDealTasks } from './AddDealTasks';

interface DealActionsProps {
  contract_id: number | null;
}

export const DealActions = (props: DealActionsProps) => {
  const { contract_id } = props;

  if (contract_id) {
    return (
      <>
        <div className="card-content-1">
          <ViewDeal contract_id={contract_id} />
        </div>
        <div className="card-content-2">
          <AddDealTasks contract_id={contract_id} />
          <Divider type="vertical" />
          <AddAnotherPurchase />
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="card-content-1">
          <AddAnotherPurchase />
        </div>
      </>
    );
  }
};
