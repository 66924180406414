import { useMemo } from 'react';
import { Button, Table, TableColumnsType, TableProps, Tooltip, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { ACLCheck } from 'shared/Helpers';
import { LinkedProduct } from 'components/molecules/LinkedProducts';

interface Props extends TableProps<LinkedProduct> {
  products: Array<LinkedProduct>;
  productId?: number;
  onProductRemove: (rec: LinkedProduct) => void;
}

export const LinkedProductsTable: React.FC<Props> = ({ productId, products, onProductRemove, ...rest }) => {
  const columns: TableColumnsType<LinkedProduct> = useMemo(
    () => [
      {
        title: 'Supplier',
        dataIndex: 'supplier_name',
        render: (_, rec: LinkedProduct) => rec.vendor.name
      },
      {
        title: 'Product',
        dataIndex: 'name',
        render: (_, rec: LinkedProduct) => (
          <Typography.Text style={{ width: 500 }} ellipsis={{ tooltip: rec.name }}>
            {rec.name}
          </Typography.Text>
        )
      },
      {
        title: 'Product Code',
        dataIndex: 'code'
      },
      {
        title: undefined,
        dataIndex: 'action',
        render: (_, rec: LinkedProduct) => {
          if (ACLCheck(['update:contract_all']) && rec.id !== productId) {
            return (
              <Tooltip title="Unlink product">
                <Button size="small" type="text" icon={<DeleteOutlined />} onClick={() => onProductRemove(rec)} />
              </Tooltip>
            );
          } else {
            return '';
          }
        }
      }
    ],
    [onProductRemove, productId]
  );

  return (
    <Table<LinkedProduct>
      {...rest}
      className="line-items"
      rowKey="id"
      size="small"
      columns={columns}
      dataSource={products}
    />
  );
};
