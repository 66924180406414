import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { useQuery } from '@tanstack/react-query';
import { SalesforceAccount } from 'components/atoms/SalesforceAccountSearch';

/**
 * Get list of accounts that matches string
 *
 * @param {string} str
 * @returns array of accounts
 */
export const searchSalesforceAccounts = async (str: string): Promise<SalesforceAccount[]> => {
  try {
    const res = await axios.post('/secure/sf/accounts/search', {
      str
    });

    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useSalesforceAccounts = (searchTerm: string, config = {}) =>
  useQuery({
    queryKey: ['salesforceAccounts', searchTerm],
    queryFn: async () => await searchSalesforceAccounts(searchTerm),
    staleTime: 1000 * 60 * 60,
    ...config
  });

/**
 * Get account
 *
 * @param {number} id
 * @returns account object
 */
export const getSalesforceAccount = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/account/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get list of deals for account
 *
 * @param {string} id
 * @param {string} type
 * @param {boolean} intersection
 * @returns array of deals
 */
export const getAccountDeals = async (id: string, type: string, intersection?: boolean) => {
  let query = '';
  if (intersection) {
    query = '?intersection=1';
  }
  try {
    const res = await axios.get(`/secure/sf/${type === 'client' ? 'account' : 'vendor'}/${id}/deals${query}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const getDeal = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/deal/${id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get contacts for the account
 *
 * @param {string} id
 * @returns {Array<any>}
 */
export const getAccountContacts = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/account/${id}/contacts`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const getDealDetail = async (id: string) => {
  try {
    const res = await axios.get(`/secure/sf/deal/${id}/detail`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Retrieve client names from array of Salesforce Ids
 *
 * @param salesforceIds
 */
export const getClientNames = async (salesforceIds: Array<string>) => {
  try {
    const res = await axios.post(`/secure/sf/account_names`, { ids: salesforceIds });
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
