import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { AcodisAccuracy } from 'sb/models/Acodis';

export async function getAcodisAccuracies() {
  try {
    const res = await axios.get(`/secure/acodis-accuracies`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getAcodisAccuracyByVendorAndReseller(vendor_id: number, reseller_id: number) {
  try {
    const res = await axios.post(`/secure/acodis-accuracy`, { reseller_id, vendor_id });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function createAcodisAccuracy(values: Pick<AcodisAccuracy, 'reseller_id' | 'vendor_id' | 'accuracy'>) {
  try {
    const res = await axios.post(`/secure/acodis-accuracies`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function updateAcodisAccuracy(
  id: number,
  values: Pick<AcodisAccuracy, 'reseller_id' | 'vendor_id' | 'accuracy'>
) {
  try {
    const res = await axios.patch(`/secure/acodis-accuracies/${id}`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteAcodisAccuracy(id: number) {
  try {
    const res = await axios.delete(`/secure/acodis-accuracies/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getAcodisExtractionAccuracies() {
  try {
    const res = await axios.get(`/secure/acodis-extraction/accuracies`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getAcodisExtractionFeedbacks() {
  try {
    const res = await axios.get(`/secure/acodis-extraction/feedbacks`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getAcodisExtractionRating(acodisResultId: number) {
  try {
    const res = await axios.get(`/secure/acodis-extraction/${acodisResultId}/rating`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function setAcodisExtractionRating(
  acodisTransactionId: number,
  values: { accuracy: number; feedbacks?: number[] }
) {
  try {
    const res = await axios.post(`/secure/acodis-extraction/${acodisTransactionId}/rating`, values);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    throw new Error(res.data?.message);
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
