import React, { FC, useState, useEffect, createRef } from 'react';
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Select,
  Button,
  Checkbox,
  Card,
  notification,
  InputRef,
  message,
  ModalProps
} from 'antd';
import {
  ArrowRightOutlined,
  PlusOutlined,
  CloseOutlined,
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseSquareFilled,
  PlusSquareFilled
} from '@ant-design/icons';
import { insertClientUser, insertSalesforceUser, insertUser, updateClientUser, updateUser } from 'api/admin';
import { useRecoilValue } from 'recoil';
import { selectedClientState } from 'recoil/atoms';
import { isString } from 'lodash-es';
import { Auth0User, Role } from 'types/global';

interface AddUserModalProps extends ModalProps {
  onSubmit: (values: any) => void;
  roles: Role[];
  initialValues?: any;
  user?: Auth0User;
  availableSeats: number;
  siteAdmin?: boolean;
  emailDomains?: string[];
}

type AddUserFormProps = {
  given_name: string;
  family_name: string;
  email: string;
  email_name: string;
  email_domain: string;
  title: string;
  phone: string;
  active: boolean;
  role_ids: Array<number>;
  selected_role: string | undefined;
};

export const AddUserModal: FC<AddUserModalProps> = ({
  roles,
  user,
  availableSeats,
  onSubmit,
  emailDomains,
  siteAdmin,
  ...rest
}) => {
  const [form] = Form.useForm<AddUserFormProps>();
  const firstNameRef = createRef<InputRef>();
  const [addedRoles, setAddedRoles] = useState<Role[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [blockedButtonDisabled, _setBlockButtonDisabled] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const selectedClient = useRecoilValue(selectedClientState);
  const inputWidth = 320;

  const handleFinish = async (values: any) => {
    // setShowError(true);

    /**
     * The identifier of the Salesforce account associated with the selected client.
     * This variable holds the `key` property of the `selectedClient` object.
     * It is typically used to reference and interact with a specific account
     * in Salesforce within the application.
     * If `selectedClient` is undefined or null, `sfAccountId` will be undefined.
     *
     * mhall: changed from the user's client.salesforce_id
     *
     * @type {string | undefined}
     */
    const sfAccountId: string | undefined | number = selectedClient?.key;
    if (!isString(sfAccountId)) return;
    setSaving(true);
    values.role_ids = addedRoles.map((item) => item.id);
    values.blocked = !values.active;
    values.email = `${values.email_name}@${values.email_domain}`;
    delete values.active;

    let res: any;
    if (user) {
      res = siteAdmin
        ? await updateUser(sfAccountId, user?.auth0_user_id, values)
        : await updateClientUser(user?.auth0_user_id, values);
    } else {
      values.provision_salesforce_user = false;
      res = siteAdmin ? await insertUser(sfAccountId, values) : await insertClientUser(values);
      if (res?.status == 'success' && res.provision_salesforce_user !== false) {
        const sfRes = await insertSalesforceUserRecord(res);
        if (sfRes?.status === 'error') {
          notification.error({
            description: 'There was a problem provisioning the user in Salesforce. Please contact support.',
            message: 'Salesforce Provisioning Problem',
            duration: 8
          });
          setSaving(false);
          return;
        }
      } else if (!res) {
        setSaving(false);
        return;
      }
    }

    if (res?.status === 'error') {
      notification.error({
        description: 'There was a problem adding/updating a user. Please contact support.',
        message: 'Account Creation/Update Problem',
        duration: 8
      });
      setSaving(false);
    } else {
      setSaving(false);
      onSubmit(values);
    }
  };

  const insertSalesforceUserRecord = async (vals: Auth0User) => {
    if (!selectedClient) return;
    let res: any;

    const workingModal = Modal.info({
      title: 'Provisioning Account',
      content: `Provisioning the new account in the NPI Portal.  This may take a few seconds, so thanks in advance for your patience.`,
      closable: false,
      keyboard: false,
      maskClosable: false,
      onCancel: undefined,
      okText: 'Provisioning Account',
      okButtonProps: {
        disabled: true,
        loading: true
      }
    });

    if (typeof selectedClient.key === 'string') {
      res = await insertSalesforceUser({
        Firstname: vals.first_name,
        LastName: vals.last_name,
        Email: vals.email,
        AccountId: selectedClient.key,
        Title: vals.title
      });

      workingModal.destroy();
      if (res.status === 'success') {
        message.success('User account provisioned successfully!');
      } else {
        message.error('There was a probably provisioning this user account. Please contact support!');
      }
    }
    return res;
  };

  useEffect(() => {
    if (!rest.open) return;
    setShowError(false);
    form.resetFields();
    if (!user) {
      setAddedRoles([]);

      form.setFieldsValue({
        given_name: '',
        family_name: '',
        email: '',
        email_name: '',
        title: '',
        phone: '',
        active: !!availableSeats,
        send_reset_password: false,
        email_domain: Array.isArray(emailDomains) && emailDomains.length === 1 ? emailDomains[0] : undefined,
        role_ids: [],
        selected_role: undefined
      } as AddUserFormProps);
      firstNameRef.current?.focus();
    } else {
      const roleIds = user.user_role?.map(({ role }) => role?.id);
      const [email_name, email_domain] = user.email?.split('@') || [];
      const roles: Role[] = user.user_role?.map(({ role }) => role).filter((item): item is Role => !!item) || [];
      setAddedRoles(roles);

      form.setFieldsValue({
        given_name: user.first_name,
        family_name: user.last_name,
        email: user.email,
        email_name: email_name,
        email_domain: email_domain,
        title: user.title || '',
        phone: user.phone || '',
        active: !user.blocked,
        role_ids: roleIds,
        selected_role: undefined
      } as AddUserFormProps);
      firstNameRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest.open, user, form, availableSeats]);

  // useEffect(() => {
  //   if (availableSeats === 0) {
  //     setBlockButtonDisabled(user ? user.blocked === true : true);
  //   } else {
  //     setBlockButtonDisabled(false);
  //   }
  // }, [user, availableSeats]);

  const handleRoleChange = (value: string) => {
    console.log('value ', value);
  };

  const handleAddRole = () => {
    const selectedRole = form.getFieldValue('selected_role');
    const roleToAdd = roles.find((role) => role.id == Number(selectedRole));
    roleToAdd && setAddedRoles([...addedRoles, roleToAdd]);
    form.setFieldValue('selected_role', undefined);
    showError && form.validateFields();
  };

  const handleRemoveRole = (roleId: number) => {
    setAddedRoles(addedRoles.filter((role) => role.id !== roleId));
  };

  const handleViewRoles = () => {
    setIsExpanded(true);
  };

  const handleHideRoles = () => {
    setIsExpanded(false);
  };

  const onBlurInput = (fieldName: string) => {
    let fieldValue = form.getFieldValue(fieldName);
    fieldValue = (fieldValue && fieldValue.trim()) || '';
    form.setFieldValue(fieldName, fieldValue);
  };

  const filteredRoles = roles.filter((role) => !addedRoles.some((addedRole) => addedRole.id === role.id));
  return (
    <Modal
      title={user ? 'Update User Details' : 'Add User Details'}
      open={rest.open}
      width={1200}
      className={'add-user-modal'}
      {...rest}
      footer={[
        <Button key="cancel" onClick={rest.onCancel} disabled={saving}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={saving}
          onClick={() => {
            setShowError(true);
            form.submit();
          }}
          disabled={saving}
        >
          {user ? 'Update user' : 'Add User'}
        </Button>
      ]}
    >
      <Row gutter={16}>
        <Col span={isExpanded ? 12 : 24}>
          <Form
            form={form}
            labelAlign="left"
            size="large"
            layout="horizontal"
            colon={false}
            labelWrap
            onFinish={handleFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
          >
            <Form.Item label="First Name" required>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="given_name"
                    rules={[{ required: true, message: 'Please input first name!' }]}
                    required
                  >
                    <Input placeholder="First name" className="rounded-md" onBlur={() => onBlurInput('given_name')} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="family_name"
                    rules={[{ required: true, message: 'Please input last name!' }]}
                    required
                  >
                    <Input placeholder="Last name" className="rounded-md" onBlur={() => onBlurInput('family_name')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item label="Title" name="title">
              <Input placeholder="Job Title" className="rounded-md" onBlur={() => onBlurInput('title')} />
            </Form.Item>

            <Form.Item
              label="Email"
              name="email_name"
              rules={[
                {
                  validator: () => {
                    if (showError) {
                      if (!form.getFieldValue('email_name')) {
                        return Promise.reject(new Error('Please input email!'));
                      } else if (!form.getFieldValue('email_domain')) {
                        return Promise.reject(new Error('Please select email domain!'));
                      }
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              required
            >
              <Input
                placeholder="User Name"
                disabled={!!user}
                style={{ width: inputWidth }}
                onBlur={() => onBlurInput('email_name')}
                addonAfter={
                  <Form.Item name="email_domain" required noStyle>
                    <Select
                      style={{ width: 190 }}
                      placeholder="Domain"
                      allowClear
                      disabled={!!user || (Array.isArray(emailDomains) && emailDomains.length === 1)}
                      onChange={() => {
                        console.log('showError', showError);
                        showError && form.validateFields();
                      }}
                    >
                      {emailDomains?.map((domain: string) => (
                        <Select.Option key={domain} value={domain}>
                          @{domain}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>

            <Form.Item
              label="Phone"
              name="phone"
              rules={[
                {
                  validator: () => {
                    if (showError) {
                      const value: string = form.getFieldValue('phone') || '';
                      const regEx = /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
                      if (!value || regEx.test(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(new Error('Please input valid phone number'));
                      }
                    }
                  }
                }
              ]}
            >
              <Input
                style={{ width: 240 }}
                placeholder="+1"
                className="rounded-md"
                onBlur={() => onBlurInput('phone')}
              />
            </Form.Item>

            <Form.Item label="Select Roles" required>
              <Row gutter={8} align="middle">
                <Col span={isExpanded ? 20 : 12}>
                  <Form.Item
                    name="selected_role"
                    rules={[
                      {
                        validator: () => {
                          if (showError)
                            if (addedRoles.length > 0) {
                              return Promise.resolve(); // No validation error
                            } else {
                              return Promise.reject(new Error('Please select at least one role'));
                            }
                        }
                      }
                    ]}
                  >
                    <Select
                      placeholder="Select roles"
                      className="rounded-md"
                      onChange={handleRoleChange}
                      options={filteredRoles.map((role) => ({ value: role.id, label: role.name }))}
                    />
                  </Form.Item>
                </Col>
                <Col span={isExpanded ? 4 : 12} className={'pb-5'}>
                  <Button
                    icon={
                      <span className="font-bold">
                        <PlusOutlined />
                      </span>
                    }
                    onClick={handleAddRole}
                  />
                  {!isExpanded ? (
                    <span className="ml-2 text-blue-600 cursor-pointer" onClick={handleViewRoles}>
                      View available roles <ArrowRightOutlined />
                    </span>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              {addedRoles.length > 0 ? (
                <div className="mt-4 flex flex-wrap gap-2">
                  {addedRoles.map((role) => (
                    <div key={role.id} className="flex items-center px-3 py-1 rounded-full bg-gray-300 text-xs">
                      {role.name}
                      <Button
                        type="text"
                        icon={<CloseOutlined style={{ fontSize: '8px', color: 'gray', fontWeight: '600' }} />}
                        className="ml-2"
                        style={{
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          padding: '0',
                          width: '20px',
                          height: '20px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                        onClick={() => handleRemoveRole(role.id)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </Form.Item>
            <Form.Item>
              <Row>
                <Col span={9}></Col>
                <Col span={12}>
                  <Form.Item name="active" valuePropName="checked">
                    <Checkbox disabled={blockedButtonDisabled}>Activate User</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              {!user && (
                <Row>
                  <Col span={9}></Col>
                  <Col span={12}>
                    <Form.Item label="" name="send_reset_password" valuePropName="checked">
                      <Checkbox>{`Send "Set Password" Email`}</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form.Item>
          </Form>
        </Col>
        {isExpanded ? (
          <Col span={12}>
            <div
              className="p-4 border-l"
              style={{
                backgroundColor: '#f0f0f0',
                maxHeight: '400px',
                overflowY: 'auto',
                msOverflowStyle: 'none'
              }}
            >
              <Button onClick={handleHideRoles} type="link" className="text-base">
                <ArrowLeftOutlined /> Hide Roles
              </Button>
              {roles.map((role) => {
                const isRoleAdded = addedRoles.find((addedRole) => addedRole.id == role.id);
                return (
                  <Card
                    key={role.id}
                    className={`mb-2 border ${isRoleAdded ? 'border-green-500' : ''}`}
                    style={{
                      borderRadius: '8px',
                      padding: '10px 0',
                      transition: 'border-color 0.3s',
                      cursor: 'pointer',
                      borderColor: isRoleAdded ? 'green' : 'transparent'
                    }}
                    onMouseOver={(e) => {
                      if (!isRoleAdded) {
                        e.currentTarget.style.borderColor = 'blue';
                        const addRoleText = e.currentTarget.querySelector('.add-role-text') as HTMLElement;
                        if (addRoleText) {
                          addRoleText.style.display = 'flex';
                        }
                      }
                    }}
                    onMouseLeave={(e) => {
                      if (!isRoleAdded) {
                        e.currentTarget.style.borderColor = 'transparent';
                        const addRoleText = e.currentTarget.querySelector('.add-role-text') as HTMLElement;
                        if (addRoleText) {
                          addRoleText.style.display = 'none';
                        }
                      }
                    }}
                  >
                    <div>
                      <div className="flex items-center">
                        <span className={'w-5'}>
                          <CheckCircleFilled
                            className={'text-green-500 mr-2'}
                            style={!isRoleAdded ? { display: 'none' } : {}}
                          />
                        </span>
                        <span className="font-bold">{role.name}</span>
                        {isRoleAdded ? (
                          <span
                            className="text-red-600 ml-auto flex items-center cursor-pointer font-bold"
                            onClick={() => handleRemoveRole(role.id)}
                          >
                            <CloseSquareFilled className="mr-1" />
                            Remove
                          </span>
                        ) : (
                          <span
                            className="text-blue-600 ml-auto add-role-text flex items-center cursor-pointer font-bold"
                            style={{ display: 'none' }}
                            onClick={() => setAddedRoles([...addedRoles, role])}
                          >
                            <PlusSquareFilled className="mr-1" />
                            Click to Add role
                          </span>
                        )}
                      </div>
                      <div className={'pl-5'}>{role.description || ''}</div>
                    </div>
                  </Card>
                );
              })}
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Modal>
  );
};
