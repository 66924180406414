import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { AxiosError, AxiosResponse } from 'axios';
import { AddTasksPayload, BulkDeal, CategoryTask, Deal, EditTasksPayload } from 'types/Contract';
import { useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { UploadedFile } from 'models/Files';
import { FollowContractRecord, MultiDealRequestPayload } from '../models/Deal';

/**
 * Fetch the logged-in user's company contracts
 */
export const fetchMyCompanyContracts = async () => {
  try {
    const res = await axios.get(`/secure/contracts`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch contract summary
 * @param contract_id
 */
export const fetchContractSummary = async (contract_id: number) => {
  try {
    const res = await axios.get(`/api/contract/${contract_id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch contract summary
 * @param contract_id
 */
export const fetchDealContacts = async (contract_id: number) => {
  try {
    const res = await axios.get(`/api/contract/${contract_id}/contacts`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch contract comps
 *
 * @param {number} contract_id
 * @param {number} years
 */
export const fetchDealComps = async (contract_id: number, years: number) => {
  try {
    const res = await axios.get(`/api/contract/${contract_id}/comps?years=${years}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Returns a Prisma structured JSON object
 *
 * @param {number} contract_id
 */
export async function fetchContract(contract_id: number) {
  try {
    const res = await axios.get(`/secure/p/contract/${contract_id}`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Returns a Prisma structured JSON object
 *
 * @param {number} id
 */
export async function fetchDeal(id: number) {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`/api/deal/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useDeal = (id: number, config = {}): UseQueryResult<Deal, AxiosError> =>
  useQuery({
    queryKey: ['deal', id],
    queryFn: () => {
      return fetchDeal(id);
    },
    ...config
  });

/**
 * Fetch Contract Renewal Notifications
 *
 * @param {number} id
 */
export async function fetchContractRenewalNotifications(id: number) {
  try {
    const res: AxiosResponse<any, any> = await axios.get(`/secure/contract/${id}/renewal/notifications`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function addDeal(deal: Deal, profile_id?: number) {
  try {
    const url = `/api/deal`;
    const { data } = await axios.post(url, deal, { params: { profile_id } });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useAddDeal = (config = {}) =>
  useMutation({
    mutationFn: async ({ data, profile_id }: { data: Deal; profile_id?: number }) => {
      return await addDeal(data, profile_id);
    },
    ...config
  });

export async function editDeal(dealId: number, deal: Partial<Deal>) {
  try {
    const url = `/api/deal/${dealId}`;
    const { data } = await axios.patch(url, deal);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useEditDeal = (config = {}) =>
  useMutation({
    mutationFn: async (data: Partial<Deal> & { dealId: number }) => {
      const { dealId, ...rest } = data;
      return await editDeal(dealId, rest);
    },
    ...config
  });

/**
 * Returns a Prisma structured JSON object
 *
 * @param formData
 */
export async function fileUpload(formData: any) {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/api/files/upload`, formData, {
      transformRequest: () => formData
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Remove File
 * @param key
 */
export async function removeFile(key: string) {
  try {
    const res: AxiosResponse<any, any> = await axios.delete(`/api/file`, {
      params: { key }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function removeFiles(keys: string[]) {
  try {
    const res: AxiosResponse<any, any> = await axios.delete(`/api/files`, {
      data: keys
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function deleteDeal(id: number) {
  try {
    const res: AxiosResponse<any, any> = await axios.delete(`/api/deal/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function removeContractFile(id: number, key: string) {
  try {
    const res: AxiosResponse<any, any> = await axios.delete(`/api/deal/${id}/file`, {
      params: { key }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Attach upload files to contract record
 * @param id
 * @param data
 */
export async function attachContractFiles(id: number, data: UploadedFile[]) {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/api/deal/${id}/files/attach`, data);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function parseBulkContractFile(formData: any) {
  try {
    const res: AxiosResponse<any, any> = await axios.post(`/api/deals/upload/parse`, formData, {
      transformRequest: () => formData
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useParseBulkContractFile = (config = {}) =>
  useMutation({
    mutationFn: async (data: any) => {
      return await parseBulkContractFile(data);
    },
    ...config
  });

export async function addBulkDeal(deals: BulkDeal[]) {
  try {
    const url = `/api/deals`;
    const { data } = await axios.post(url, deals);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useAddBulkDeal = (config = {}) =>
  useMutation({
    mutationFn: async (data: BulkDeal[]) => {
      return await addBulkDeal(data);
    },
    ...config
  });

export async function updateDealMetadata(id: number, data: any) {
  try {
    const res = await axios.patch(`api/deal/${id}/metadata`, data);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

const getCategoryDefaultTasks = async (): Promise<CategoryTask[]> => {
  try {
    const url = `/api/tasks/category_default`;
    const res = await axios.get(url);
    return res.data.data;
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useCategoryDefaultTasks = (config = {}): UseQueryResult<CategoryTask[], AxiosError> => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['category_default_tasks'],
    queryFn: async () => {
      const res = await getCategoryDefaultTasks();
      const defaultCategories = res.map((category: CategoryTask) => ({
        categoryId: category.category_root.id,
        categoryName: category.category_root.name
      }));

      queryClient.setQueryData(['default_categories'], defaultCategories);

      return res;
    },
    staleTime: 1000 * 60 * 60 * 24,
    ...config
  });
};

export const useDefaultCategories = (config = {}): UseQueryResult<Record<string, string | number>[]> => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['default_categories'],
    queryFn: () => queryClient.getQueryData(['default_categories']) || [],
    staleTime: 1000 * 60 * 60 * 24,
    ...config
  });
};

/**
 * Fetch deal notes
 * @param contract_id
 */
export const fetchDealNotes = async (contract_id: number) => {
  try {
    const res = await axios.get(`/api/deal/${contract_id}/notes`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const getRequestAccess = async (contract_id: number) => {
  try {
    const res = await axios.get(`/api/deal/documents/${contract_id}/access_request`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const sendRequestAccess = async (payload: { contract_id: number }) => {
  try {
    const res = await axios.post(`/api/deal/documents/access_request`, payload);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const notifyClient = async (payload: { contract_id: number }) => {
  try {
    const res = await axios.post(`/api/deal/documents/notify_access_grant`, payload);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const addTasks = async (dealId: number, payload: AddTasksPayload[]) => {
  try {
    const res = await axios.post(`/api/contract/${dealId}/tasks`, payload);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useAddTasks = (config = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ dealId, data }: { dealId: number; data: AddTasksPayload[] }) => {
      const res = await addTasks(dealId, data);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

/**
 * Create deal note
 */
export async function createDealNote(
  contractId: number,
  dealNote: { note: string; parent_id?: number; is_visible_to_npi: boolean }
) {
  try {
    const url = `/api/deal/${contractId}/note`;
    const { data } = await axios.post(url, dealNote);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update deal note
 */
export async function updateDealNote(
  contractId: number,
  noteId: number,
  payload: { note: string; is_visible_to_npi: boolean }
) {
  try {
    const url = `/api/deal/${contractId}/note/${noteId}`;
    const { data } = await axios.patch(url, payload);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Delete deal note
 */
export async function deleteDealNote(contractId: number, noteId: number) {
  try {
    const url = `/api/deal/${contractId}/note/${noteId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update deal task
 */
export async function updateTask(dealId: number, taskId: number, data: EditTasksPayload) {
  try {
    const res = await axios.patch(`api/contract/${dealId}/task/${taskId}`, data);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
}

export const useUpdateTask = (config = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ dealId, taskId, data }: { dealId: number; taskId: number; data: EditTasksPayload }) => {
      const res = await updateTask(dealId, taskId, data);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

/**
 * Delete deal task
 */
export async function deleteDealTask(contractId: number, taskId: number) {
  try {
    const url = `/api/contract/${contractId}/task/${taskId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useDeleteDealTask = (config = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ dealId, taskId }: { dealId: number; taskId: number }) => {
      const res = await deleteDealTask(dealId, taskId);
      await queryClient.invalidateQueries(['my_tasks']);
      return res;
    },
    ...config
  });
};

export const addMultipleDeal = async (deal: any, profile_id?: number) => {
  try {
    const url = `/api/deals`;
    const { data } = await axios.post(url, deal, { params: { profile_id } });
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const fetchContractsForNotifications = async (profile_id?: number) => {
  try {
    const res = await axios.get(`/api/notifications/contracts`, {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

export const useContractsForNotifications = (profile_id?: number): UseQueryResult<FollowContractRecord[], AxiosError> =>
  useQuery({
    queryKey: ['contracts', profile_id],
    queryFn: () => fetchContractsForNotifications(profile_id)
  });

export const useAddMultipleDeal = (config = {}) =>
  useMutation({
    mutationFn: async ({ data, profile_id }: { data: MultiDealRequestPayload[]; profile_id?: number }) => {
      return await addMultipleDeal(data, profile_id);
    },
    ...config
  });

export const useSendRequestAccess = (config = {}) =>
  useMutation({
    mutationFn: async (data: { contract_id: number }) => {
      return await sendRequestAccess(data);
    },
    ...config
  });

export const useNotifyClient = (config = {}) =>
  useMutation({
    mutationFn: async (data: { contract_id: number }) => {
      return await notifyClient(data);
    },
    ...config
  });
