import { Avatar, Button, Divider, Input, InputRef, Select, SelectProps } from 'antd';
import React, { ChangeEvent, createRef, useState } from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { escapeRegExp } from 'lodash-es';
import { firstLetters } from 'shared/Helpers';
import './MultiSelect.scss';
import SelectReusable from './SelectReusable';

const { Option } = Select;

/**
 * Props interface for the Props class.
 *
 * @interface Props
 * @extends {SelectProps}
 */
interface Props extends SelectProps {
  options: Array<{ value: number | string; label: string }>;
  submitButtonLabel?: string;
  onChange?: (value: Array<string | number>) => void;
  onSubmit?: (value: Array<string | number>) => void;
  displayLabelAll?: boolean;
}

/**
 * A multi-select component for React.
 *
 * @component
 *
 * @param {object} Props - The props for the MultiSelect component.
 * @param {Array<object>} Props.options - The list of options for the multi-select.
 * @param {string} Props.submitButtonLabel - The label for the submit button.
 * @param {function} Props.onChange - A callback function that is called when the selected values change.
 * @param {function} Props.onSubmit - A callback function that is called when the submit button is clicked.
 *
 * @return {React.ReactElement} - The rendered MultiSelect component.
 */
const MultiSelect: React.FC<Props> = ({
  options,
  submitButtonLabel,
  onChange,
  onSubmit,
  displayLabelAll,
  ...rest
}: Props): React.ReactElement => {
  const [value, setValue] = useState<any[]>(displayLabelAll ? ['All'] : []);
  const [searchStr, setSearchStr] = useState<undefined | string>(undefined);
  const filterRef = createRef<InputRef>();
  const { maxTagCount } = rest;

  /**
   * Handles the change event for a given array of values.
   *
   * @param {Array<string | number>} e - The array of values representing the change event.
   * @returns {void}
   */
  const handleChange = (e: Array<string | number>): void => {
    let newVal: any[];
    if (displayLabelAll) {
      if (e.length === 0) {
        newVal = ['All'];
      } else if (e.length > 1) {
        newVal = e.filter((item) => item != 'All');
      } else {
        newVal = e;
      }
    } else {
      newVal = e;
    }
    setValue(newVal);
    if (typeof onChange === 'function') onChange(newVal);
  };

  /**
   * Renders an option element for a dropdown list.
   *
   * @param {object} option - The option object.
   * @param {string | number} option.value - The value of the option.
   * @param {string} option.label - The label of the option.
   * @param {number} key - The unique key for the option element.
   * @returns {React.ReactElement} - The rendered option element.
   * @since bugfix/VAN-142 Added back 'key' parameter to render, which is a required field.
   *        The lack of 'key' was throwing an error which was breaking the search capability
   *        of the component.
   */
  const OptionRender = (option: { value: string | number; label: string }, key: number): React.ReactElement => {
    return (
      <Option value={option.value} label={option.label === 'All' ? option.label : firstLetters(option.label)} key={key}>
        {option.label}
      </Option>
    );
  };

  /**
   * Handles the change event of an input element.
   * Updates the search string based on the new value.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The change event object.
   * @returns {void}
   */
  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (value) {
      setSearchStr(escapeRegExp(value));
    } else {
      setSearchStr(undefined);
    }
  };

  /**
   * Handles form submission.
   *
   * @return {void}
   */
  const handleSubmit = (): void => {
    if (typeof onSubmit === 'function') onSubmit(value);
  };

  /**
   * Renders a tag component with a label inside an Avatar component.
   *
   * @param {Object} props - The properties for rendering the tag component.
   * @param {string} props.label - The label to be displayed inside the tag component.
   * @returns {ReactElement} - The rendered tag component.
   */
  const tagRender = (props: any): React.ReactElement => {
    return (
      <Avatar size={29} className="-mr-1 avatar text-base">
        {props.label}
      </Avatar>
    );
  };

  /**
   * Generates a React element for the maxTagPlaceholder.
   * The maxTagPlaceholder represents the number of tags that have been hidden in a component.
   *
   * @returns {React.ReactElement} The React element for the maxTagPlaceholder.
   */
  const maxTagPlaceholder = (): React.ReactElement => {
    return (
      <Avatar size={29} className="avatar">
        +{maxTagCount && value.length - (typeof maxTagCount === 'number' ? maxTagCount : 4)}
      </Avatar>
    );
  };

  return (
    <div className="multiselect-wrapper oval" style={{ width: 180 }}>
      <SelectReusable
        className="w-full"
        placeholder="Select Assignees"
        allowClear={true}
        mode="multiple"
        bordered={false}
        value={value}
        onChange={handleChange}
        onFocus={() => filterRef.current?.focus()}
        optionLabelProp="label"
        dropdownRender={(menu) => (
          <div style={{ padding: '4px 6px' }}>
            <Input
              placeholder="Search"
              prefix={<FilterOutlined />}
              ref={filterRef}
              allowClear
              onChange={handleSearch}
              style={{ width: '100%' }}
            />
            <Divider style={{ margin: '8px 0 0' }} />
            {menu}
            {typeof onSubmit === 'function' && (
              <>
                <Divider style={{ margin: '8px 0 8px' }} />
                <div className="text-right">
                  <Button size="small" type="primary" onClick={handleSubmit}>
                    {submitButtonLabel || 'Submit'}
                  </Button>{' '}
                </div>
              </>
            )}
          </div>
        )}
        tagRender={tagRender}
        maxTagPlaceholder={maxTagCount ? maxTagPlaceholder : ''}
        {...rest}
      >
        {options
          .filter((item) => item.label?.match(new RegExp(escapeRegExp(searchStr), 'i')))
          .map((option: any, key: number) => OptionRender(option, key))}
      </SelectReusable>
    </div>
  );
};

export default MultiSelect;
