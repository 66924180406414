import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  InputRef,
  List,
  message,
  Row,
  Space,
  Table,
  TableColumnsType,
  Typography
} from 'antd';
import { DownloadOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { UserRecord } from 'sb/models/User';
import { getUsers } from 'sb/api/extra';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';
import { escapeRegExp, filter, isNil } from 'lodash-es';
import fileDownload from 'js-file-download';
import { ExcelCreateRequest, FieldType, Horizontal, NumberFormat, Vertical } from 'sb/models/ExcelJs';
import 'components/molecules/admin/Users.scss';
import { createExcel } from 'sb/api/excel';
import { FieldSorter } from '../../shared/Helpers';

const Users: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserRecord[]>([]);
  const [UserOrig, setUserOrig] = useState<UserRecord[]>([]);
  const [creatingExcel, setCreatingExcel] = useState(false);

  const filterInputRef = useRef<InputRef>(null);

  useEffect(() => {
    handleClickRefresh().then();
  }, []);

  const handleClickRefresh = async () => {
    setLoading(true);
    const res = await getUsers();
    if (Array.isArray(res)) {
      setUsers(
        res.map((item: UserRecord) => {
          item.logins_count = item.logins_count || 0;
          return item;
        })
      );
    }

    if (res) {
      setUsers(res);
      setUserOrig(res);
      setTimeout(() => {
        filterInputRef.current?.focus();
      }, 1000);
    }
    setLoading(false);
  };

  const handleExport = async () => {
    const filename = `NPI-Digital-Users.xlsx`;

    const formData: ExcelCreateRequest = {
      data: users.map((user: UserRecord) => {
        return {
          name: user.first_name + ' ' + user.last_name,
          title: user.title,
          email: user.email,
          logins_count: user.logins_count,
          last_login: user.auth_log.length > 0 ? user.auth_log[0].login_date : null,
          roles: user.user_roles?.join(', ')
        };
      }),
      options: { fileName: filename, workSheetName: 'Users' },
      columns: [
        { header: 'Name', key: 'name', width: 16, type: FieldType.String },
        { header: 'Title', key: 'title', width: 32, type: FieldType.String },
        { header: 'Email', key: 'email', width: 32, type: FieldType.String },
        {
          header: 'Logins',
          key: 'logins_count',
          width: 8,
          type: FieldType.Number,
          alignment: {
            horizontal: Horizontal.center,
            vertical: Vertical.bottom,
            wrapText: false
          },
          numFmt: NumberFormat.integer
        },
        { header: 'Last Login', key: 'last_login', width: 12, type: FieldType.Date },
        {
          header: 'Roles',
          key: 'roles',
          width: 64,
          type: FieldType.String,
          alignment: {
            horizontal: Horizontal.left,
            vertical: Vertical.bottom,
            wrapText: true
          }
        }
      ]
    };

    try {
      setCreatingExcel(true);
      const excelData = await createExcel(formData);
      fileDownload(excelData, filename);
    } catch (e) {
      message.error('Problem creating Excel file.');
    } finally {
      setCreatingExcel(false);
    }
  };

  const columns: TableColumnsType<UserRecord> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        sortDirections: ['ascend', 'descend'],
        sorter: (a: UserRecord, b: UserRecord) => FieldSorter(a.first_name, b.first_name),
        render: (_, rec: UserRecord) => `${rec.first_name || '--'} ${rec.last_name || ' '}`
      },
      {
        title: 'Title',
        dataIndex: 'title',
        render: (_, rec: UserRecord) => rec.title || '--',
        sortDirections: ['ascend', 'descend'],
        sorter: (a: UserRecord, b: UserRecord) => FieldSorter(a.title, b.title)
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sortDirections: ['ascend', 'descend'],
        sorter: (a: UserRecord, b: UserRecord) => FieldSorter(a.email, b.email)
      },
      {
        title: 'Roles',
        dataIndex: 'user_roles',
        render: (_, rec: UserRecord) => {
          if (rec.user_roles && rec.user_roles.length > 0) {
            return (
              <List
                size="small"
                bordered={false}
                split={false}
                dataSource={rec.user_roles}
                renderItem={(role: any) => (
                  <List.Item>
                    <div className="role-label">{role}</div>
                  </List.Item>
                )}
              />
            );
          }
          return '--';
        }
      },
      {
        title: 'Logins',
        dataIndex: 'logins_count',
        align: 'center',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: UserRecord, b: UserRecord) => a.logins_count - b.logins_count
      },
      {
        title: 'Last Login',
        dataIndex: 'last_login',
        sortDirections: ['descend', 'ascend'],
        sorter: (a: UserRecord, b: UserRecord) => {
          const loginA = a.auth_log.length > 0 ? a.auth_log[0].login_date : null;
          const loginB = b.auth_log.length > 0 ? b.auth_log[0].login_date : null;
          return FieldSorter(loginA, loginB);
        },
        render: (_, rec: UserRecord) =>
          rec.auth_log.length > 0 ? moment(rec.auth_log[0].login_date).format('lll') : '-'
      }
    ],
    []
  );
  const handleSearchWithinUsers = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value) {
      const regExp = new RegExp(escapeRegExp(value), 'i');

      /**
       * We have to use type 'any' to avoid TS2232 error
       */
      const filteredUsers: any = filter(UserOrig, (item: UserRecord) => {
        const matchedRoles = item.user_roles?.filter((role) => role.match(regExp));
        const name = `${item.first_name || ''} ${item.last_name || ''}`;
        return (
          (!isNil(name) && name.match(regExp)) ||
          (!isNil(item.email) && item.email.match(regExp)) ||
          (!isNil(item.title) && item.title.match(regExp)) ||
          (matchedRoles && matchedRoles.length > 0)
        );
      });

      setUsers(filteredUsers);
    } else {
      setUsers(UserOrig);
    }
  };

  return (
    <div className="users">
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Users</Typography.Title>
        </Col>
        <Col>
          <Space>
            <Button
              type="default"
              loading={loading}
              icon={<SyncOutlined spin={loading} />}
              onClick={handleClickRefresh}
            >
              Refresh
            </Button>
            <Button type="default" onClick={handleExport} icon={<DownloadOutlined />} loading={creatingExcel}>
              Download
            </Button>
          </Space>
        </Col>
      </Row>

      <Divider className="my-4" />
      <div style={{ margin: `12px 0 ${users.length > 0 ? '-40px' : 0}` }}>
        <Input
          placeholder="Filter by Name, Email, Title, or Role"
          prefix={<SearchOutlined />}
          ref={filterInputRef}
          allowClear
          onChange={handleSearchWithinUsers}
          style={{ width: 410 }}
          size="large"
        />
      </div>
      <Table
        rowKey="user_id"
        size="small"
        loading={loading}
        columns={columns}
        dataSource={users}
        pagination={{
          ...DEFAULT_TABLE_PAGINATION_CONFIG,
          defaultPageSize: 50
        }}
      />
    </div>
  );
};

export default Users;
