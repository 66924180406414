import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { appVersionsState } from 'recoil/atoms';
import socket from 'socket.io-client';

export const SOCKET_URL = import.meta.env.VITE_APP_EXPRESS_URL || 'http://localhost:3030';

export const useSocket = () => {
  const setAppVersions = useSetRecoilState(appVersionsState);

  const updateAppVersion = useCallback(
    (data: any) => {
      setAppVersions((oldAppVersions) => {
        return {
          ...(oldAppVersions || {}),
          ...data
        };
      });
    },
    [setAppVersions]
  );

  useEffect(() => {
    const client = socket(SOCKET_URL, {
      transports: ['websocket']
    });

    client.on('appVersions', updateAppVersion);

    return () => {
      client.off('connect');
      client.off('disconnect');
      client.off('appVersions');
    };
  }, [updateAppVersion]);
};
