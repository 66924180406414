import { useEffect, useState, useMemo, useCallback } from 'react';
import { Button, Card, Col, Drawer, Form, message, Popconfirm, Row, Table, Tooltip } from 'antd';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { SortableHandle, SortableElement, SortableContainer } from 'react-sortable-hoc';
import { CollapseCallbackArgs, UnmountClosed } from 'react-collapse';
import { arrayMoveImmutable } from 'array-move';

import { DealDynamicForm } from 'components/atoms/DealDynamicForm';
import { Dynamic } from 'sb/models/Dynamic';
import { CHART_TYPES, ChartTypes } from 'sb/shared/constants';
import {
  getDealDynamics,
  reorderDealDynamics,
  createDealDynamic,
  updateDealDynamic,
  deleteDealDynamic
} from 'sb/api/extra';

// DNS Sort
const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableContainerComponent = SortableContainer((props: any) => <tbody {...props} />);

interface Props {
  visible: boolean;
  onClose: () => void;
}

export const DealDynamicsDrawer: React.FC<Props> = ({ visible, onClose }) => {
  const [form] = Form.useForm<Dynamic>();

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openForm, setOpenForm] = useState(false);

  const [selectedDynamicId, setSelectedDynamicId] = useState<number>();
  const [dynamics, setDynamics] = useState<Dynamic[]>([]);

  useEffect(() => {
    if (visible) {
      getDynamics();
    } else {
      setOpenForm(false);
    }
  }, [visible]);

  const getDynamics = async () => {
    setLoading(true);
    const res = await getDealDynamics();
    if (res) {
      setDynamics(res);
    }
    setLoading(false);
  };

  const onClickEdit = useCallback(
    (value: Dynamic) => {
      setOpenForm(true);
      setSelectedDynamicId(value.id);

      value.scale = [value.scale_low, value.scale_high];
      form.setFieldsValue(value);
    },
    [form]
  );

  const handleNewClick = () => {
    setOpenForm(true);
    setSelectedDynamicId(undefined);

    form.resetFields();
  };

  const onFormOpenRest = ({ isFullyOpened }: CollapseCallbackArgs) => {
    if (!isFullyOpened) return;

    form.getFieldInstance('name')?.focus();
  };

  const handleDelete = async (rec: Dynamic) => {
    const res = await deleteDealDynamic(rec.id);
    if (res) {
      setDynamics((prev) => prev.filter((item) => item.id !== rec.id));
    }
    setOpenForm(false);
  };

  const handleFinish = async (values: Dynamic) => {
    values.scale_low = values.scale?.[0] || 0;
    values.scale_high = values.scale?.[1] || 0;

    setSaving(true);

    if (selectedDynamicId) {
      await updateDealDynamic(selectedDynamicId, values);
    } else {
      await createDealDynamic(values);
    }

    await getDynamics();

    setOpenForm(false);
    setSaving(false);
  };

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    if (oldIndex === newIndex) return;

    const newDynamics = arrayMoveImmutable(dynamics, oldIndex, newIndex).filter((el) => !!el);
    setDynamics(newDynamics);

    await reorderDealDynamics(newDynamics.map((item, index) => ({ id: item.id, order_by: index })));
    message.success('Successfully re-ordered items');
  };

  const columns = useMemo(() => {
    return [
      {
        title: 'Sort',
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />
      },
      { title: 'Name', dataIndex: 'name' },
      { title: 'Hint', dataIndex: 'hint' },
      {
        title: 'Scale',
        key: 'scale',
        width: 60,
        render: (_: string, rec: Dynamic) => rec.scale_low + ' to ' + rec.scale_high
      },
      {
        title: 'Rating Order',
        dataIndex: 'rating_order',
        width: 110,
        render: (text: string) => {
          switch (text) {
            case 'ascending':
              return <div>Neg {<ArrowRightOutlined style={{ fontSize: 11 }} />} Pos</div>;
            case 'descending':
              return <div>Pos {<ArrowRightOutlined style={{ fontSize: 11 }} />} Neg</div>;
          }
        }
      },
      {
        title: 'Chart Type',
        dataIndex: 'chart_type',
        render: (text: string) =>
          CHART_TYPES.find((item: ChartTypes) => {
            return item.value === text;
          })?.label
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 75,
        render: (_: string, rec: Dynamic) => (
          <>
            <Tooltip title="Click to edit row">
              <Button type="link" onClick={() => onClickEdit(rec)} size="small" icon={<EditOutlined />} />
            </Tooltip>
            <Popconfirm title="Remove line item?" onConfirm={() => handleDelete(rec)} placement="top">
              <Tooltip title="Click to confirm row deletion" placement="bottom">
                <Button type="link" size="small" icon={<DeleteOutlined />} />
              </Tooltip>
            </Popconfirm>
          </>
        )
      }
    ];
  }, [onClickEdit]);

  return (
    <Drawer title="Supplier Dynamics" width={780} open={visible} onClose={onClose}>
      <UnmountClosed isOpened={!openForm}>
        <Row justify="end" gutter={[0, 8]}>
          <Col>
            <Button icon={<PlusOutlined />} onClick={handleNewClick}>
              Add Insight
            </Button>
          </Col>
          <Col span={24}>
            <Table
              title={() => 'Supplier Dynamics'}
              className="sm-font"
              rowKey="id"
              size="small"
              loading={loading}
              columns={columns}
              dataSource={dynamics}
              components={{
                body: {
                  wrapper: (props: any) => (
                    <SortableContainerComponent
                      useDragHandle
                      hideSortableGhost={false}
                      disableAutoscroll
                      helperClass="sortableHelper row-dragging"
                      onSortEnd={onSortEnd}
                      {...props}
                    />
                  ),
                  row: (props: any) => {
                    // function findIndex base on Table rowKey props and should always be a right array index
                    const index = dynamics.findIndex((x) => {
                      return x.id === props['data-row-key'];
                    });
                    return <SortableItem {...props} index={index} />;
                  }
                }
              }}
              pagination={false}
            />
          </Col>
        </Row>
      </UnmountClosed>

      <UnmountClosed isOpened={openForm} onRest={onFormOpenRest}>
        <Card title="Supplier Insight" size="small">
          <DealDynamicForm form={form} saving={saving} onFinish={handleFinish} onCancel={() => setOpenForm(false)} />
        </Card>
      </UnmountClosed>
    </Drawer>
  );
};
