export interface BannerAdPatch {
  name: string;
  main_text?: string;
  banner_type: 'text' | 'image';
  url: string;
  publish_date: Date;
  expiration_date?: Date;
  permissions?: string[];
}

export enum BannerAdSubscriptionLevel {
  Gold = 'read:portofino_full',
  Basic = 'read:portofino'
}

export enum BannerAdStatus {
  Scheduled = 'Scheduled',
  Inactive = 'Inactive',
  Active = 'Active'
}

export const audienceFilters = [
  {
    text: 'Gold',
    value: 'read:portofino_gold'
  },
  {
    text: 'Basic',
    value: 'read:portofino'
  }
];

export const statusFilters = [
  {
    text: 'Scheduled',
    value: 'Scheduled'
  },
  {
    text: 'Active',
    value: 'Active'
  },
  {
    text: 'Inactive',
    value: 'Inactive'
  }
];
