import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Divider, Empty, Form, notification, Row, Skeleton, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';
import { SalesforceAccountSearch } from 'components/atoms/SalesforceAccountSearch';
import { useClientDetail, useDeleteClientRelation, useUpdateClient, useUpdateClientLogoFilename } from 'sb/api/client';
import { ClientForm } from 'components/atoms/ClientForm';
import { ClientRelatedCompanies } from 'components/atoms/ClientRelatedCompanies';
import { useQueryClient } from '@tanstack/react-query';
import { ClientManagementSummary } from 'sb/models/Client';
import { FileUploadResult } from 'sb/models/Files';
import { CompanyRelationshipModal } from 'components/molecules/admin/CompanyRelationshipModal';

interface SalesforceAccount {
  value: string;
  label: string;
  key: string;
}

const Clients: React.FC = () => {
  const [form] = Form.useForm();
  const { aclCheck } = useScopes();

  // LOCAL STATE
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [isParentCompanyEditEnabled, setIsParentCompanyEditEnabled] = useState(false);
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [salesforceAcc, setSalesforceAcc] = useState<SalesforceAccount>();

  const deleteRalatedCompany = (id: number) => {
    deleteClientRelation(id);
  };

  const resetEditAndAddStates = () => {
    setIsEditEnabled(false);
    setIsAddClicked(false);
  };

  const setFormValues = useCallback(
    (_client: ClientManagementSummary) => {
      form.setFieldsValue({
        encrypted_name: _client?.encrypted_name,
        salesforce_id: _client?.salesforce_id || salesforceAcc?.value,
        industry: _client?.industry,
        sub_industry: _client?.sub_industry,
        annual_revenue_millions: _client?.annual_revenue_millions,
        encrypted_domain: _client?.encrypted_domain,
        auth0_organization_id: _client?.auth0_organization_id,
        logo_url: _client?.logo_url
      });
      form.setFieldValue(['salesforce_account', 'Name'], _client?.salesforce_account?.Name);
    },
    [form, salesforceAcc?.value]
  );

  // Queries and Mutations
  const queryClient = useQueryClient();
  const {
    data: client,
    isLoading,
    isInitialLoading
  } = useClientDetail(salesforceAcc?.value || '', {
    enabled: !!salesforceAcc?.value,
    onSuccess: setFormValues,
    onError: form.resetFields
  });

  useEffect(() => {
    if (client) {
      setFormValues(client);
    }
  }, [client, setFormValues]);

  const { mutate: updateClient, isLoading: isClientUpdating } = useUpdateClient({
    onSuccess: () => {
      queryClient.invalidateQueries(['clientDetail', salesforceAcc?.value]).then();
      notification.success({
        message: 'Client updated successfully',
        duration: 4
      });
    },
    onSettled: resetEditAndAddStates,
    onError: () => {
      notification.error({
        message: 'Something went wrong while updating client. Please try again.',
        duration: 4
      });
    }
  });

  const { mutate: deleteClientRelation } = useDeleteClientRelation({
    onSuccess: () => {
      queryClient.invalidateQueries(['clientDetail', salesforceAcc?.value]).then();
      notification.success({
        message: 'Company relation deleted successfully',
        duration: 4
      });
    },
    onError: () => {
      notification.error({
        message: 'Something went wrong while deleting client relation. Please try again.',
        duration: 4
      });
    }
  });

  // Logo upload
  const { mutate: uploadLogo } = useUpdateClientLogoFilename({
    onSuccess: () => {
      queryClient.invalidateQueries(['clientDetail', salesforceAcc?.value]).then();
    }
  });

  useEffect(() => {
    // Reset states when client changes
    resetEditAndAddStates();
  }, [salesforceAcc?.value]);

  const handleClientChange = async (_salesforceAcc: SalesforceAccount) => {
    form.resetFields();
    setSalesforceAcc(_salesforceAcc);
  };

  const handleSubmit = async (_values: any) => {
    updateClient({ ..._values, salesforceId: client?.salesforce_id });
  };

  const handleAdd = () => {
    setIsAddClicked(true);
  };

  const handleEdit = () => {
    setIsEditEnabled(true);
  };

  const handleEditParentCompany = () => {
    setIsParentCompanyEditEnabled(true);
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Clients</Typography.Title>
        </Col>
      </Row>

      <Row justify="space-between">
        <Col>
          <SalesforceAccountSearch
            placeholder="Search by supplier Name or Salesforce ID"
            onChange={handleClientChange}
          />
        </Col>
        <Col>
          {aclCheck(['create:suppliers_resellers']) &&
          salesforceAcc?.value &&
          !isLoading &&
          !client &&
          !isAddClicked ? (
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
              Add
            </Button>
          ) : null}
          {client && !isEditEnabled ? (
            <Button onClick={handleEdit} icon={<EditOutlined />} type="primary">
              Edit Info
            </Button>
          ) : null}
        </Col>
      </Row>

      <Divider className="my-4" />
      <div className="relative">
        <Skeleton loading={isInitialLoading} paragraph={{ rows: 10 }} active>
          {!client && !isInitialLoading && salesforceAcc?.value && <Empty description="No data found!" />}
          {!isLoading && ((client && client?.salesforce_id === salesforceAcc?.value) || (!client && isAddClicked)) ? (
            <ClientForm
              loading={isClientUpdating}
              client={client}
              form={form}
              onFinish={handleSubmit}
              disabled={client && !isEditEnabled}
              onCancel={() => {
                setIsEditEnabled(false);
                setIsAddClicked(false);
              }}
              onSaveLogo={async (res: FileUploadResult) => {
                if (salesforceAcc?.value && res?.key && res?.signedUrl && client) {
                  uploadLogo({ client_id: client.id, logo_filename: res.key });
                }
              }}
              showLogo={!!client}
            />
          ) : null}
          {client && (
            <ClientRelatedCompanies
              client={client}
              deleteRalatedCompany={deleteRalatedCompany}
              handleEditParentCompany={handleEditParentCompany}
            />
          )}
        </Skeleton>
      </div>
      {client && isParentCompanyEditEnabled && (
        <CompanyRelationshipModal
          open={isParentCompanyEditEnabled}
          clientId={client.id}
          salesforceId={client.salesforce_id}
          onCancel={() => setIsParentCompanyEditEnabled(false)}
        />
      )}
    </>
  );
};

export default Clients;
