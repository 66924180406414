import { Menu, MenuProps } from 'antd';
import './DealSupplierModalMenu.scss';
import { AreaChartOutlined, FileTextOutlined, PicLeftOutlined, CommentOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';

interface Props extends MenuProps {
  selectedKeys: Array<MenuKeys>;
  supplierDynamics?: boolean;
}

export enum MenuKeys {
  'Summary' = 'summary',
  'Supplier Dynamics' = 'supplierDynamics',
  'Files' = 'files',
  'Notes' = 'notes'
}

export const DealSupplierModalMenu: React.FC<Props> = ({ supplierDynamics, selectedKeys, ...rest }: Props) => {
  const { aclCheck } = useScopes();
  const items: MenuProps['items'] = [
    {
      label: 'Summary',
      icon: <PicLeftOutlined />,
      key: MenuKeys.Summary
    },
    aclCheck(['read:supplier_dynamics'])
      ? {
          label: 'Supplier Dynamics',
          icon: <AreaChartOutlined />,
          key: MenuKeys['Supplier Dynamics'],
          disabled: supplierDynamics !== true
        }
      : null,
    {
      label: 'Files',
      icon: <FileTextOutlined />,
      key: MenuKeys['Files']
    },
    {
      label: 'Notes',
      icon: <CommentOutlined />,
      key: MenuKeys['Notes']
    }
  ];

  return <Menu selectedKeys={selectedKeys} className="deal-modal-menu" mode="horizontal" items={items} {...rest} />;
};
