import { intersection } from 'lodash-es';
import { useRecoilState } from 'recoil';

import { scopeState } from 'recoil/atoms';
import { Scope } from 'types/permissions';

export const useScopes = () => {
  const [scopes, setScopes] = useRecoilState(scopeState);

  const aclCheck = (allowedScopes: Scope[]) => {
    return intersection(scopes, allowedScopes).length > 0;
  };

  return {
    scopes,
    aclCheck,
    setScopes
  };
};
