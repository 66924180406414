import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';

export const uploadFile = async (formData: any) => {
  try {
    const res = await axios.post(`/secure/file/upload`, formData);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Upload file to S3
 *
 * @param formData
 */
export const uploadFileToS3 = async (formData: any) => {
  try {
    const res = await axios.post(`/secure/s3`, formData, {
      transformRequest: () => formData
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Upload file to S3
 *
 * @param filename
 */
export const deleteFileFromS3 = async (filename: string) => {
  try {
    const res = await axios.delete(`/secure/s3/${filename}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Upload file to S3
 *
 * @param filename
 */
export const getFileUrlFromS3 = async (filename: string) => {
  try {
    const res = await axios.get(`/api/file/url`, {
      params: { filename }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
