import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import './DealCreationSuccess.scss';

export const DealCreationSuccessMessage: React.FC = () => {
  return (
    <>
      <div>
        <CheckCircleOutlined className="check-circle" />
      </div>
      <div>
        <Typography.Title level={3} className="font-bold !m-0 opacity-80">
          Deal Submitted Successfully!
        </Typography.Title>
        <Typography.Title level={4} className="!font-light !m-0 opacity-80">
          Your NPI team has been alerted to your deal submission.
        </Typography.Title>
        <Typography.Title level={5} className="font-medium text-base leading-4 text-center opacity-50">
          What&lsquo;s Next?
        </Typography.Title>
      </div>
    </>
  );
};
