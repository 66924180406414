import React, { useMemo, useState } from 'react';
import { useEditProductAccuracy, useSupplierProductAccuracy } from 'sb/api/productAccuracy';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { Button, Col, Empty, notification, Row, Select, Spin, Table } from 'antd';
import { Accuracy, EditAccuracyPayload, ProductAccuracy as ProductAccuracyModel } from 'models/Product';
import EditAccuracy from 'components/product-accuracy/EditAccuracy';
import { regionalCurrencyFormatter } from 'shared/Helpers';
import { EditOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { productAccuracyVariationState } from 'recoil/atoms';
import { ColumnType } from 'antd/es/table';

const ProductAccuracy: React.FC = () => {
  const [supplier, setSupplier] = useState<number | undefined>(undefined);
  const [accuracy, setAccuracy] = useRecoilState(productAccuracyVariationState);
  const [dealToEdit, setDealToEdit] = useState<Accuracy | undefined>(undefined);
  const [productToEdit, setProductToEdit] = useState<Omit<ProductAccuracyModel, 'accuracy'> | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data: productAccuracy, isInitialLoading } = useSupplierProductAccuracy(supplier!, {
    enabled: !!supplier
  });

  const productsWithVariation = useMemo(() => {
    if (!productAccuracy?.length) return [];

    return productAccuracy
      ?.map((product) => {
        return {
          ...product,
          accuracy: product.accuracy
            .map((acc) => {
              return {
                ...acc,
                variationInPercentage: (() => {
                  const predictedUnitCostQuantity = acc.predictedUnitCostQuantity;
                  const unitCost = acc.unit_cost;
                  //  calculate percentage increase or decrease from predicted to unit cost
                  if (unitCost && predictedUnitCostQuantity) {
                    if (unitCost > predictedUnitCostQuantity) {
                      return (((unitCost - predictedUnitCostQuantity) / predictedUnitCostQuantity) * 100).toFixed(2);
                    } else {
                      return (((predictedUnitCostQuantity - unitCost) / unitCost) * 100).toFixed(2);
                    }
                  } else return 0;
                })()
              };
            })
            .filter((acc) => acc.variationInPercentage > accuracy)
        };
      })
      .filter((p) => !!p.accuracy.length);
  }, [productAccuracy, accuracy]);

  const { mutate: updateAccuracy } = useEditProductAccuracy({
    onSuccess: () => {
      notification.success({
        message: 'Contract edited successfully'
      });
      setDealToEdit(undefined);
      setProductToEdit(undefined);
    },
    onError: () => {
      notification.error({
        message: 'Error while editing contract'
      });
      setDealToEdit(undefined);
      setProductToEdit(undefined);
    }
  });

  const handleVendorChange = (supplierId: number) => {
    setSupplier(supplierId);
  };

  const handleAccuracyMargin = (v: number) => {
    setAccuracy(v);
  };

  const handleUpdateAccuracy = (data: EditAccuracyPayload & { transaction_id: number }) => {
    updateAccuracy({ ...data, supplierId: supplier });
  };

  const columns = [
    {
      title: 'Product ID',
      dataIndex: 'id'
    },
    {
      title: 'Product Code',
      dataIndex: 'code'
    },
    {
      title: 'Product Name',
      dataIndex: 'name'
    },
    {
      title: 'Suspect Line Items',
      render: (record: ProductAccuracyModel) => {
        return record.accuracy.length;
      }
    }
  ];

  const expandedRowRender = (record: ProductAccuracyModel) => {
    const columns = [
      {
        title: 'Salesforce Deal Name',
        dataIndex: 'salesforce_deal_name'
      },
      {
        title: 'Quantity',
        dataIndex: 'capacity',
        render: (v: number) => <div className="text-right">{regionalCurrencyFormatter(v)}</div>,
        align: 'right'
      },
      {
        title: 'Unit Quantity',
        dataIndex: 'unit_quantity',
        render: (v: number) => <div className="text-right">{v ? regionalCurrencyFormatter(v) : '-'}</div>,
        align: 'right'
      },
      {
        title: 'Unit Cost',
        dataIndex: 'unit_cost',
        render: (v: number) => <div className="text-right">{regionalCurrencyFormatter(v, 'currency')}</div>,
        align: 'right'
      },
      {
        title: 'TCV',
        dataIndex: 'tcv',
        render: (v: number) => <div className="text-right">{regionalCurrencyFormatter(v, 'currency')}</div>,
        align: 'right'
      },
      {
        title: 'Unit Cost Predictions',
        children: [
          {
            title: 'By Quantity',
            dataIndex: 'predictedUnitCostQuantity',
            render: (v: number) => <div className="text-right">{v ? `$${v.toFixed(2)}` : '-'}</div>,
            align: 'right'
          },
          {
            title: 'By TCV',
            dataIndex: 'predictedUnitCostTcv',
            render: (v: number) => <div className="text-right">{v ? `$${v.toFixed(2)}` : '-'}</div>,
            align: 'right'
          },
          {
            title: 'Variation',
            dataIndex: 'variationInPercentage',
            render: (v: number, _record: Accuracy) => {
              const prefix = _record.unit_cost > _record.predictedUnitCostQuantity ? '+' : '-';
              const color = _record.unit_cost > _record.predictedUnitCostQuantity ? 'red' : 'green';
              return (
                <div
                  className="text-right"
                  style={{
                    color
                  }}
                >
                  {v ? `${prefix}${v}%` : '-'}
                </div>
              );
            },
            defaultSortOrder: 'ascend',
            sorter: (
              a: Accuracy & { variationInPercentage: number },
              b: Accuracy & {
                variationInPercentage: number;
              }
            ) => {
              if (a.variationInPercentage && b.variationInPercentage) {
                if (a.variationInPercentage === b.variationInPercentage) {
                  const aVariation = a.unit_cost > a.predictedUnitCostQuantity ? 1 : -1;
                  const bVariation = b.unit_cost > b.predictedUnitCostQuantity ? 1 : -1;

                  if (aVariation === bVariation) {
                    return 0;
                  }
                  return aVariation > bVariation ? -1 : 1;
                }
              }
              return a.variationInPercentage - b.variationInPercentage;
            },
            align: 'right'
          }
        ]
      },
      {
        title: 'Actions',
        render: (_: any, _record: Accuracy) => {
          return (
            <Button
              type="link"
              onClick={() => {
                const { accuracy: _accuracy, ...rest } = record;
                setDealToEdit(_record);
                setProductToEdit(rest);
              }}
            >
              <EditOutlined />
            </Button>
          );
        }
      }
    ] as ColumnType<Accuracy>[];

    return <Table columns={columns} dataSource={record.accuracy} pagination={false} bordered />;
  };

  const renderEditModal = () => (
    <>
      {dealToEdit && productToEdit ? (
        <EditAccuracy
          accuracy={dealToEdit}
          product={productToEdit}
          updateAccuracy={handleUpdateAccuracy}
          onCancel={() => {
            setDealToEdit(undefined);
            setProductToEdit(undefined);
          }}
        />
      ) : null}
    </>
  );

  return (
    <div>
      <h1>Product Accuracy</h1>

      <Row gutter={24} className="pb-8">
        <Col>
          <VendorsSearch placeholder="Search for a supplier" onChange={handleVendorChange} />
        </Col>
        <Col>
          <Select size={'large'} onChange={handleAccuracyMargin} placeholder="Select Accuracy" value={accuracy}>
            <Select.Option value={15}>Accuracy +-15%</Select.Option>
            <Select.Option value={25}>Accuracy +-25%</Select.Option>
            <Select.Option value={30}>Accuracy +-30%</Select.Option>
            <Select.Option value={50}>Accuracy +-50%</Select.Option>
          </Select>
        </Col>
      </Row>
      {!supplier || !productsWithVariation?.length ? (
        isInitialLoading ? (
          <Spin />
        ) : (
          <Empty description={!supplier ? 'Please select a supplier to view accuracy data' : undefined} />
        )
      ) : (
        <Table dataSource={productsWithVariation} rowKey="id" columns={columns} expandable={{ expandedRowRender }} />
      )}
      {renderEditModal()}
    </div>
  );
};

export default ProductAccuracy;
