import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  Col,
  Comment,
  Empty,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Typography
} from 'antd';
import { createDealNote, deleteDealNote, fetchDealNotes, updateDealNote } from 'api/contract';
import { DealNote } from 'models/Deal';
import nl2br from 'react-nl2br';
import { useRecoilValue } from 'recoil';
import { myState } from 'recoil/atoms';
import { ACLCheck, getCommentDateTime } from 'shared/Helpers';
import { ArrowRightOutlined, DeleteOutlined, EditOutlined, StarFilled } from '@ant-design/icons';
import { FormInstance } from 'antd/es/form';
import SelectReusable from '../shared/SelectReusable';
import {
  createSupplierNote,
  deleteSupplierNote,
  fetchSupplierNotes,
  updateSupplierNote
} from '../../api/supplier_dynamics';

interface props {
  contract_id?: number;
  supplier_id?: number;
}

const DealNotes: FC<props> = ({ contract_id, supplier_id }) => {
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<DealNote[]>();
  const [replyId, setReplyId] = useState<number | undefined>(undefined);
  const [editNoteId, setEditNoteId] = useState<number | undefined>(undefined);

  const [editNoteForm] = Form.useForm();
  const [noteForm] = Form.useForm();
  // RECOIL STATE
  const me = useRecoilValue(myState);
  const isAnalyst = ACLCheck(['read:smartbench_access']);

  const formInitialValues = {
    privacy: 'private'
  };

  const appendParentPrivacyToReplies = (replies: any[], parentPrivacy: boolean) => {
    return replies.map((reply) => ({
      ...reply,
      parentPrivacy: parentPrivacy
    }));
  };

  const handleRefetchNotes = useCallback(async () => {
    const appendParentPrivacy = (notes: any[]) => {
      return notes.map((note) => ({
        ...note,
        replies: appendParentPrivacyToReplies(note?.replies, note?.is_visible_to_npi)
      }));
    };
    setLoading(true);
    const allNotes = supplier_id
      ? await fetchSupplierNotes(supplier_id as number)
      : await fetchDealNotes(contract_id as number);
    setNotes(appendParentPrivacy(allNotes));
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract_id, supplier_id]);

  useEffect(() => {
    if (!contract_id && !supplier_id) return;
    handleRefetchNotes().then();
  }, [contract_id, supplier_id, handleRefetchNotes]);

  useEffect(() => {
    if (notes && notes.length > 0) {
      scrollToLastNote();
    }
  }, [notes]);

  const scrollToLastNote = () => {
    const element = document && document.getElementById('deal-notes');
    if (element && element.lastChild) {
      const lastChild = element.lastChild as HTMLDivElement;
      lastChild.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    }
  };

  const handleAddNote = async (newComment: string, privacyValue: string) => {
    if (contract_id || supplier_id) {
      let result;
      if (contract_id) {
        result = await createDealNote(contract_id, {
          note: newComment.trim(),
          parent_id: undefined,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      } else if (supplier_id) {
        result = await createSupplierNote(supplier_id, {
          note: newComment.trim(),
          parent_id: undefined,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      }
      if (result) {
        setEditNoteId(undefined);
        setReplyId(undefined);
        noteForm.resetFields();
        await handleRefetchNotes();
        await message.success('Note created successfully!');
      }
    }
  };

  const handleReplyToNote = async (newReply: string, privacyValue: string) => {
    if ((contract_id || supplier_id) && replyId) {
      let result;
      if (contract_id) {
        result = await createDealNote(contract_id, {
          note: newReply,
          parent_id: replyId,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      } else if (supplier_id) {
        result = await createSupplierNote(supplier_id, {
          note: newReply,
          parent_id: replyId,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      }
      if (result) {
        setReplyId(0);
        noteForm.resetFields();
        await handleRefetchNotes();
        await message.success('Reply added successfully!');
      }
    }
  };

  const handleDeleteNote = async (noteId: number) => {
    if (contract_id || supplier_id) {
      let result;
      if (contract_id) {
        result = await deleteDealNote(contract_id, noteId);
      } else if (supplier_id) {
        result = await deleteSupplierNote(supplier_id, noteId);
      }
      if (result) {
        await handleRefetchNotes();
        await message.success('Note has been deleted!');
      }
    }
  };

  const handleEditNote = async (newNote: string, privacyValue: string) => {
    if ((contract_id || supplier_id) && editNoteId && newNote) {
      let result;
      if (contract_id) {
        result = await updateDealNote(contract_id, editNoteId, {
          note: newNote,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      } else if (supplier_id) {
        result = await updateSupplierNote(supplier_id, editNoteId, {
          note: newNote,
          is_visible_to_npi: isAnalyst || privacyValue !== 'private'
        });
      }
      if (result) {
        setEditNoteId(undefined);
        noteForm.resetFields();
        await handleRefetchNotes();
        await message.success('Note has been updated!');
      }
    }
  };

  const noteReply = (reply: any) => {
    const initial = reply?.user?.name ? reply.user?.name.substring(0, 1) : '-';
    return (
      <Comment
        key={reply.id}
        content={
          <div className="rounded-[0.5rem] px-4 py-3 bg-gray-100">
            <div className="text-xs opacity-[0.5] flex gap-2 text-small justify-between">
              <div className="flex gap-2">
                <span>{reply.user.user_id === me?.user_id ? me?.name : reply.user.name || reply.user.given_name}</span>
                <span>{getCommentDateTime(reply.creation_date)}</span>
              </div>
              <div className="text-red-700 text-right">
                {!reply.is_visible_to_npi && (
                  <>
                    <StarFilled />
                    {' ' + 'Private Note'}
                  </>
                )}
              </div>
            </div>

            {editNoteId === reply?.id ? (
              <div className={'w-full'}>{renderEditNote(reply)}</div>
            ) : (
              <div className="pt-1 leading-tight">{nl2br(reply.note)}</div>
            )}

            <div className="flex gap-4 mt-4">
              {reply.replies ? (
                <Button type="link" className="p-0 leading-tight h-auto  text-xs" onClick={() => setReplyId(reply.id)}>
                  <ArrowRightOutlined style={{ fontSize: '12px' }} />
                  Reply
                </Button>
              ) : null}
              {reply.user.user_id === me?.user_id && (
                <span className={'space-x-2'}>
                  <Button
                    type="link"
                    className="p-0 leading-tight h-auto text-xs"
                    onClick={() => {
                      setEditNoteId(reply.id);
                    }}
                  >
                    <EditOutlined style={{ fontSize: '12px' }} />
                  </Button>
                  <Popconfirm
                    title="Are you sure you wish to delete this comment?"
                    onConfirm={() => handleDeleteNote(reply.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="link" className="p-0 leading-tight h-auto  text-xs">
                      <DeleteOutlined style={{ fontSize: '12px' }} />
                    </Button>
                  </Popconfirm>
                </span>
              )}
            </div>
            {replyId === reply?.id ? <div className={'w-full'}>{addReplyToNote(reply)}</div> : null}
          </div>
        }
        avatar={
          reply.user.user_id === me?.user_id ? (
            <Avatar className="mt-2">
              {me?.given_name.charAt(0)}
              {me?.family_name.charAt(0)}
            </Avatar>
          ) : (
            <Avatar className="mt-2">{initial}</Avatar>
          )
        }
      >
        {reply?.replies?.map(noteReply)}
      </Comment>
    );
  };

  const addReplyToNote = (reply: any) => {
    editNoteForm.setFieldValue('note', '');
    editNoteForm.setFieldValue('privacy', 'private');
    return renderNoteForm(
      'edit',
      editNoteForm,
      'Add New Reply',
      !reply?.is_visible_to_npi,
      () => {
        handleReplyToNote(editNoteForm.getFieldValue('note'), editNoteForm.getFieldValue('privacy')).then();
      },
      () => {
        setReplyId(undefined);
      }
    );
  };

  const renderEditNote = (note: DealNote) => {
    const disableShareWithNPI = note?.replies == undefined ? !note?.parentPrivacy : false;
    editNoteForm.setFieldValue('note', note.note);
    editNoteForm.setFieldValue('privacy', 'private');
    return renderNoteForm(
      'edit',
      editNoteForm,
      '',
      disableShareWithNPI,
      () => {
        handleEditNote(editNoteForm.getFieldValue('note'), editNoteForm.getFieldValue('privacy')).then();
      },
      () => {
        setEditNoteId(undefined);
      }
    );
  };

  const getPrivacyMenuOptions = (disableShareWithNPI: boolean) => {
    return [
      {
        label: (
          <Row gutter={4}>
            <Col span={25} className="text-wrap">
              <div className="text-base mt-1">Private Note</div>
              <div className="hint text-wrap">
                This note and subsequent replies will only be visible to users from your organization
              </div>
            </Col>
          </Row>
        ),
        value: 'private'
      },
      {
        label: (
          <Row gutter={4}>
            <Col span={25}>
              <div className="text-base mt-1">Share with NPI</div>
              <div className="hint text-wrap">This note and subsequent replies will be visible to NPI</div>
            </Col>
          </Row>
        ),
        value: 'public',
        disabled: disableShareWithNPI
      }
    ];
  };

  /**
   * Renders a form for adding or editing a note.
   * @param {string} mode - The mode of the form ('add' or 'edit').
   * @param {FormInstance} noteForm - The form instance for the note.
   * @param {string} label - The label for the form.
   * @param {boolean} disableShareWithNPI - Whether to disable sharing with NPI.
   * @param {() => void} submitHandler - The handler for the form submit event.
   * @param {(() => void) | undefined} cancelHandler - The optional handler for the form cancel event.
   * @returns {React.ReactElement} - The rendered form component.
   */
  const renderNoteForm = (
    mode: 'add' | 'edit',
    noteForm: FormInstance,
    label: string,
    disableShareWithNPI: boolean,
    submitHandler: () => void,
    cancelHandler?: (() => void) | undefined
  ): React.ReactElement => {
    return (
      <div className={'mt-2'}>
        <Form name="genericNoteForm" form={noteForm} onFinish={submitHandler} initialValues={formInitialValues}>
          <div>
            <Row className={'w-full'}>
              <Form.Item
                label={label ? <span className={'font-bold'}>{label}</span> : null}
                name="note"
                className={'w-full'}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value && value.trim()) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Note is a required field');
                    }
                  }
                ]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input.TextArea size="large" rows={3} placeholder="Make a note" />
              </Form.Item>
            </Row>

            <Row justify="end" className={'w-full gap-3'}>
              {!isAnalyst && (
                <Col>
                  <Form.Item
                    name="privacy"
                    className="w-64 justify-self-start"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value && value.trim()) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Please select privacy type');
                        }
                      }
                    ]}
                  >
                    <SelectReusable options={getPrivacyMenuOptions(disableShareWithNPI)} defaultValue="private" />
                  </Form.Item>
                </Col>
              )}
              <Col>
                <Space size="small">
                  {mode === 'add' ? (
                    <Button type="link" onClick={() => noteForm.resetFields()}>
                      Clear
                    </Button>
                  ) : (
                    cancelHandler && (
                      <Button type="link" onClick={cancelHandler}>
                        Cancel
                      </Button>
                    )
                  )}
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <Card className="pt-4">
      <Row gutter={36}>
        <Skeleton loading={loading} active>
          <Col span={17}>
            <Typography.Title level={4}>Notes</Typography.Title>
            <div id="deal-notes" className="max-h-[50vh] overflow-auto">
              {notes && notes?.length > 0 ? notes?.map(noteReply) : <Empty description="No notes to display." />}
            </div>
          </Col>
          <Col span={7}>
            {renderNoteForm(
              'add',
              noteForm,
              'Add New Note',
              false,
              () => {
                // noinspection JSIgnoredPromiseFromCall
                handleAddNote(noteForm.getFieldValue('note'), noteForm.getFieldValue('privacy'));
              },
              undefined
            )}
          </Col>
        </Skeleton>
      </Row>
    </Card>
  );
};

export default DealNotes;
