import { Button, Form, FormProps, Input, Checkbox, Space, DatePicker } from 'antd';

interface Props extends FormProps {
  loading?: boolean;
  onCancel?: () => void;
}
export const ProductForm: React.FC<Props> = ({ loading, onCancel, onFinish, ...rest }) => {
  return (
    <Form onFinish={onFinish} layout="vertical" size="middle" requiredMark={false} {...rest}>
      <Form.Item name="name" label="Product Name" rules={[{ required: true, message: 'Please input product name' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="code" label="Product Code">
        <Input />
      </Form.Item>
      <Form.Item name="end_of_support_date" label="End of Support Date">
        <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" placeholder="Enter Support date" />
      </Form.Item>
      <Form.Item name="end_of_life_date" label="End of Life Date">
        <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" placeholder="Enter Life date" />
      </Form.Item>
      <Form.Item name="unit_of_measure" label="Unit of Measure">
        <Input />
      </Form.Item>
      <Form.Item name="is_indicator_sku" valuePropName="checked">
        <Checkbox>Is Indicator Sku</Checkbox>
      </Form.Item>

      <Form.Item className="text-right">
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
