import { Button, Table, TableColumnsType, TableProps, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useScopes } from 'hooks/useScopes';
import { Reseller } from 'sb/models/Reseller';
import { FieldSorter } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';

interface Props extends TableProps<Reseller> {
  onEdit?: (item: Reseller) => void;
}

export const ResellerTable: React.FC<Props> = ({ onEdit, ...rest }) => {
  const { aclCheck } = useScopes();

  const columns: TableColumnsType<Reseller> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Reseller, b: Reseller) => FieldSorter(a.name, b.name),
      render: (_, rec: Reseller) => rec.name || '--'
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Reseller, b: Reseller) => FieldSorter(a.symbol, b.symbol),
      render: (_, rec: Reseller) => rec.symbol || '--'
    },
    {
      title: 'Year End',
      dataIndex: 'fiscal_year_end',
      sortDirections: ['descend', 'ascend'],
      sorter: (a: Reseller, b: Reseller) => FieldSorter(a.fiscal_year_end, b.fiscal_year_end),
      render: (_, rec: Reseller) => {
        const date = moment(rec.fiscal_year_end, 'MM-DD', true);
        return date.isValid() ? date.format('MMM DD') : '--';
      }
    },
    {
      title: 'Salesforce ID',
      dataIndex: 'salesforce_id',
      sortDirections: ['ascend', 'descend'],
      sorter: (a: Reseller, b: Reseller) => a.salesforce_id?.localeCompare(b.salesforce_id),
      render: (_, rec: Reseller) => rec.salesforce_id || '--'
    },
    {
      title: 'Profile',
      dataIndex: 'profile'
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: aclCheck(['update:suppliers_resellers']) ? 70 : 1,
      render: (_: string, rec: Reseller) => {
        if (aclCheck(['update:suppliers_resellers'])) {
          return (
            <Tooltip title="Click to edit row">
              <Button type="link" size="small" icon={<EditOutlined />} onClick={() => onEdit?.(rec)} />
            </Tooltip>
          );
        }
      }
    }
  ];

  return <Table rowKey="id" size="small" {...rest} columns={columns} pagination={DEFAULT_TABLE_PAGINATION_CONFIG} />;
};
