import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';

export async function getProductOutliers(id: number) {
  try {
    const res = await axios.get(`/secure/product/${id}/outliers`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function getProductContracts(id: number) {
  try {
    const res = await axios.get(`/secure/product/${id}/contracts`);
    if (res.data?.status === 'error') {
      return Promise.reject(Error(res.data?.message));
    }
    return res.data?.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Add list price for a product
 *
 * @param product_id
 * @param list_price
 */
export async function addProductListPriceCustom(
  product_id: number,
  list_price: { list_price: number; comment: string }
) {
  try {
    const res = await axios.post(`/secure/product/${product_id}/list_price`, list_price);
    if (res.data?.status === 'error') {
      return Promise.reject(Error(res.data?.message));
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch product list prices
 *
 * @param product_id
 */
export const getProductListPrices = async (product_id: number) => {
  try {
    const res = await axios.get(`/secure/product/${product_id}/list_prices`);
    if (res.data?.status === 'error') {
      return Promise.reject(Error(res.data?.message));
    }
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Delete list price
 *
 * @param id
 */
export const deleteProductListPrice = async (id: number) => {
  try {
    const res = await axios.delete(`/secure/product/list_price/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Get product comps
 *
 * @param product_id
 */
export const getProductComps = async (product_id: number) => {
  try {
    const res = await axios.get(`/secure/product/${product_id}/comps`);
    return res.data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch Products linked to a given product
 *
 * @param id
 */
export async function fetchLinkedProducts(id: number) {
  try {
    const res = await axios.get(`/secure/product/${id}/linked_products`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Products linked to a given product
 *
 * @param str
 * @param inContract
 * @param vendor_id
 */
export async function searchProducts(str: string, inContract?: string, vendor_id?: number) {
  try {
    const res = await axios.get(`/secure/product/search`, {
      params: {
        str: str,
        inContract: inContract,
        vendor_id: vendor_id
      }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Searches for products associated with a specific vendor based on a search string.
 *
 * @param {string} str - The search string used to find matching products.
 * @param {number} supplier_id - The unique identifier of the vendor whose products are to be searched.
 * @return {Promise<any>} A promise that resolves to the search results if successful, or an error message if the search fails.
 */
export async function searchSupplierProducts(str: string, supplier_id: number): Promise<any> {
  try {
    const res = await axios.get(`/secure/supplier/${supplier_id}/products/search`, { params: { str } });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch supplier products
 *
 * @param {number} id - vendor ID
 * @param str
 */
export async function fetchSupplierProducts(id: number, str?: string) {
  try {
    const res = await axios.get(`/secure/supplier/${id}/products`, {
      params: { str }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update Linked Products
 *
 * @param id
 * @param productIds
 * @param name
 */
export async function updateGroupedProducts(id: number, productIds: number[], name: string) {
  try {
    const res = await axios.patch(`/secure/product_group/${id}`, { productIds, name });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Insert Linked Products
 *
 * @param productIds
 * @param name
 */
export async function insertGroupedProducts(productIds: number[], name: string) {
  try {
    const res = await axios.post(`/secure/product_group`, { productIds, name });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Delete Linked Products
 *
 * @param id
 */
export async function deleteProductGroup(id: number) {
  try {
    const res = await axios.delete(`/secure/product_group/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Delete Linked Products
 */
export async function selectTaxonomy() {
  try {
    const res = await axios.get(`/secure/taxonomy`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update secondary categories for an array of products
 *
 * @param product_ids
 * @param category_ids
 */
export async function updateProductsCategories(product_ids: number[], category_ids: number[]) {
  try {
    const res = await axios.post(`/secure/products/categories`, {
      product_ids,
      category_ids
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
