export enum PermissionMethods {
  read = 'read',
  create = 'create',
  update = 'update',
  delete = 'delete'
}

export const AllPermissionMethods = [
  PermissionMethods.create,
  PermissionMethods.read,
  PermissionMethods.update,
  PermissionMethods.delete
];

export interface Permission extends Record<PermissionMethods, number> {
  id: string;
  name: string;
}
