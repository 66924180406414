import React from 'react';
import './DealCreationSuccess.scss';
import { DealCreationSuccessMessage } from './DealCreationSuccessMessage';
import { DealSteps } from './DealSteps';
import { DealActions } from './DealActions';
import { useParams } from 'react-router-dom';

export const DealCreationSuccess: React.FC = () => {
  const { contract_id } = useParams();
  return (
    <div className="products-search">
      <DealCreationSuccessMessage />
      <DealActions contract_id={Number(contract_id)} />
      <span className="font-bold m-5">You are one step closer!</span>
      <DealSteps />
    </div>
  );
};
