import { Button, Table, TableColumnsType, TableProps } from 'antd';
import moment from 'moment';

// import { Contract } from 'sb/models/Contract';
import { ContractByProduct } from 'sb/models/Product';
import { FormatCurrency } from 'sb/shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';

interface Props extends TableProps<ContractByProduct> {
  onContractClick?: (contract: ContractByProduct) => void;
}

export const ContractsByProductTable: React.FC<Props> = ({ onContractClick, ...rest }) => {
  const columns: TableColumnsType<ContractByProduct> = [
    {
      title: 'Deal Id',
      dataIndex: 'contract_code',
      width: 225,
      render: function render(_, rec: ContractByProduct) {
        return (
          <Button type="link" onClick={() => onContractClick?.(rec)}>
            {rec.salesforce_deal_name}
          </Button>
        );
      }
    },
    {
      title: 'Renewal Date',
      dataIndex: 'contract_renewal_date',
      render: (_, rec: ContractByProduct) =>
        rec.contract_renewal_date ? moment(rec.contract_renewal_date).format('ll') : '--'
    },
    {
      title: 'Avg Unit Cost',
      align: 'center',
      dataIndex: 'avg_unit_cost',
      sorter: (a: ContractByProduct, b: ContractByProduct) => a.avg_unit_cost - b.avg_unit_cost,
      sortDirections: ['descend', 'ascend'],
      defaultSortOrder: 'descend',
      render: (_, rec: ContractByProduct) => FormatCurrency(rec.avg_unit_cost)
    },
    {
      title: 'Unique SKUs',
      dataIndex: 'product_count',
      align: 'right'
    },
    {
      title: 'TCV',
      align: 'right',
      dataIndex: 'total_value',
      render: (_, rec: ContractByProduct) => FormatCurrency(rec.tcv, 0)
    }
  ];

  return (
    <Table
      {...rest}
      scroll={{ y: 400 }}
      rowKey="contract_id"
      className="line-items sm-header"
      columns={columns}
      pagination={{
        ...DEFAULT_TABLE_PAGINATION_CONFIG,
        position: ['topRight'],
        showTotal: (total) => `Count: ${total}`
      }}
    />
  );
};
