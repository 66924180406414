import { useEffect, useRef, useState } from 'react';
import { Collapse, CollapseCallbackArgs, UnmountClosed } from 'react-collapse';
import { Button, Checkbox, Col, Divider, Input, InputRef, message, Modal, Row, Space, Typography } from 'antd';
import { VendorsSearch } from 'components/atoms/VendorsSearch';
import { ContractsByProductTable } from 'components/atoms/ContractsByProductTable';
import { ProductTable } from 'components/atoms/ProductTable';
import { ProductCategoriesModal } from 'components/atoms/ProductCategoriesModal';
import { ProductMergeModal } from 'components/atoms/ProductMergeModal';
import { getVendor, mergeVendorProducts, updateVendorProduct, updateVendorProductConfirmed } from 'sb/api/vendor';
import { fetchSupplierProducts, getProductContracts } from 'sb/api/products';
import { useRecoilValue } from 'recoil';
import { refreshProductsTableState, uiState } from 'recoil/atoms';
import { RefSelectProps } from 'antd/lib/select';
import { animateScroll } from 'react-scroll';
import { scrollOptions } from 'shared/constants';
import { SupplierProductRow } from 'models/Product';
import { SearchOutlined } from '@ant-design/icons';
import { escapeRegExp } from 'lodash-es';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const thisMenuKey = 'products';

const Products: React.FC = () => {
  const filterRef = useRef<InputRef>(null);
  const vendorSearchRef = useRef<RefSelectProps>(null);
  const searchProductsRef = useRef<InputRef>(null);
  const selectedProductCategories = useRef<any[]>([]);

  // LOCAL STATE
  const [vendorId, setVendorId] = useState<number>();
  const [vendorName, setVendorName] = useState('');
  const [unverifiedOnly, setUnverifiedOnly] = useState(false);
  const [markerSkuOnly, setMarkerSkuOnly] = useState(false);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [mergingProducts, setMergingProducts] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [openMergeModal, setOpenMergeModal] = useState(false);

  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Array<SupplierProductRow>>([]);
  const [productsTable, setProductsTable] = useState<SupplierProductRow[]>([]);
  const [_productsTable, _setProductsTable] = useState<SupplierProductRow[]>([]);

  const [searchString, setSearchString] = useState<undefined | string>(undefined);
  const [filterString, setFilterString] = useState<undefined | string>(undefined);

  const [retrieveLinkedProductsOnly, setRetrieveLinkedProductsOnly] = useState(true);
  const [searchCategories, setSearchCategories] = useState(true);

  // Recoil state to trigger refresh of products table
  const refreshProductsTable = useRecoilValue(refreshProductsTableState);
  const { menuKey } = useRecoilValue(uiState);

  useEffect(() => {
    getProductsTable().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshProductsTable]);

  useEffect(() => {
    if (menuKey === thisMenuKey) {
      vendorSearchRef.current?.focus();
      animateScroll.scrollToTop(scrollOptions);
    }
  }, [menuKey]);

  useEffect(() => {
    if (!searchString || searchString.length < 2 || !vendorId) return;

    (async () => {
      const productsTable: SupplierProductRow[] | undefined = await fetchSupplierProducts(vendorId, searchString);
      if (productsTable) {
        setProductsTable(productsTable);
        _setProductsTable(productsTable);
        filterRef.current?.focus();
      }
      return () => {
        Modal.destroyAll();
      };
    })();
  }, [searchString, refreshProductsTable, vendorId]);

  /**
   * Handles the change of a vendor.
   *
   * @param {number} [_vendorId] - The ID of the vendor. Optional.
   * @returns {Promise<void>} - Returns a promise that resolves with nothing.
   */
  const handleVendorChange = async (_vendorId?: number): Promise<void> => {
    let _vendorName = '';
    if (_vendorId) {
      const res = await getVendor(_vendorId);
      _vendorName = res.name;

      try {
        setLoadingProducts(true);
        const productsTable: SupplierProductRow[] | undefined = await fetchSupplierProducts(_vendorId);
        if (productsTable) {
          setProductsTable(productsTable);
          _setProductsTable(productsTable);
          filterRef.current?.focus();
          setFilterString(undefined);
        }
      } finally {
        setLoadingProducts(false);
      }
    } else {
      setProductsTable([]);
      _setProductsTable([]);
      setTimeout(() => {
        vendorSearchRef.current?.focus();
      }, 100);
    }

    setSearchString(undefined);
    setRetrieveLinkedProductsOnly(true);

    setVendorId(_vendorId);
    setVendorName(_vendorName);
    setUnverifiedOnly(false);

    // searchProductsRef.current?.setValue('');
    searchProductsRef.current?.focus();
  };

  /**
   * Retrieves the products table for a specific vendor, asynchronously.
   *
   * @returns {Promise<void>} - A promise that resolves when the products table has been retrieved and set.
   *
   * @throws {Error} - Thrown if the vendor ID is not provided.
   */
  const getProductsTable = async (): Promise<void> => {
    if (!vendorId) return;

    try {
      setLoadingProducts(true);
      const productsTable: SupplierProductRow[] | undefined = await fetchSupplierProducts(vendorId);
      if (productsTable) {
        setProductsTable(productsTable);
        _setProductsTable(productsTable);
      }
    } finally {
      setLoadingProducts(false);
    }
  };

  // const getUnconfirmedProducts = async () => {
  //   if (!vendorId) {
  //     message.warning('Please select supplier');
  //     return;
  //   }
  //
  //   setLoadingUnverifiedProducts(true);
  //   setUnverifiedOnly(true);
  //   // searchProductsRef.current?.setValue('');
  //
  //   setLoadingUnverifiedProducts(false);
  // };

  /**
   * Updates the confirmation status of a supplier product row.
   *
   * @param {SupplierProductRow} rec - The supplier product row to update.
   * @returns {Promise<void>} - A promise that resolves when the update is complete.
   */
  const handleChangeIsConfirmed = async (rec: SupplierProductRow): Promise<void> => {
    const temp: SupplierProductRow[] = [...productsTable];

    const res = await updateVendorProductConfirmed(rec.vendor_id, rec.id, !rec.is_confirmed);

    setProductsTable((prev: SupplierProductRow[]) =>
      prev.map((product: SupplierProductRow) =>
        product.id === rec.id ? { ...product, is_confirmed: res.is_confirmed } : product
      )
    );

    _setProductsTable((prev) =>
      prev.map((product: SupplierProductRow) =>
        product.id === rec.id ? { ...product, is_confirmed: res.is_confirmed } : product
      )
    );

    if (res) {
      message.success('Record updated');
    } else {
      setProductsTable(temp);
    }
  };

  /**
   * Sets the open state of the category modal and resets the selected product categories.
   * @returns {void}
   */
  const handleUpdateCategoriesClick = (): void => {
    setOpenCategoryModal(true);
    selectedProductCategories.current = [];
  };

  /**
   * Sets the state to open the category modal and update the selected products and categories.
   *
   * @param {SupplierProductRow} rec - The record of the supplier product.
   * @returns {void}
   */
  const handleEditCategories = (rec: SupplierProductRow): void => {
    setOpenCategoryModal(true);
    setSelectedProducts([rec]);
    selectedProductCategories.current = rec.product_category_second;
  };

  /**
   * Callback function for handling the cancellation of the category modal.
   * It sets the open state of the category modal to false and clears the selected product IDs.
   *
   * @returns {void}
   */
  const handleCategoryModalCancel = (): void => {
    setOpenCategoryModal(false);
    setSelectedProductIds([]);
  };

  /**
   * Toggles the value of `markerSkuOnly` variable.
   *
   * @function
   */
  const toggleMarkerSku = () => {
    setMarkerSkuOnly((prev) => !prev);
  };

  /**
   * Handles the saving of the category modal.
   *
   * @async
   * @returns {Promise<void>} - A Promise that resolves once the category modal is saved.
   */
  const handleCategoryModalSave = async (): Promise<void> => {
    setOpenCategoryModal(false);

    if (!vendorId) {
      return;
    }

    const newProductsTable: SupplierProductRow[] | undefined = await fetchSupplierProducts(vendorId, searchString);
    if (newProductsTable) {
      _setProductsTable(newProductsTable);

      if (filterString) {
        const regExp = new RegExp(escapeRegExp(filterString), 'i');
        setProductsTable(
          newProductsTable.filter((item: SupplierProductRow) => {
            const categories = item.product_category_second.filter((second) =>
              second.category_second.name.match(regExp)
            );
            return item.code.match(regExp) || item.name.match(regExp) || categories.length > 0;
          })
        );
      } else {
        setProductsTable(newProductsTable);
      }
    }
    setSelectedProductIds([]);
  };

  /**
   * Handles merging of vendor products.
   * @param {any} values - The values to be passed for merging.
   * @returns {Promise<void>} - A promise that resolves to void.
   */
  const handleMerge = async (values: any): Promise<void> => {
    if (!vendorId) return;

    if (selectedProductIds?.length < 1) {
      message.error('Two or more products should be selected');
      return;
    }

    setMergingProducts(true);
    const res = await mergeVendorProducts(vendorId, {
      ...values,
      product_ids: selectedProductIds,
      is_confirmed: +values.is_confirmed
    });
    if (res) {
      message.success('Products successfully merged');
      await getProductsTable();

      setSelectedProductIds([]);
      setOpenMergeModal(false);
    }
    setMergingProducts(false);
  };

  /**
   * Updates a product and its details in the vendor's system.
   *
   * @param {any} rec - The existing product record.
   * @param {any} values - The updated values for the product.
   *
   * @returns {Promise<void>} - A Promise that resolves to void.
   */
  const handleProductUpdate = async (rec: any, values: any): Promise<void> => {
    if (!vendorId) return;
    if (
      rec.code === values.code &&
      rec.name === values.name &&
      rec.is_indicator_sku === values.is_indicator_sku &&
      rec.end_of_life_date === values.end_of_life_date &&
      rec.end_of_support_date === values.end_of_support_date &&
      rec.unit_of_measure === values.unit_of_measure
    )
      return;

    const res = await updateVendorProduct(vendorId, rec.id, values);
    if (res) {
      message.success('Product is successfully updated');
      const table = (prev: SupplierProductRow[]) =>
        prev.map((item: SupplierProductRow) =>
          item.id === rec.id
            ? {
                ...item,
                name: values.name,
                code: values.code,
                code_name: `(${values.code}) ${values.name}`,
                is_indicator_sku: values.is_indicator_sku,
                end_of_life_date: values.end_of_life_date,
                end_of_support_date: values.end_of_support_date,
                unit_of_measure: values.unit_of_measure
              }
            : item
        );
      setProductsTable(table);
      _setProductsTable(table);
    }
  };

  /**
   * Handles the click event for a contract.
   *
   * @param {any} rec - The record associated with the contract.
   * @returns {Promise<void>} - Promise that resolves with void when handling is completed.
   */
  const handleContractClick = async (rec: any): Promise<void> => {
    const res = await getProductContracts(rec.id);

    Modal.info({
      icon: null,
      closable: true,
      width: 860,
      okText: 'Close',
      title: 'Contracts Including this Product',
      content: (
        <>
          <ContractsByProductTable
            size={'small'}
            dataSource={res}
            onContractClick={({ id }) => {
              window.open(`/sb/contract/${id}?step=4`, '_blank', 'noreferrer');
              Modal.destroyAll();
            }}
          />
        </>
      )
    });
  };

  /**
   * Updates the filter string and filters the table based on the input value.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event of the input element.
   * @returns {void}
   */
  const handleFilterTable = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setFilterString(escapeRegExp(value));

    filterTable(value);
  };

  /**
   * Filters the products table based on the given value.
   *
   * @param {string | undefined} value - The value to filter the table by.
   * @returns {void}
   */
  const filterTable = (value: string | undefined): void => {
    if (value) {
      const regExp = new RegExp(escapeRegExp(value), 'i');

      setProductsTable(
        _productsTable.filter((item: SupplierProductRow) => {
          if (searchCategories) {
            const categories = item.product_category_second.filter((second) =>
              second.category_second.name.match(regExp)
            );
            return item.code.match(regExp) || item.name.match(regExp) || categories.length > 0;
          } else {
            return item.code.match(regExp) || item.name.match(regExp);
          }
        })
      );
    } else {
      setProductsTable(_productsTable);
    }
  };

  useEffect(() => {
    if (!markerSkuOnly) {
      setProductsTable(_productsTable);
    } else {
      setProductsTable(_productsTable.filter((item) => item.is_indicator_sku));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerSkuOnly]);

  /**
   * Invoked when a change event occurs on linked products' checkbox.
   *
   * @param {CheckboxChangeEvent} e - The change event object.
   * @returns {void}
   */
  const handleLinkedProductsChange = (e: CheckboxChangeEvent): void => {
    setRetrieveLinkedProductsOnly(e.target.checked);
  };

  /**
   * Updates the state of searchCategories based on the value of the checkbox
   * and focuses on the filterRef element.
   *
   * @param {CheckboxChangeEvent} e - The event object triggered by the checkbox change.
   * @returns {void}
   */
  const handleSearchCategoriesOnlyChange = (e: CheckboxChangeEvent): void => {
    setSearchCategories(e.target.checked);
    filterRef.current?.focus();
  };

  return (
    <>
      <Typography.Title level={4}>Products</Typography.Title>
      <Row justify="space-between" align="bottom">
        <Col span={12}>
          <div className="text-xs">SUPPLIER SEARCH</div>
          <VendorsSearch
            ref={vendorSearchRef}
            placeholder="Search for a supplier"
            width={400}
            onChange={handleVendorChange}
          />
          <div className="mt-3">
            <Checkbox onChange={handleLinkedProductsChange} checked={retrieveLinkedProductsOnly}>
              Retrieve only products linked to contracts
            </Checkbox>
          </div>
        </Col>
        <Col>
          {/*<Button*/}
          {/*  type="primary"*/}
          {/*  size="large"*/}
          {/*  icon={<SelectOutlined />}*/}
          {/*  loading={loadingUnverifiedProducts}*/}
          {/*  onClick={getUnconfirmedProducts}*/}
          {/*>*/}
          {/*  Retrieve all unverified products*/}
          {/*</Button>*/}
        </Col>
      </Row>

      <Divider className="my-4" />

      <Row className="mb-4" justify="space-between" align="middle">
        <Col>
          <UnmountClosed
            isOpened={retrieveLinkedProductsOnly}
            onWork={(args: CollapseCallbackArgs) => {
              if (args.isFullyOpened) {
                filterRef.current?.focus();
              }
            }}
          >
            <Input
              placeholder="Filter by product code, name or category"
              prefix={<SearchOutlined />}
              ref={filterRef}
              allowClear
              value={filterString}
              onChange={handleFilterTable}
              style={{ width: 400 }}
              size="large"
              disabled={!vendorId}
            />
            <div className="mt-3">
              <Checkbox onChange={handleSearchCategoriesOnlyChange} checked={searchCategories} disabled={!vendorId}>
                Include Category(s) in filter
              </Checkbox>
            </div>
          </UnmountClosed>

          <UnmountClosed
            isOpened={!retrieveLinkedProductsOnly}
            onWork={(args: CollapseCallbackArgs) => {
              if (args.isFullyOpened) {
                searchProductsRef.current?.focus();
              }
            }}
          >
            <Input.Search
              ref={searchProductsRef}
              loading={loadingProducts}
              placeholder="Search by product name or SKU"
              style={{ width: 400 }}
              size="large"
              allowClear
              enterButton
              disabled={!vendorId}
              onSearch={setSearchString}
            />
          </UnmountClosed>
        </Col>
        {/*<Col>*/}
        {/*  <Space>*/}
        {/*    <>Include only Unverified Products in Search</>*/}
        {/*    <Switch checked={unverifiedOnly} onChange={setUnverifiedOnly} />*/}
        {/*  </Space>*/}
        {/*</Col>*/}
      </Row>

      <ProductTable
        title={() => (
          <Row justify="space-between" align="middle">
            <Col>{vendorName || <>&nbsp;</>}</Col>
            <Col>
              <Space>
                <Collapse isOpened={selectedProductIds?.length > 1}>
                  <Button onClick={() => setOpenMergeModal(true)}>Merge Products</Button>
                </Collapse>
                <Collapse isOpened={selectedProductIds?.length > 1}>
                  <Button onClick={handleUpdateCategoriesClick}>Update Categories</Button>
                </Collapse>
                <Button onClick={toggleMarkerSku}>{markerSkuOnly ? 'Show All' : 'Show Marker Sku Only'}</Button>
              </Space>
            </Col>
          </Row>
        )}
        size={'small'}
        loading={loadingProducts}
        dataSource={productsTable}
        rowSelection={{
          selectedRowKeys: selectedProductIds,
          onChange: (selectedRowKeys: React.Key[], selectedRows: Array<SupplierProductRow>) => {
            setSelectedProductIds(selectedRowKeys as number[]);
            setSelectedProducts(selectedRows);
          }
        }}
        className="line-items sm-font"
        showVendor={unverifiedOnly}
        onChangeIsConfirmed={handleChangeIsConfirmed}
        onEditCategories={handleEditCategories}
        onUpdateProduct={handleProductUpdate}
        onClickContract={handleContractClick}
      />

      <ProductMergeModal
        open={openMergeModal}
        confirmLoading={mergingProducts}
        onOk={handleMerge}
        onCancel={() => setOpenMergeModal(false)}
      />

      <ProductCategoriesModal
        selectedProducts={selectedProducts}
        open={openCategoryModal}
        width={800}
        onOk={handleCategoryModalSave}
        onCancel={handleCategoryModalCancel}
      />
    </>
  );
};

export default Products;
