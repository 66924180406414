import { Button, Cascader, Form, FormProps, Input, InputNumber, Space, Image, Row, Col, Radio } from 'antd';
import { useEffect, useState } from 'react';
import { getMonthDaySelectOptions } from 'sb/shared/Helpers';
import { UploadFile } from './UploadFile';
import './VendorForm.scss';
import { FileUploadResult } from 'sb/models/Files';
import placeholderLogo from 'assets/logo-placeholder.png';
import { updateSupplierLogoFilename } from 'sb/api/vendor';

interface Props extends FormProps {
  loading?: boolean;
  onCancel?: () => void;
  addOrEditVendor: boolean;
  vendor?: any;
  selectedVendor?: number;
}

type LogoFile = {
  url: string;
  fileName?: string;
};

export const VendorForm: React.FC<Props> = ({
  loading,
  onCancel,
  onFinish,
  addOrEditVendor,
  vendor,
  selectedVendor,
  ...rest
}) => {
  const [logoFile, setLogoFile] = useState<LogoFile | undefined>(undefined);

  useEffect(() => {
    if (vendor && vendor.logo_url && selectedVendor) {
      setLogoFile({ url: vendor.logo_url });
    } else if (!selectedVendor) {
      setLogoFile(undefined);
    }
  }, [vendor, selectedVendor]);

  const onSaveLogo = async (res: FileUploadResult) => {
    if (vendor?.id && res?.key && res?.signedUrl) {
      const logoResult = await updateSupplierLogoFilename(vendor?.id, res.key);
      if (logoResult) {
        setLogoFile({ url: logoResult.logo_url });
      }
    }
  };

  return (
    <Form
      onFinish={onFinish}
      disabled={!addOrEditVendor}
      // layout="vertical"
      size="middle"
      requiredMark={false}
      className={addOrEditVendor ? 'vendor-form enable-form text-lg' : 'vendor-form hide-appearance'}
      {...rest}
    >
      <Row gutter={[24, 16]} className="border-b" align="top">
        <Col flex="none" className="py-5 pt-0">
          <UploadFile
            buttonType="link"
            onUpload={onSaveLogo}
            className={'upload-btn upload-button ant-input w-fit max-w-52'}
            uploadLabel={
              <div className="text-l h-full p-2">
                <Image
                  style={{ maxHeight: 60 }}
                  preview={{ visible: false, mask: 'Click to update' }}
                  width={105}
                  src={vendor && logoFile && logoFile.url ? logoFile.url : placeholderLogo}
                  alt="Supplier Logo"
                />
              </div>
            }
          />
        </Col>
        <Col flex="auto" className="border-0 pt-0">
          <Form.Item
            name="name"
            label="Name"
            className={'hide-label !border-0 !pb-0 !pt-0'}
            rules={[{ required: true, message: 'Please input vendor name' }]}
          >
            <Input className="supplier-title" placeholder="Supplier Name" />
          </Form.Item>
          <Form.Item name="profile" className={'hide-label !mb-[-1px] !border-0'} label="Profile">
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} placeholder="Profile" />
          </Form.Item>
        </Col>
      </Row>
      <div className="grid grid-cols-2 gap-x-8">
        <Form.Item name="industry" label="Industry">
          <Input />
        </Form.Item>
        <Form.Item name="sub_industry" label="Sub-Industry">
          <Input />
        </Form.Item>
        <Form.Item name="domain" label="Domain">
          <Input />
        </Form.Item>
        <Form.Item name="symbol" label="Stock Symbol">
          <Input />
        </Form.Item>
        <Form.Item name="annual_revenue" label="Revenue">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item name="fiscal_year_end" label="Fiscal Year End">
          <Cascader options={getMonthDaySelectOptions()} displayRender={(label) => label?.join(' ')} />
        </Form.Item>
        <Form.Item name="salesforce_id" label="Salesforce ID">
          <Input />
        </Form.Item>
        <Form.Item name="is_pinpoint" label="Is Pinpoint">
          {!addOrEditVendor ? (
            <div>{vendor?.is_pinpoint ? 'Yes' : 'No'}</div>
          ) : (
            <Radio.Group>
              <Space direction="horizontal">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Space>
            </Radio.Group>
          )}
        </Form.Item>
      </div>

      <Form.Item className="text-right form-actions">
        <Space>
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
