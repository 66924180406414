import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';

/**
 * Fetch Supplier Deal Dynamics
 */
export async function fetchSupplierDealDynamics(id: string | number) {
  try {
    const res = await axios.get(`/secure/supplier/${id}/dealDynamics`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Insert Supplier Deal Dynamics records
 *
 * @param supplierDealDynamics
 * @param supplierDealDynamicsNotes
 */
export const insertSupplierDealDynamics = async (supplierDealDynamics: object, supplierDealDynamicsNotes: object) => {
  try {
    const res = await axios.post(`/secure/supplier/dynamics`, { supplierDealDynamics, supplierDealDynamicsNotes });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetch Deal Dynamics Supplier info for Administration page
 *
 * @param id
 */
export async function fetchDealDynamicsSupplierAdmin(id: string | number) {
  try {
    const res = await axios.get(`/secure/supplier/${id}/deal_dynamics/admin`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update supplier_deal_dynamic note
 *
 * @param id
 * @param body
 */
export async function updateSupplierDealDynamic(id: number, body: { is_accepted?: boolean; value?: number }) {
  try {
    const res = await axios.put(`/secure/supplier_deal_dynamic/${id}`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw new Error();
  }
}

/**
 * Delete Deal Dynamics Supplier record
 *
 * @param id
 */
export async function deleteSupplierDealDynamic(id: number) {
  try {
    const res = await axios.delete(`/secure/supplier_deal_dynamic/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update supplier_deal_dynamic_note record
 *
 * @param id
 * @param body
 */
export async function updateSupplierDealDynamicNote(id: number, body: { is_released?: boolean; note?: string }) {
  try {
    const res = await axios.put(`/secure/supplier_deal_dynamic_note/${id}`, body);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw new Error();
  }
}

/**
 * Delete Deal Dynamics Supplier Note record
 *
 * @param id
 */
export async function deleteSupplierDealDynamicNote(id: number) {
  try {
    const res = await axios.delete(`/secure/supplier_deal_dynamic_note/${id}`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
