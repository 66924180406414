import { useEffect, useState } from 'react';
import { Card, CardProps, Divider } from 'antd';
import 'components/deal-modal/DealModalSupplierDynamics.scss';
import { useRecoilValue } from 'recoil';
import { selectedSupplierDynamicsVendorState } from 'recoil/atoms';
import { SupplierTitle } from 'models/Supplier';
import { selectSupplierTitle } from 'api/vendor';
import SupplierCardTitle from 'components/supplier-dynamics/vendor-card-title/SupplierCardTitle';
import { DealDyanmics } from 'components/supplier-dynamics/deal-dynamics/DealDynamics';
import { useScopes } from 'hooks/useScopes';

/**
 * Renders the supplier dynamics modal.
 *
 * @param {CardProps} rest - The props to be spread to the underlying Card component.
 * @returns {React.ReactElement} - The rendered supplier dynamics modal.
 */
const ModalSupplierDynamics: React.FC<CardProps> = ({ ...rest }: CardProps): React.ReactElement => {
  const { aclCheck } = useScopes();
  // LOCAL STATE
  const [supplier, setSupplier] = useState<undefined | SupplierTitle>(undefined);

  // RECOIL STATE
  const selectedSupplier = useRecoilValue(selectedSupplierDynamicsVendorState);

  useEffect((): void => {
    if (!selectedSupplier) return;

    (async (): Promise<void> => {
      const supplier: undefined | SupplierTitle = await selectSupplierTitle(selectedSupplier.id);
      if (supplier) {
        setSupplier(supplier);
      }
    })();
  }, [selectedSupplier]);

  return (
    <Card
      // hoverable={true}
      title={
        selectedSupplier?.id ? (
          <div className="text-xl">
            Supplier Dynamics
            <Divider />
          </div>
        ) : (
          <SupplierCardTitle onSupplierUpdate={console.log} supplier={supplier} />
        )
      }
      {...rest}
    >
      <div className="deal-modal-supplier-dynamics">{aclCheck(['read:supplier_dynamics']) && <DealDyanmics />}</div>
    </Card>
  );
};

export default ModalSupplierDynamics;
