import React from 'react';
import { Button, Col, Form, FormProps, Image, Input, InputNumber, Row, Space } from 'antd';
import { UploadFile } from './UploadFile';
import { ClientManagementSummary } from 'sb/models/Client';
import placeholderLogo from 'assets/logo-placeholder.png';
import { FileUploadResult } from 'sb/models/Files';
import 'components/atoms/ClientForm.scss';

interface Props extends FormProps {
  loading?: boolean;
  onCancel?: () => void;
  onSaveLogo: (res: FileUploadResult) => void;
  client?: ClientManagementSummary;
  showLogo?: boolean;
}

export const ClientForm: React.FC<Props> = ({
  loading,
  onCancel,
  onFinish,
  client,
  onSaveLogo,
  disabled,
  showLogo,
  ...rest
}) => {
  return (
    <Form
      onFinish={onFinish}
      size="middle"
      requiredMark={false}
      disabled={disabled}
      className={disabled ? 'client-form hide-appearance' : 'client-form enable-form text-lg'}
      {...rest}
    >
      <Row gutter={[24, 16]} className="border-b">
        {showLogo && (
          <Col flex="none" className="py-5">
            <UploadFile
              buttonType="link"
              onUpload={onSaveLogo}
              className={'upload-btn upload-button ant-input'}
              uploadLabel={
                <div className="text-l">
                  <Image
                    style={{ maxHeight: 60 }}
                    preview={{ visible: false, mask: 'Click to update' }}
                    width={105}
                    src={client?.logo_url || placeholderLogo}
                    alt="Supplier Logo"
                  />
                </div>
              }
            />
          </Col>
        )}
        <Col flex="auto" className="py---5">
          <Form.Item
            name={['salesforce_account', 'Name']}
            label="Salesforce Name"
            className={'text-2xl !border-0 align-vertical p-0'}
            rules={[{ required: true, message: 'Please input name' }]}
          >
            <Input className="supplier-title" disabled />
          </Form.Item>
        </Col>
      </Row>
      {/*showing the file upload only for "edit" scenario and not "add" since the update logo api accepts a client id*/}

      <div className="grid grid-cols-2 gap-x-8">
        <Form.Item name="encrypted_name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="salesforce_id" label="Salesforce ID">
          <Input />
        </Form.Item>
        <Form.Item name="industry" label="Industry">
          <Input />
        </Form.Item>
        <Form.Item name="sub_industry" label="Sub Industry">
          <Input />
        </Form.Item>
        <Form.Item name="annual_revenue_millions" label="Revenue">
          <InputNumber className="w-full" />
        </Form.Item>
        <Form.Item name="encrypted_domain" label="Domain">
          <Input />
        </Form.Item>
        <Form.Item name="auth0_organization_id" label="Auth0 Organization ID">
          <Input />
        </Form.Item>
      </div>
      {!disabled ? (
        <Form.Item className="text-right form-actions">
          <Space>
            <Button type="default" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Save
            </Button>
          </Space>
        </Form.Item>
      ) : null}
    </Form>
  );
};
