import { message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { FILE_UPLOAD_SIZE_LIMIT } from './constants';
import millify from 'millify';

const validFileTypes = [
  'text/plain',
  'text/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
  'text/csv',
  'image/jpeg',
  'image/png',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/x-iwork-keynote-sffkey',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/x-iwork-numbers-sffnumbers',
  'application/zip',
  'application/x-zip-compressed'
];

const FileUploadValidator = (_file: RcFile, uploadedFileList: any[] = [], maxFiles = 5) => {
  const isAllowedType = validFileTypes.includes(_file.type);
  if (!isAllowedType) {
    message.error(`${_file.name} is not a Valid file type`).then();
    return false;
  }
  const isAllowedSize = _file.size < FILE_UPLOAD_SIZE_LIMIT;
  if (!isAllowedSize) {
    message.error(`${_file.name} exceeds the allowed size of ${millify(FILE_UPLOAD_SIZE_LIMIT)}`).then();
    return false;
  }
  if (_file.size === 0) {
    message.error(`${_file.name} is an empty file`).then();
    return false;
  }
  if (uploadedFileList.find((file) => file.name == _file.name)) {
    message.error(`${_file.name} is already attached.`).then();
    return false;
  }
  if (uploadedFileList.length >= maxFiles) {
    message.error(`You are allowed to upload only a maximum of ${maxFiles} files`).then();
    return false;
  }
  return true;
};

export default FileUploadValidator;
