import { TableColumnsType, Typography } from 'antd';
import { NoteStatus } from 'sb/models/Inquiry';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { User } from 'sb/models/User';

interface Props {
  npiUsers?: Array<User>;
}

export const InquiryStatusModalColumns = ({ npiUsers }: Props) => {
  const columns: TableColumnsType<NoteStatus> = useMemo(
    () => [
      {
        title: 'Status',
        dataIndex: 'status',
        render: (_, rec: NoteStatus) => rec.lov_status.name
      },
      {
        title: 'Assigned To',
        dataIndex: 'assigned_to',
        render: (_, rec: NoteStatus) => {
          if (npiUsers) {
            return rec.users_note_status_assigned_toTousers
              ? npiUsers.filter((user: User) => {
                  return user.user_id === rec.users_note_status_assigned_toTousers?.auth0_user_id;
                })[0].name
              : '--';
          } else {
            return '--';
          }
        }
      },
      {
        title: 'Remark',
        dataIndex: 'remark',
        render: (_, rec: NoteStatus) => (
          <Typography.Paragraph style={{ width: 200 }} ellipsis={{ rows: 1, expandable: true, symbol: 'more' }}>
            {rec.remark || '--'}
          </Typography.Paragraph>
        )
      },
      {
        title: 'Date',
        dataIndex: 'date_created',
        render: (_, rec: NoteStatus) => (rec.date_created ? moment(rec.date_created).utc().format('lll') : '--')
      }
    ],
    [npiUsers]
  );
  return columns;
};
