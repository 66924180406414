import { Form, FormProps, Button, Input, InputNumber, Radio, Select, Slider, Space } from 'antd';

import { MARKS, CHART_TYPES, DEAL_DYNAMIC_TYPES } from 'sb/shared/constants';
import { Dynamic } from 'sb/models/Dynamic';

interface Props extends FormProps<Dynamic> {
  saving?: boolean;
  onCancel?: () => void;
}

export const DealDynamicForm: React.FC<Props> = ({ saving, onCancel, ...rest }) => {
  return (
    <Form<Dynamic> layout="vertical" size="middle" initialValues={{ scale: [1, 5], weight: 1 }} {...rest}>
      <Form.Item label="Label" name="name" rules={[{ required: true, message: 'Please input label!' }]}>
        <Input placeholder="Enter label" />
      </Form.Item>

      <Form.Item label="Description" name="hint">
        <Input.TextArea placeholder="Enter description" />
      </Form.Item>

      <Form.Item
        label={'Supplier Dynamics Type'}
        name={'dynamics_type'}
        rules={[{ required: true, message: 'Required field!' }]}
      >
        <Select options={DEAL_DYNAMIC_TYPES} placeholder={'Select Dynamics Type'} />
      </Form.Item>

      <Form.Item label="Range" name="scale">
        <Slider range={true} min={-12} max={12} marks={MARKS} />
      </Form.Item>

      <Form.Item label="Rating order" name="rating_order">
        <Radio.Group className="mt-2" buttonStyle="solid">
          <Radio.Button value="ascending">Negative to Positive</Radio.Button>
          <Radio.Button value="descending">Positive to Negative</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="chart_type"
        rules={[{ required: true, message: 'Please select Chart Type!' }]}
        label="Chart Type"
      >
        <Select placeholder="Select chart type" allowClear>
          {CHART_TYPES.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="weight" label="Weighting">
        <InputNumber min={1} />
      </Form.Item>

      <br />
      <Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={saving}>
            Save
          </Button>
          <Button onClick={onCancel}>Cancel</Button>
        </Space>
      </Form.Item>
    </Form>
  );
};
