import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { useMutation } from '@tanstack/react-query';

/**
 * Fetch Deal Dynamic
 *
 * @param id
 */
export async function fetchDealDynamic(id: number) {
  try {
    const res = await axios.get(`/api/deal_dynamic/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Create supplier selection record
 *
 * @param supplier_id
 * @param supplier_category_id
 */
export async function insertSupplierSelection(supplier_id: number, supplier_category_id?: number) {
  try {
    const res = await axios.post(`/api/supplier/selection`, {
      supplier_id: supplier_id,
      supplier_category_id: supplier_category_id
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Supplier Deal Dynamic
 *
 * @param id
 * @param supplier_category_id
 */
export async function fetchSupplierDealDynamics(id: number, supplier_category_id?: number) {
  try {
    const res = await axios.get(`/api/supplier/${id}/deal_dynamics`, {
      params: { supplier_category_id }
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Supplier Deal Dynamic statistics
 *
 */
export async function fetchSupplierDynamicsStats() {
  try {
    const res = await axios.get(`/api/supplier_dynamics/statistics`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch Supplier Briefing
 *
 * @param id
 */
export async function fetchSupplierBriefing(id: number) {
  try {
    const res = await axios.get(`/api/supplier/${id}/briefing`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function fetchSupplierRiskDetails(id: number) {
  try {
    const res = await axios.get(`/api/supplier/${id}/report?report_type=Risk`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function insertSupplierBriefingFeedback(id: number, score: number, feedback?: string) {
  try {
    const res = await axios.post(`/api/supplier_briefing/${id}/feedback`, {
      score: score,
      feedback: feedback
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function insertSupplierReportFeedback(id: number, score: number, feedback?: string) {
  try {
    const res = await axios.post(`/api/supplier_report/${id}/feedback`, {
      score: score,
      feedback: feedback
    });
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export const useSupplierBriefingFeedback = (config = {}) =>
  useMutation({
    mutationFn: async (data: { id: number; score: number; feedback?: string }) => {
      const { id, score, feedback } = data;
      return await insertSupplierBriefingFeedback(id, score, feedback);
    },
    ...config
  });

export const useSupplierReportFeedback = (config = {}) =>
  useMutation({
    mutationFn: async (data: { id: number; score: number; feedback?: string }) => {
      const { id, score, feedback } = data;
      return await insertSupplierReportFeedback(id, score, feedback);
    },
    ...config
  });

/**
 * Fetch supplier notes
 * @param supplier_id
 */
export const fetchSupplierNotes = async (supplier_id: number) => {
  try {
    const res = await axios.get(`/api/supplier/${supplier_id}/notes`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res?.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
    throw e;
  }
};

/**
 * Create supplier note
 */
export async function createSupplierNote(
  supplierId: number,
  dealNote: { note: string; parent_id?: number; is_visible_to_npi: boolean }
) {
  try {
    const url = `/api/supplier/${supplierId}/note`;
    const { data } = await axios.post(url, dealNote);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Delete supplier note
 */
export async function deleteSupplierNote(supplierId: number, noteId: number) {
  try {
    const url = `/api/supplier/${supplierId}/note/${noteId}`;
    const { data } = await axios.delete(url);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Update supplier note
 */
export async function updateSupplierNote(
  supplierId: number,
  noteId: number,
  payload: { note: string; is_visible_to_npi: boolean }
) {
  try {
    const url = `/api/supplier/${supplierId}/note/${noteId}`;
    const { data } = await axios.patch(url, payload);
    return data;
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
