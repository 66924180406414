import React, { useState } from 'react';
import { Button, Input, Tag, Popconfirm, Typography, Empty } from 'antd';
import { ClientManagementSummary, ParentCompany } from 'sb/models/Client';
import { CloseCircleFilled, EditFilled, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';

interface Props {
  client?: ClientManagementSummary;
  deleteRalatedCompany: (id: number) => void;
  handleEditParentCompany: () => void;
}

export const ClientRelatedCompanies: React.FC<Props> = ({ client, deleteRalatedCompany, handleEditParentCompany }) => {
  const [openPopConfirm, setOpenPopConfirm] = useState<number>(0);
  const [editParentCompanies, setEditParentCompanies] = useState<boolean>(false);
  const [editChildCompanies, setEditChildCompanies] = useState<boolean>(false);
  const [parentSearch, setParentSearch] = useState('');
  const [childSearch, setChildSearch] = useState('');
  const [showMoreParentCo, setShowMoreParentCo] = useState<boolean>(false);
  const [showMoreChildCo, setShowMoreChildCo] = useState<boolean>(false);

  const confirm = (id: number) => {
    setOpenPopConfirm(0);
    deleteRalatedCompany(id);
  };

  const showConfirmPopup = (e: React.MouseEvent<HTMLElement>, id: number) => {
    setOpenPopConfirm(id);
    e.preventDefault();
  };

  const handleParentSearchChange = (e: any) => {
    setParentSearch(e.target.value);
  };

  const handleChildSearchChange = (e: any) => {
    setChildSearch(e.target.value);
  };

  const renderPills = (company: ParentCompany, closable: boolean, label: string) => {
    const pId = company.id;
    return (
      <Popconfirm
        placement="topRight"
        title={'Do you want to delete the related company?'}
        open={openPopConfirm === pId}
        onCancel={() => setOpenPopConfirm(0)}
        onConfirm={() => confirm(company.id)}
        okText="Yes"
        cancelText="No"
        key={label + pId}
      >
        <Tag
          key={label + pId}
          closable={closable}
          onClose={(e) => showConfirmPopup(e, company.id)}
          className="bg-gray-200 pl-3 py-1 pr-2 text-gray-700 rounded-full tag-icon"
        >
          <span className="font-semibold">{company.name}</span> - {company.relation_type.name}
        </Tag>
      </Popconfirm>
    );
  };

  const emptyMessage = (label: string) => (
    <div className="flex flex-col">
      <div className="flex items-center mb-2 ">
        <h4 className="mb-0 font-semibold flex-1">{label}</h4>
      </div>
      <div className="py-3">
        <Empty description="No data found!" />
      </div>
    </div>
  );

  const renderRelatedCompanies = (
    relatedCompanies: ParentCompany[],
    label: string,
    searchHandler: (e: any) => void,
    edit: boolean,
    setEdit: (flag: boolean) => void,
    searchText: string,
    showMore: boolean,
    setShowMore: (flag: boolean) => void
  ) => {
    const searchTextLowerCase = searchText.toLowerCase();
    const companies =
      (searchText
        ? relatedCompanies.filter(
            (c: ParentCompany) =>
              c.name.toLowerCase().includes(searchTextLowerCase) ||
              c.relation_type.name.toLowerCase().includes(searchTextLowerCase)
          )
        : relatedCompanies) || [];
    const slicedCompaniesArray = showMore ? companies : companies.slice(0, 5);
    return (
      <div className="flex flex-col">
        <div className="flex items-center mb-4">
          <h4 className="mb-0 font-semibold flex-1">{label}</h4>
          <div className="flex gap-x-3 items-center">
            <div>
              <Input
                placeholder="Search companies"
                allowClear
                bordered={true}
                onChange={searchHandler}
                suffix={<SearchOutlined style={{ fontSize: '1.25rem' }} />}
                className="borderless"
              />
            </div>
            <div>
              <Button
                disabled={edit}
                type="text"
                icon={<EditFilled style={{ fontSize: '1.25rem' }} />}
                onClick={() => {
                  setEdit(true);
                }}
              />
            </div>
          </div>
        </div>
        {slicedCompaniesArray.length > 0 ? (
          <div className="flex flex-wrap gap-y-2">
            {slicedCompaniesArray?.map((p: ParentCompany) => renderPills(p, edit, label))}
            {companies.length > 5 && (
              <Tag
                key={label + 'show-more'}
                className="bg-blue-600 text-white border-transparent pl-2 py-1 pr-2 rounded-full tag-icon cursor-pointer"
              >
                {showMore ? (
                  <span className="font-semibold number-font" onClick={() => setShowMore(false)}>
                    Show less
                  </span>
                ) : (
                  <span className="font-semibold number-font" onClick={() => setShowMore(true)}>
                    +{companies.length - 5} More
                  </span>
                )}
              </Tag>
            )}
          </div>
        ) : (
          <div>
            <Typography>No match found!</Typography>
          </div>
        )}
        {edit && (
          <div className="pt-6">
            <Button
              className="p-0"
              icon={<CloseCircleFilled style={{ fontSize: '1.25rem' }} className="align-middle leading-none" />}
              type="link"
              onClick={() => setEdit(false)}
            >
              Exit Edit Mode
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="flex mt-8 mb-8">
        <Typography.Title level={4} className="!mb-0 flex-1">
          Related Companies
        </Typography.Title>

        <Button className="ml-2" icon={<EditOutlined />} type="primary" onClick={handleEditParentCompany}>
          Add Company Relationship
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-x-8">
        {isEmpty(client?.parent_companies)
          ? emptyMessage('Parent Company')
          : renderRelatedCompanies(
              client?.parent_companies || [],
              'Parent Companies',
              handleParentSearchChange,
              editParentCompanies,
              setEditParentCompanies,
              parentSearch,
              showMoreParentCo,
              setShowMoreParentCo
            )}

        {isEmpty(client?.child_companies)
          ? emptyMessage('Child Company')
          : renderRelatedCompanies(
              client?.child_companies || [],
              'Child Companies',
              handleChildSearchChange,
              editChildCompanies,
              setEditChildCompanies,
              childSearch,
              showMoreChildCo,
              setShowMoreChildCo
            )}
      </div>
    </div>
  );
};
