import { axios } from 'shared/axiosClient';
import { AxiosErrorHandler } from 'shared/Helpers';
import { GanttChartChange } from 'types/global';
import { AxiosRequestConfig } from 'axios';
// import renewals_portfolio_data from '../../public/data/renewals_portfolio_blackstone.json';
/**
 * Fetch project
 *
 * @param project_id
 */
export const fetchProject = async (project_id: number) => {
  try {
    const res = await axios.get(`/api/project/${project_id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update Project Tasks
 *
 * @param project_id
 * @param method
 * @param change
 */
export const upsertProjectTask = async (project_id: number, method: 'post' | 'patch', change: GanttChartChange) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/api/project/${project_id}/task`,
      method: method,
      data: change
    };
    const res = await axios(config);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Delete Project Task
 *
 * @param project_id
 * @param task_id
 */
export const deleteProjectTask = async (project_id: number, task_id: number) => {
  try {
    const res = await axios.delete(`/api/project/${project_id}/task/${task_id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update Project Dependency
 *
 * @param project_id
 * @param method
 * @param change
 */
export const upsertProjectDependency = async (
  project_id: number,
  method: 'post' | 'patch',
  change: GanttChartChange
) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/api/project/${project_id}/dependency`,
      method: method,
      data: change
    };
    const res = await axios(config);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Delete Project Dependency
 *
 * @param project_id
 * @param id
 */
export const deleteProjectDependency = async (project_id: number, id: number) => {
  try {
    const res = await axios.delete(`/api/project/${project_id}/dependency/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Update Project Assignment
 *
 * @param project_id
 * @param method
 * @param change
 */
export const upsertProjectAssignment = async (
  project_id: number,
  method: 'post' | 'patch',
  change: GanttChartChange
) => {
  try {
    const config: AxiosRequestConfig = {
      url: `/api/project/${project_id}/assignment`,
      method: method,
      data: change
    };
    const res = await axios(config);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Delete Project Assignment
 *
 * @param project_id
 * @param id
 */
export const deleteProjectAssignment = async (project_id: number, id: number) => {
  try {
    const res = await axios.delete(`/api/project/${project_id}/assignment/${id}`);
    if (res.data?.status === 'success') {
      return res.data.data;
    }
    return Promise.reject(Error(res.data.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

/**
 * Fetches a list of renewals based on the given status and profile ID (optional).
 *
 * @param {string} status - The status of the renewals. Can be 'active' or 'expired'.
 * @param {number} [profile_id] - The profile ID to filter the renewals by (optional).
 * @returns {Promise<Array<Object>>} - A promise that resolves to an array of renewal data objects.
 * @throws {Error} - If an error occurs during the request or if the response status is not 'success'.
 */
export async function fetchRenewals(status: 'active' | 'expired', profile_id?: number): Promise<any> {
  try {
    const res = await axios.get(`/api/renewals`, {
      params: { status, profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

export async function fetchPortfolioRenewals(status: 'active' | 'expired', profile_id?: number): Promise<any> {
  try {
    // return Promise.resolve(renewals_portfolio_data.data);
    // profile_id = profile_id || 1327; //TO DO: remove hardcoded profile_id value
    const res = await axios.get(`/api/portfolio/renewals`, {
      params: { status, profile_id }
    });
    if (res.data?.status === 'success') {
      // return renewals_portfolio_data;
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message || 'Error while fetching Renewals'));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch supplier renewals
 *
 * @param id
 */
export async function fetchSupplierRenewals(id: number) {
  try {
    const res = await axios.get(`/secure/supplier/${id}/renewals`);
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}

/**
 * Fetch supplier renewals
 *
 * @param id
 * @param renewalTimeframe
 */
export async function fetchNPIRenewals(id: string, renewalTimeframe?: string) {
  try {
    const res = await axios.get(`/secure/renewals/${id}`, {
      params: { renewalTimeframe: renewalTimeframe }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
}
