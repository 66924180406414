import React, { FC, useEffect, useState } from 'react';
import { Button, Descriptions, Select, Tag, message, Form, Space, Spin } from 'antd';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountSettingsMenuKeyState, myState } from 'recoil/atoms';
import moment from 'moment';
import { FormatCurrency } from 'shared/Helpers';
import './AccountInfo.scss';
import { AccountData } from '../../models/AccountData';
import { ApiOutlined, EditFilled, PlusOutlined } from '@ant-design/icons';
import { saveDefaultRoles, updateClientEmailDomains } from '../../api/admin';
import { DefaultRole } from '../../sb/models/Role';

interface AccountInfoDetailsProps {
  accountInfo?: AccountData;
  emailDomains?: string[];
  activeUsers: number;
  siteAdmin?: boolean;
  setEmailDomains?: (value: ((prevState: string[]) => string[]) | string[]) => void;
  onUsersLinkClick?: () => void;
  hasContract?: boolean;
  updateEnabled?: boolean;
  onProvisionAccount?: () => void;
  savedRoles?: DefaultRole[];
  availableRoles?: DefaultRole[];
  fetchSavedAndDefaultRoles?: () => Promise<void>;
  loadingRoles?: boolean;
}

type FormTypes = {
  client_email_domains: Array<string>;
};

export const AccountInfoDetails: FC<AccountInfoDetailsProps> = ({
  accountInfo,
  emailDomains,
  activeUsers,
  siteAdmin,
  setEmailDomains,
  onUsersLinkClick,
  hasContract,
  updateEnabled,
  onProvisionAccount,
  savedRoles,
  availableRoles,
  fetchSavedAndDefaultRoles,
  loadingRoles
}: AccountInfoDetailsProps) => {
  const me = useRecoilValue(myState);
  const [_accountSettingsMenuKey, setAccountSettingsMenuState] = useRecoilState(accountSettingsMenuKeyState);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editRolesMode, setEditRolesMode] = useState<boolean>(false);
  const [savingEmailDomains, setSavingEmailDomains] = useState<boolean>(false);
  const [savingRoles, setSavingRoles] = useState<boolean>(false);
  const [isProvisioningAccount, setProvisioningAccount] = useState(false);
  const [form] = Form.useForm();

  const { auth0_organization_id } = accountInfo || {};

  useEffect(() => {
    form.setFieldValue('client_email_domains', emailDomains);
  }, [emailDomains, form]);

  useEffect(() => {
    form.setFieldValue(
      'client_roles',
      savedRoles?.map((role) => role?.id)
    );
  }, [savedRoles, form]);

  /**
   * Handles the saving of email domains for a client.
   *
   * This function saves the provided email domains by updating the client's email domains
   * in the system. It also manages the state of the saving process and provides feedback
   * upon successful update.
   *
   * @param {FormTypes} values - The values containing the email domains to be saved.
   * @returns {Promise<void>} - A promise that resolves when the saving process is complete.
   */
  const handleEmailDomainsSave = async (values: FormTypes): Promise<void> => {
    if (!accountInfo?.Id) return;

    const { client_email_domains } = values;
    try {
      setSavingEmailDomains(true);
      const res = await updateClientEmailDomains(accountInfo?.Id + '', client_email_domains);
      setEditMode(false);
      if (res) {
        message.success('Client email domains updated!');
        setEmailDomains && setEmailDomains(client_email_domains);
      } else {
        form.setFieldValue('client_email_domains', emailDomains);
      }
    } finally {
      setSavingEmailDomains(false);
    }
  };

  /**
   * Asynchronously handles the process of saving roles for a client account.
   * Sets savingRoles to true, gets the roles selected by the user from the form,
   * saves the default roles for the client account by calling saveDefaultRoles API,
   * fetches saved and default roles if required, sets savingRoles to false,
   * and sets editRolesMode to false after completion or error.
   *
   * @returns {Promise<void>} A Promise that resolves once the roles are saved and handled.
   */
  const handleSaveRoles = async (): Promise<void> => {
    try {
      setSavingRoles(true);
      const rolesSelected = form.getFieldValue('client_roles');
      const res = await saveDefaultRoles(
        { default_roles: rolesSelected?.map((role: string) => parseInt(role)) },
        accountInfo?.client_id
      );
      if (fetchSavedAndDefaultRoles && res) {
        await fetchSavedAndDefaultRoles();
      }
    } catch (error) {
      message.error('Problem saving/retrieving default roles');
    } finally {
      setSavingRoles(false);
      setEditRolesMode(false);
    }
  };

  const renderMsg = () => {
    return (
      <p>
        To request a change to the domains your team can use to authenticate, please contact your NPI customer service
        director.
      </p>
    );
  };

  const handleDomainEditCancel = () => {
    setEditMode(false);
    form.setFieldValue('client_email_domains', emailDomains);
  };

  const handleRolesEditCancel = () => {
    setEditRolesMode(false);
    form.setFieldValue(
      'client_roles',
      savedRoles?.map((role) => role?.id)
    );
  };

  const handleProvisionAccount = async () => {
    setProvisioningAccount(true);
    try {
      await onProvisionAccount?.();
    } catch (e) {
      console.error(e);
    }
    setProvisioningAccount(false);
  };

  const renderAvailableRoles = () => {
    return !siteAdmin ? (
      <>
        {savedRoles?.map((role) => (
          <Tag key={role?.id} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
            {role.name}
          </Tag>
        ))}
      </>
    ) : siteAdmin && !editRolesMode ? (
      <>
        {savedRoles && savedRoles?.length > 0 ? (
          savedRoles?.map((role) => (
            <Tag key={role.id} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
              {role.name}
            </Tag>
          ))
        ) : loadingRoles ? (
          <Spin />
        ) : (
          'No roles selected'
        )}
        <Button type="link" onClick={() => setEditRolesMode(true)}>
          {availableRoles && availableRoles?.length > 0 && !loadingRoles ? (
            <>
              <EditFilled />
              Edit List
            </>
          ) : (
            <></>
          )}
        </Button>
      </>
    ) : (
      <div className="flex gap-2">
        <Form.Item name="client_roles" label={undefined} style={{ marginBlock: 0 }}>
          <Select mode="tags" style={{ width: 650 }} tokenSeparators={[',']} placeholder="Select roles">
            {availableRoles?.map((role) => (
              <Select.Option key={role.id} id={role.id} value={role.id}>
                {role.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space>
          <Button type="primary" loading={savingRoles} onClick={() => handleSaveRoles()}>
            Save
          </Button>
          <Button onClick={() => handleRolesEditCancel()}>Cancel</Button>
        </Space>
      </div>
    );
  };

  return (
    <Form form={form} size="large" colon={false} onFinish={handleEmailDomainsSave}>
      <Descriptions column={1} colon={true} labelStyle={{ width: 180 }} bordered>
        <Descriptions.Item label="Phone">{accountInfo?.Phone}</Descriptions.Item>
        <Descriptions.Item label="Annual Revenue">{FormatCurrency(accountInfo?.AnnualRevenue)}</Descriptions.Item>
        <Descriptions.Item label="Status">
          {siteAdmin
            ? accountInfo?.CurrentContract?.Status || 'No Contract'
            : accountInfo?.Current_Contract__r?.Status || 'No Contract'}
        </Descriptions.Item>
        <Descriptions.Item label="Vantage Access">{accountInfo?.CurrentContract?.Vantage_Access__c}</Descriptions.Item>
        {auth0_organization_id && accountInfo?.client_id != me?.client_id ? (
          <Descriptions.Item label="Available Roles">{renderAvailableRoles()}</Descriptions.Item>
        ) : null}
        <Descriptions.Item label="Industry">{accountInfo?.Industry}</Descriptions.Item>
        <Descriptions.Item label="Billing Address">
          <div>{accountInfo?.BillingAddress?.street || ''}</div>
          <div>
            {`${accountInfo?.BillingAddress?.city || ''} ${accountInfo?.BillingAddress?.city ? ',' : ''} ${
              accountInfo?.BillingAddress?.state || ''
            } ${accountInfo?.BillingAddress?.postalCode || ''}`}
          </div>
        </Descriptions.Item>

        <Descriptions.Item label="Current Contract">
          {siteAdmin ? accountInfo?.CurrentContract?.Name : accountInfo?.Current_Contract__r?.Name}
        </Descriptions.Item>
        <Descriptions.Item label="Dates">
          {siteAdmin
            ? accountInfo?.CurrentContract?.StartDate && accountInfo?.CurrentContract?.EndDate
              ? moment(accountInfo.CurrentContract.StartDate).format('MMM DD, YYYY') +
                ' - ' +
                moment(accountInfo.CurrentContract.EndDate).format('MMM DD, YYYY')
              : 'N/A'
            : accountInfo?.Current_Contract__r?.StartDate && accountInfo?.Current_Contract__r?.EndDate
            ? moment(accountInfo.Current_Contract__r.StartDate).format('MMM DD, YYYY') +
              ' - ' +
              moment(accountInfo.Current_Contract__r.EndDate).format('MMM DD, YYYY')
            : 'N/A'}
        </Descriptions.Item>
        <Descriptions.Item label="Subscription">
          {siteAdmin
            ? FormatCurrency(accountInfo?.CurrentContract?.Total_Subscription_Fee__c)
            : FormatCurrency(accountInfo?.Current_Contract__r?.Total_Subscription_Fee__c)}
        </Descriptions.Item>
        <Descriptions.Item label="Active Users">
          {
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => {
                setAccountSettingsMenuState('userManagement');
                onUsersLinkClick && onUsersLinkClick();
              }}
            >
              {activeUsers} Active Users
            </span>
          }
        </Descriptions.Item>

        {auth0_organization_id ? (
          <Descriptions.Item label="Enabled Domains">
            {!siteAdmin ? (
              <>
                {emailDomains?.map((domain, idx) => (
                  <Tag key={idx + domain} color="#d9d9d9" className="text-base px-2 py-1 rounded-lg ml-2 text-black">
                    {domain}
                  </Tag>
                ))}
              </>
            ) : siteAdmin && !editMode ? (
              <>
                {emailDomains && emailDomains?.length > 0
                  ? emailDomains?.map((domain, idx) => (
                      <Tag
                        key={idx + domain}
                        color="#d9d9d9"
                        className="text-base px-2 py-1 rounded-lg ml-2 text-black"
                      >
                        {domain}
                      </Tag>
                    ))
                  : 'No client domain URL found'}
                <Button type="link" onClick={() => setEditMode(true)}>
                  {emailDomains && emailDomains?.length > 0 ? (
                    <>
                      <EditFilled />
                      Edit List
                    </>
                  ) : (
                    <>
                      <PlusOutlined />
                      Click here to add
                    </>
                  )}
                </Button>
              </>
            ) : (
              <div className="flex gap-2">
                <Form.Item name="client_email_domains" label={undefined} style={{ marginBlock: 0 }}>
                  <Select
                    mode="tags"
                    style={{ width: 650 }}
                    tokenSeparators={[',']}
                    placeholder="Enter email domain w/out '@'"
                  >
                    {emailDomains?.map((domain) => (
                      <Select.Option key={domain} id={domain}>
                        {domain}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Space>
                  <Button type="primary" htmlType="submit" loading={savingEmailDomains}>
                    Save
                  </Button>
                  <Button onClick={() => handleDomainEditCancel()}>Cancel</Button>
                </Space>
              </div>
            )}
          </Descriptions.Item>
        ) : null}

        {hasContract && !auth0_organization_id && updateEnabled ? (
          <Descriptions.Item label="Provisioning">
            <Button
              data-testid="provision-button"
              type="primary"
              size="large"
              icon={<ApiOutlined />}
              block
              loading={isProvisioningAccount}
              onClick={handleProvisionAccount}
            >
              Provision Online Account
            </Button>
          </Descriptions.Item>
        ) : null}

        {!siteAdmin && <Descriptions.Item label={undefined}>{renderMsg()}</Descriptions.Item>}
      </Descriptions>
    </Form>
  );
};
