import { useState } from 'react';
import { Button, Col, Divider, Drawer, Form, Input, message, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';
import { ResellerTable } from 'components/atoms/ResellerTable';
import { ResellerForm } from 'components/atoms/ResellerForm';
import { Reseller } from 'sb/models/Reseller';
import { createReseller, getResellers, updateReseller } from 'sb/api/reseller';

const DRAWER_WIDTH = 400;

const Resellers: React.FC = () => {
  const { aclCheck } = useScopes();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [resellers, setResellers] = useState<Reseller[]>([]);
  const [selectedReseller, setSelectedReseller] = useState<number>();

  const handleSearch = async (str: string) => {
    if (str?.length < 1) return;

    setLoading(true);
    const res = await getResellers(str);
    if (Array.isArray(res)) {
      setResellers(res);
      message.success(`${res.length} items fetched.`);
    }
    setLoading(false);
  };

  const handleSubmit = async (_values: any) => {
    const values = { ..._values, fiscal_year_end: _values.fiscal_year_end?.join('-') };

    setUpdating(true);
    if (selectedReseller) {
      // Update selected reseller
      const res = await updateReseller(selectedReseller, values);
      if (res) {
        setResellers((prev) => prev.map((item) => (item.id === selectedReseller ? res : item)));
        message.success('Reseller successfully updated.');
      }
    } else {
      // Create a new reseller
      const res = await createReseller(values);
      if (res) {
        setResellers((prev) => [...prev, res]);
        message.success('Reseller successfully added.');
      }
    }
    setOpenDrawer(false);
    setUpdating(false);
  };

  const handleAdd = () => {
    setSelectedReseller(undefined);
    form?.resetFields();
    setOpenDrawer(true);
  };

  const handleEdit = (rec: Reseller) => {
    setSelectedReseller(rec.id);
    form.setFieldsValue({ ...rec, fiscal_year_end: rec.fiscal_year_end?.split('-') || [] });
    setOpenDrawer(true);
  };

  return (
    <>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4}>Resellers</Typography.Title>
        </Col>
        <Col>
          {aclCheck(['create:suppliers_resellers']) && (
            <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd}>
              Add
            </Button>
          )}
        </Col>
      </Row>

      <Input.Search
        className="w-1/2"
        size="large"
        placeholder="Search by name or industry"
        enterButton
        onSearch={handleSearch}
      />

      <Divider className="my-4" />

      <ResellerTable dataSource={resellers} loading={loading} onEdit={handleEdit} />

      <Drawer
        title={selectedReseller ? 'Edit Reseller' : 'Add Reseller'}
        width={DRAWER_WIDTH}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <ResellerForm loading={updating} form={form} onFinish={handleSubmit} onCancel={() => setOpenDrawer(false)} />
      </Drawer>
    </>
  );
};

export default Resellers;
