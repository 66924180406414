import React from 'react';
import { Button, Card, Divider, Typography } from 'antd';
import './DealCreationSuccess.scss';
import { useNavigate } from 'react-router-dom';
import ViewDealDetails from '../../../../assets/ViewDealDetails.svg';

interface DealActionsProps {
  contract_id: number;
}

export const ViewDeal = (props: DealActionsProps) => {
  const { contract_id } = props;
  const navigate = useNavigate();

  return (
    <Card bordered={false} className="bg-transparent text-center w-96">
      <Button
        type={'link'}
        icon={<img src={ViewDealDetails} alt="Icon" height={20} width={26} />}
        size="large"
        onClick={() => navigate(`/deal_details/${contract_id}`, { state: 'returnToDashboard' })}
      >
        View Deal Details
      </Button>
      <Typography.Title level={5} className="!font-light text-xs leading-4 text-center">
        View the deal home page, where you can research the supplier, upload supporting documents, add and assign tasks,
        take notes, and more!
      </Typography.Title>
      <Divider />
    </Card>
  );
};
