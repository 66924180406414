import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Steps } from 'antd';
import './DealCreationSuccess.scss';
import { useNavigate } from 'react-router-dom';
import DealSubmitted from '../../../../assets/DealSubmitted.svg';
import BillOfMaterials from '../../../../assets/BillOfMaterials.svg';
import FMVReportReady from '../../../../assets/FMVReportReady.svg';
import SupplierNegotiation from '../../../../assets/SupplierNegotiation.svg';
import DealClose from '../../../../assets/DealClose.svg';

export const DealSteps: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="steps">
        <Steps
          current={0}
          labelPlacement="vertical"
          items={[
            {
              icon: <img src={DealSubmitted} alt="Icon" />,
              title: 'Deal Submitted'
            },
            {
              icon: <img src={BillOfMaterials} alt="Icon" aria-disabled={true} />,
              title: 'Bill of Materials Ready'
            },
            {
              icon: <img src={FMVReportReady} alt="Icon" />,
              title: 'FMV Report Ready'
            },
            {
              icon: <img src={SupplierNegotiation} alt="Icon" />,
              title: 'Supplier Negotiation'
            },
            {
              icon: <img src={DealClose} alt="Icon" />,
              title: 'Deal Close'
            }
          ]}
        />
      </div>
      <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => navigate('/subscription_purchases')} size="large">
        Back to all deals
      </Button>
    </>
  );
};
