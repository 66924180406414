import { Button, Popconfirm, Switch, TableColumnsType, Tooltip } from 'antd';
import { SupplierDealDynamic } from 'sb/models/DealDynamicsSupplier';
import { useMemo } from 'react';
import moment from 'moment/moment';
import { FieldSorter } from '../../sb/shared/Helpers';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useScopes } from 'hooks/useScopes';

interface Props {
  hideCategoryColumn: boolean;
  onIsAcceptedChange: (id: number, checked: boolean) => void;
  onConfirmDelete: (id: number) => void;
  onEditClick: (rec: SupplierDealDynamic) => void;
}

export const DealDynamicsSupplierTableColumns = ({
  hideCategoryColumn,
  onIsAcceptedChange,
  onConfirmDelete,
  onEditClick
}: Props) => {
  const { aclCheck } = useScopes();

  const columns: TableColumnsType<SupplierDealDynamic> = useMemo(
    () => [
      {
        title: 'Dynamic',
        dataIndex: 'dynamic',
        sorter: (a: SupplierDealDynamic, b: SupplierDealDynamic) =>
          FieldSorter(a.deal_dynamic.name, b.deal_dynamic.name),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: SupplierDealDynamic) => rec.deal_dynamic.name
      },
      {
        title: 'Product Category',
        dataIndex: 'category',
        sorter: (a: SupplierDealDynamic, b: SupplierDealDynamic) =>
          FieldSorter(a.supplier_category?.category, b.supplier_category?.category),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: SupplierDealDynamic) => rec.supplier_category?.category || '--'
      },
      {
        title: 'Score',
        align: 'center',
        dataIndex: 'value',
        sorter: (a: SupplierDealDynamic, b: SupplierDealDynamic) => a.value - b.value,
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: SupplierDealDynamic) => {
          if (aclCheck(['update:supplier_dynamics_admin'])) {
            return (
              <Button type="text" onClick={() => onEditClick(rec)}>
                {rec.value} <EditOutlined />
              </Button>
            );
          } else {
            return rec.value;
          }
        }
      },
      {
        title: 'Analyst',
        dataIndex: 'user',
        sorter: (a: SupplierDealDynamic, b: SupplierDealDynamic) =>
          FieldSorter(a.users?.first_name, b.users?.first_name),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: SupplierDealDynamic) => rec.users?.first_name || '--'
      },
      {
        title: 'Date',
        dataIndex: 'creationDate',
        sorter: (a: SupplierDealDynamic, b: SupplierDealDynamic) => FieldSorter(a.creation_date, b.creation_date),
        sortDirections: ['ascend', 'descend'],
        render: (_, rec: SupplierDealDynamic) => moment(rec.creation_date).format('ll')
      },
      {
        title: 'Accepted',
        dataIndex: 'is_accepted',
        align: 'center',
        render: (_, rec: SupplierDealDynamic) => (
          <Switch
            disabled={!aclCheck(['update:supplier_dynamics_admin'])}
            defaultChecked={rec.is_accepted}
            onChange={(checked) => onIsAcceptedChange(rec.id, checked)}
          />
        )
      },
      {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        width: aclCheck(['delete:supplier_dynamics_admin']) ? 80 : 1,
        render: (_, rec: SupplierDealDynamic) => {
          if (aclCheck(['delete:supplier_dynamics_admin'])) {
            return (
              <>
                <Tooltip title="Delete score.." placement="bottom">
                  <Popconfirm
                    title="Delete analyst score?"
                    overlayStyle={{ width: 240 }}
                    placement="topLeft"
                    onConfirm={() => onConfirmDelete(rec.id)}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Tooltip>
              </>
            );
          } else {
            return '';
          }
        }
      }
    ],
    [aclCheck, onEditClick, onIsAcceptedChange, onConfirmDelete]
  );
  // Filter out Category column if the vendor has no product categories
  return hideCategoryColumn ? columns.filter((col: any) => col.dataIndex !== 'category') : columns;
};
